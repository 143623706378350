import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SettingsForm } from './SettingsForm';

export const Card = () => {
  const location = useLocation();

  const [error, setError] = useState<{ loginError: string; changePasswordError: string }>({
    loginError: '',
    changePasswordError: '',
  });

  return (
    <section className="cards">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-10">
            <div className="row d-flex align-items-sm-stretch">
              <div className="card datos-personales mb-5">
                <div className="card-body p-5">
                  <div className="row mb-5">
                    <div className="col-sm-8">
                      <h3 className="card-title mb-4">Cambio de contraseña</h3>
                      <p className="card-subtitle">
                        Cambia tu contraseña cuando lo necesites. Por seguridad, te recomendamos que
                        esté compuesta de letras y números. Si no la recuerdas, puedes{' '}
                        <Link
                          to={'/recuperar-contrasena'}
                          state={{ backgroundLocation: location }}
                          className="link">
                          Recuperar la contraseña
                        </Link>
                      </p>
                      <br hidden={error.loginError === '' && error.changePasswordError === ''} />
                      <p
                        className="card-subtitle"
                        style={{ color: 'red' }}
                        hidden={error.loginError === ''}>
                        {error.loginError}
                      </p>
                      <p
                        className="card-subtitle"
                        style={{ color: 'red' }}
                        hidden={error.changePasswordError === ''}>
                        {error.changePasswordError}
                      </p>
                    </div>
                  </div>
                  <SettingsForm setError={setError} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
