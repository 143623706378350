import { axios } from '@/lib/axios';
import { Dispatch } from '@reduxjs/toolkit';
import { setInquiry, startLoadingInquiry } from './inquiry';
import { InquiryResponse } from '@/features/inquiry';

export const getInquiry = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingInquiry());
    // TODO: fetch Legal from API
    try {
      const response = await axios.post<InquiryResponse[]>('/inquiry_type/find_visible', {});

      dispatch(setInquiry(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
