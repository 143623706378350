import { useField } from 'formik';
import { CheckbokInputProps } from './types';

export const CheckboxInput = ({ label, ...props }: CheckbokInputProps) => {
  const [field] = useField({ ...props, type: 'checkbox' });

  return (
    <>
      <input
        className="form-check-input"
        type="checkbox"
        {...field}
        {...props}
        onChange={props.onChange}
      />
      <label className="form-check-label">{label}</label>
      {/* <errorMessage name={props.name} component="span" /> */}
    </>
  );
};
