import { HeaderProps } from '../interfaces/header';
import { styles } from '@/features/purchase';

export const Header = ({ title, action }: HeaderProps) => {
  return (
    <header>
      <div className="d-flex flex-xs-column justify-content-between align-items-center">
        <h1 className="m-0">{title}</h1>
        <p className="text-end m-0 flex-grow-1">Paso 1 de 2</p>
      </div>
      <hr className={styles.separator} />
      <p className="mt-3">{action}</p>
    </header>
  );
};
