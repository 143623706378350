import { useState } from 'react';
import { ErrorMessage, Field, Form, FormikErrors, FormikTouched, useFormikContext } from 'formik';

import { TextInput } from '@/components/form';

import { FormValues } from '../interfaces';
import { InputContainer } from './components';

export const RegisterForm = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { values, initialValues, handleSubmit, errors, touched } = useFormikContext();

  const error = errors as FormikErrors<FormValues>;
  const touch = touched as FormikTouched<FormValues>;

  return (
    <Form>
      <div className="row mt-5">
        <div className="col-12">
          <h3>Datos personales</h3>
          <p>
            Es importante que los datos ingresados sean correctos ya que serán los que figurarán en
            su certificado final.
          </p>
        </div>
      </div>
      <div className="row pe-sm-5">
        <InputContainer errors={error.mail} touched={touch.mail}>
          <TextInput
            label="Correo electrónico *"
            name="mail"
            placeholder="Ingresá tu correo electrónico"
            type="email"
          />
        </InputContainer>
        <InputContainer errors={error.password} touched={touch.password}>
          <label className="form-label" htmlFor="password">
            Contraseña *
          </label>
          <div className="input-wrapper position-relative">
            <Field
              type={passwordVisibility ? 'text' : 'password'}
              className="form-control"
              name="password"
              placeholder="Ingresá una contraseña"
            />
            <button
              className="grey btn btn-light"
              type="button"
              onClick={() => setPasswordVisibility(!passwordVisibility)}>
              <i className="fas fa-eye"> </i>
            </button>
          </div>
          <ErrorMessage name="password" component="span" className="errorMessagge red small" />
        </InputContainer>
      </div>
    </Form>
  );
};
