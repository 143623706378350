import { Action, ActionCreatorWithPayload, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { setBillingData, setErrorFetch, setLoadingPurchase, setUserData } from './purchaseSlice';
import { clearPurchase, getActivateToken, getPurchase, purchase } from '@/utils';
import { sellOndemand, sellOndemandWithExternalPayer } from './services';
import { activateUser, updateProfileUser } from '../user';
import { User } from '@/interfaces';
import { setUserEnroll } from '@/features/purchase';

export const setPurchaseData = (
  values: User,
  action: ActionCreatorWithPayload<any, string>,
  key: string
) => {
  return async (dispatch: Dispatch) => {
    purchase(key, values);
    dispatch(action(values));
  };
};

export const getPurchaseData = (key: string, action: ActionCreatorWithPayload<any, string>) => {
  return async (dispatch: Dispatch) => {
    const value = getPurchase(key);
    dispatch(action(value));
  };
};

export const confirmPurchase = (
  user: User,
  payment: any,
  billingData?: User,
  courseID?: string,
  userId?: string | number
) => {
  return async (dispatch: ThunkDispatch<any, void, Action>) => {
    dispatch(setLoadingPurchase(true));
    try {
      const resp =
        Object.keys(billingData).length > 0
          ? await sellOndemandWithExternalPayer(payment, user, billingData)
          : await sellOndemand(payment, user);

      if (resp.status === 200 && !resp.error) {
        const userEnroll = await setUserEnroll(Number(userId), Number(courseID));
        dispatch(setLoadingPurchase(false));
        return userEnroll;
      }

      dispatch(setUserData(null));
      dispatch(setBillingData(null));

      clearPurchase('userPurchase');
      clearPurchase('billPurchase');

      return resp.data;
    } catch (error) {
      return { error: error };
    }
  };
};
