import * as Yup from 'yup';

export const personalSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z\sÁÉÍÓÚáéíóúÑñ]*$/, 'Nombre inválido')
    .max(40)
    .required('Nombre es requerido'),
  surname: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Nombre inválido')
    .max(40)
    .required('Apellido es requerido'),
  birth: Yup.date().required('Fecha de nacimiento es requerida'),
  genre: Yup.string().required('Género es requerido'),
  nationality: Yup.string().required('Nacionalidad es requerida'),
  tipodoc: Yup.string().required('Tipo de Documento es requerido'),
});

export const contactSchema = Yup.object().shape({
  phone: Yup.object({
    countryCode: Yup.string().required('Código país es requerido'),
    area: Yup.string().min(1).max(4).required('Código de área es requerido'),
    number: Yup.string().min(6).max(10).required('Teléfono es requerido'),
  }),
  mail: Yup.string()
    .required('Correo electrónico es querido')
    .email('Correo electrónico inválido. Formato requerido: email@emailejemplo.com'),
  address: Yup.object({
    city: Yup.string().required('Ciudad es requerido'),
    street: Yup.string().required('Dirección es requerido'),
    state: Yup.string()
      .matches(/^[A-Za-z\sÁÉÍÓÚáéíóúÑñ]*$/, 'Provincia / Estado / Región inválido')
      .required('Provincia / Estado / Región es requerido'),
    country: Yup.string()
      .matches(/^[A-Za-z ]+[\u00E0-\u00FC]*$/, 'País de residencia inválido')
      .required('País de residencia es requerido'),
  }),
});

export const educationSchema = Yup.object().shape({
  educationLevel: Yup.string().required('Nivel de formación es requerido'),
  knowledgeArea: Yup.string().required('Área de conocimiento es requerido'),
  occupation: Yup.string().required('Ocupación o Cargo es requerido'),
  personnelInCharge: Yup.string().required('Personal a Cargo es requerido'),
  empresa: Yup.string().nullable(),
});
