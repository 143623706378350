import { axios } from '@/lib/axios';

type InquiryDTO = {
  asunto: string;
  nombre: string;
  apellido: string;
  email: string;
  area: string;
  prioridad: string;
  descripcion: string;
};

export const sendMail = async (data: InquiryDTO) => {
  try {
    const response = await axios.post('/crm/create_trouble_ticket_webclient', data);
    if (response.data.msg || response.data.error) {
      throw Error('Ocurrio un error enviando la incidencia');
    } else {
      return response.data;
    }
  } catch (error) {
    throw { error: error };
  }
};
