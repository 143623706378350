import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getActiveUser } from '@/store/slices/user';
import { toTop } from '@/utils/toTop';
import { getUserEmail } from '@/utils/cookieManager';
import { setBillingData, getPurchaseData, setUserData } from '@/store/slices';

export const useUserSession = () => {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAppSelector(state => state.user);

  useEffect(() => {
    if (!user || !user.mail) {
      dispatch(getActiveUser(getUserEmail()));
      dispatch(getPurchaseData('userPurchase', setUserData));
      dispatch(getPurchaseData('billPurchase', setBillingData));
      toTop();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    isLoading,
  };
};
