import { CertificateResponse } from '@/features/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface certificatesState {
  certificates: CertificateResponse;
  isLoading: boolean;
}

const certificates_INITIAL_STATE: certificatesState = {
  certificates: {} as CertificateResponse,
  isLoading: false,
};

export const certificatesSlice = createSlice({
  name: 'certificates',
  initialState: certificates_INITIAL_STATE,
  reducers: {
    startLoadingCertificates: state => {
      state.isLoading = true;
    },
    setCertificates: (state, action: PayloadAction<CertificateResponse>) => {
      state.isLoading = false;
      state.certificates = action.payload;
    },
  },
});

export const { startLoadingCertificates, setCertificates } = certificatesSlice.actions;
