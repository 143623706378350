import { useEffect } from 'react';
import { InquiryOptions } from '@/features/inquiry';
import { toTop } from '@/utils/toTop';

export const Inquiry = () => {
  useEffect(() => {
    toTop();
  }, []);
  return (
    <div>
      <InquiryOptions />
    </div>
  );
};
