import { User } from '@/interfaces';
import { countryLabels } from '@/features/user';
import { toTop } from '@/utils';

type StateProps = {
  user: User;
  setEdit: React.Dispatch<React.SetStateAction<string>>;
};

export const ViewContactData: React.FC<StateProps> = ({ user, setEdit }) => {
  return (
    <div className="card datos-contacto mb-5">
      <div className="card-body p-5">
        <div className="row mb-5">
          <div className="col">
            <h3 className="card-title mb-4">Datos de contacto</h3>
            <p className="card-subtitle">
              Los datos de contacto nos permiten comunicarnos con vos, chequea que tu correo y
              numero telefónicos sean los correctos.
            </p>
          </div>
        </div>
        <ul className="list-unstyled mb-4 data">
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">País de residencia</h4>
            <p>{countryLabels[user?.address?.country]}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Provincia, Estado o Región</h4>
            <p>{user?.address?.state}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Ciudad</h4>
            <p>{user?.address?.city}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Dirección</h4>
            <p>
              {user?.address?.street} {user?.address?.streetNumber}
              {user?.address?.departmentNumber}
            </p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">
              <i className="fas fa-check-circle"> </i> Dirección de correo verificada
            </h4>
            <p>{user.mail}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Número de teléfono</h4>
            <p>
              {user?.phone?.countryCode} {user?.phone?.area} {user?.phone?.number}
            </p>
          </li>
        </ul>
        <p className="text-end mt-2">
          <button
            onClick={() => {
              setEdit('contact');
              toTop();
            }}
            className="btn primary-btn btn-rounded red_bg white btn-small">
            Editar datos
          </button>
        </p>
      </div>
    </div>
  );
};
