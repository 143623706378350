export const getCodeCountryByCodeNumber = (codeNumber: string) => {
  if (!codeNumber || codeNumber === '') return { name: 'AR', codeNumber: '+54' };

  const countries = [
    { name: 'AR', codeNumber: '+54' },
    { name: 'BO', codeNumber: '+591' },
    { name: 'BR', codeNumber: '+55' },
    { name: 'CL', codeNumber: '+56' },
    { name: 'CO', codeNumber: '+57' },
    { name: 'CR', codeNumber: '+506' },
    { name: 'CU', codeNumber: '+53' },
    { name: 'US', codeNumber: '+1' },
    { name: 'EC', codeNumber: '+593' },
    { name: 'ES', codeNumber: '+34' },
    { name: 'SV', codeNumber: '+503' },
    { name: 'GT', codeNumber: '+502' },
    { name: 'HN', codeNumber: '+504' },
    { name: 'MX', codeNumber: '+52' },
    { name: 'NI', codeNumber: '+505' },
    { name: 'PA', codeNumber: '+507' },
    { name: 'PY', codeNumber: '+595' },
    { name: 'PE', codeNumber: '+51' },
    { name: 'UY', codeNumber: '+598' },
    { name: 'VE', codeNumber: '+58' },
  ];

  const country = countries.find(
    country => country.codeNumber?.replace('+', '') === codeNumber?.replace('+', '')
  );

  return country || { name: 'AR', codeNumber: '+54' };
};

export const getCodeCountryByCodeName = (codeName: string) => {
  if (!codeName || codeName === '') return { name: 'AR', codeNumber: '+54' };

  const countries = [
    { name: 'AR', codeNumber: '+54' },
    { name: 'BO', codeNumber: '+591' },
    { name: 'BR', codeNumber: '+55' },
    { name: 'CL', codeNumber: '+56' },
    { name: 'CO', codeNumber: '+57' },
    { name: 'CR', codeNumber: '+506' },
    { name: 'CU', codeNumber: '+53' },
    { name: 'US', codeNumber: '+1' },
    { name: 'ES', codeNumber: '+34' },
    { name: 'EC', codeNumber: '+593' },
    { name: 'SV', codeNumber: '+503' },
    { name: 'GT', codeNumber: '+502' },
    { name: 'HN', codeNumber: '+504' },
    { name: 'MX', codeNumber: '+52' },
    { name: 'NI', codeNumber: '+505' },
    { name: 'PA', codeNumber: '+507' },
    { name: 'PY', codeNumber: '+595' },
    { name: 'PE', codeNumber: '+51' },
    { name: 'UY', codeNumber: '+598' },
    { name: 'VE', codeNumber: '+58' },
  ];
  const country = countries.find(country => country.name === codeName);

  return country || { name: 'AR', codeNumber: '+54' };
};
