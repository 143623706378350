import * as React from 'react';
import Select from 'react-select';
import { countryCodes, countryLabels } from '@/features/user';

const handleKeyEnter = e => {
  const element = document.querySelector('.css-govwq4-menu');

  if (e.key === 'Enter' && !element) {
    e.preventDefault();
    e.currentTarget.blur();
  }
};

export const SelectComponent = ({
  formik,
  att,
  subatt = '',
  handleKeys,
  options,
  placeholder,
  fullWidth = false,
  handleCurrentOption = (e: any) => {},
}) => {
  const selectStyle = {
    control: base => ({
      ...base,
      border: error ? '1.5px solid #ff2639' : '1.5px solid #5a5e66',
      fontSize: 'smaller',
    }),
    menu: base => ({
      ...base,
      zIndex: 10,
    }),
    option: styles => ({
      ...styles,
      fontSize: 'smaller',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#5a5e66', // Custom colour
    }),
  };
  const value = subatt === '' ? formik.values[att] : formik.values[att][subatt];
  const [error, setError] = React.useState(
    subatt === '' ? formik.errors[att] : formik.errors[att] ? formik.errors[att][subatt] : undefined
  );
  const [touched, setTouched] = React.useState(
    subatt === ''
      ? formik.touched[att]
      : formik.touched[att]
      ? formik.touched[att][subatt]
      : undefined
  );
  const selectTheme = theme => ({
    ...theme,
    border: '1.5px solid rgb(90,94,102)',
    colors: {
      ...theme.colors,
      primary25: '#F3F6FA',
      primary: 'rgba(90,94,102,0.55)',
    },
  });

  React.useEffect(() => {
    setError(
      subatt === ''
        ? formik.errors[att]
        : formik.errors[att]
        ? formik.errors[att][subatt]
        : undefined
    );
    setTouched(
      subatt === ''
        ? formik.touched[att]
        : formik.touched[att]
        ? formik.touched[att][subatt]
        : undefined
    );
  }, [formik.touched, formik.errors]);

  function handleSelectChange(att: string, field: string, e) {
    handleKeys([att]);
    if (subatt) {
      formik.setFieldValue(field, { ...formik.values[field], [subatt]: countryCodes[e.value] });
    } else {
      formik.setFieldValue(field, e.value);
    }
  }

  function getLabel(value) {
    if (subatt === '') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return countryLabels[value];
  }
  return (
    <Select
      className={
        fullWidth
          ? error && touched
            ? ' error'
            : ''
          : `col-md-6 ${error && touched ? ' error' : ''} col-lg-4`
      }
      aria-label={att}
      components={{ IndicatorSeparator: () => null }}
      theme={selectTheme}
      styles={selectStyle}
      id={subatt != '' ? `${att}.${subatt}` : att}
      options={options}
      placeholder={placeholder}
      value={
        value
          ? {
              value: value,
              label: getLabel(value),
            }
          : 0
      }
      onChange={e => {
        handleSelectChange(`user.${att}${subatt !== '' ? `.${subatt}` : ''}`, att, e);
        handleCurrentOption(e);
      }}
      onKeyDown={handleKeyEnter}
    />
  );
};
