// eslint-disable-next-line no-restricted-imports
import styles from '@/features/purchase/Purchase.module.css';
import React, { useMemo } from 'react';
import { mode, useCourse } from '@/features/course';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '@/store/hooks';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const { course } = useCourse();
  const location = useLocation();
  const { userIP } = useAppSelector(state => state.userIP);
  const { price } = useAppSelector(state => state.course);

  // const showPageThanks = useMemo(() => {
  //   if (location.pathname?.split('/').includes('gracias')) {
  //     return true;
  //   }
  //   return false;
  // }, [location]);

  const value = price?.listprice;

  return (
    <div className="row">
      <section className="col-sm-7 p-5 ps-3">{children}</section>
      <aside className="col-sm-5 p-sm-5 pe-sm-0">
        <div className="ps-sm-5">
          <figure className="mb-3">
            <img
              src={course?.image && 'data:image/png;base64,' + course?.image?.data}
              alt={course?.fullname}
              className="img-fluid"
            />
            <figcaption className="d-flex justify-content-between align-items-center mt-2">
              <h5 className="text-left">{mode[course?.mode]?.description}</h5>
              <h5 className="text-end">
                {course?.encounters} {mode[course?.mode]?.practicesDescription1}
              </h5>
            </figcaption>
          </figure>
          <h3 className="mb-2">{course?.fullname}</h3>
          <article>
            <hr className={styles.separator} />
            <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
              <p className="subtitle">
                1 programa {userIP?.countryCurrencySimbolo === 'ARS' ? 'ARS' : 'USD'}
              </p>
              <p className="text-end">$ {value}</p>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="subtitle">Descuento aplicado</p>
                  <h3 className="text-end">50%</h3>
                </div> */}
            <div className="d-flex justify-content-between align-items-center mb-2">
              <p className="subtitle">
                Sub total {userIP?.countryCurrencySimbolo === 'ARS' ? 'ARS' : 'USD'}
              </p>
              <p className="text-end">$ {value}</p>
            </div>
            <hr className="separator" />
            <div className="d-flex justify-content-between align-items-center">
              <h3>Total {userIP?.countryCurrencySimbolo === 'ARS' ? 'ARS' : 'USD'}</h3>
              <h3 className="text-end">$ {value}</h3>
            </div>
          </article>
        </div>
      </aside>
    </div>
  );
};
