import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);
import { toTop } from '@/utils';
import { updatePassword } from '@/features/user';

// password1 and password2 must be the same

const validationSchema = Yup.object().shape({
  password1: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'Debe tener al menos 6 caracteres')
    .minNumbers(1, 'Debe tener al menos 1 número'),
  password2: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'Debe tener al menos 6 caracteres')
    .minNumbers(1, 'Debe tener al menos 1 número')
    .oneOf([Yup.ref('password1'), null], 'Las contraseñas deben ser iguales'),
});

export const ChangePassword: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [mail, setMail] = React.useState('');
  const [error, setError] = React.useState(false);
  const [passwordVisibility1, setPasswordVisibility1] = React.useState(false);
  const [passwordVisibility2, setPasswordVisibility2] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password1: '',
      password2: '',
    },
    onSubmit: async values => {
      if (values.password1 != values.password2) {
        setError(true);
      } else {
        const response = await updatePassword(values.password1, params.token);
        if (response.data.error) {
          toTop();
          setError(true);
        } else {
          navigate('/exito-contrasena', { replace: true, state: { backgroundLocation: location } });
        }
      }
    },
    validationSchema,
  });

  React.useEffect(() => {
    toTop();
    setLoading(false);
  }, []);

  return (
    <section className="reset_password_form d-flex">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-lg-5 col-sm-6 lighter-grey_bg px-lg-5 px-sm-3 px-1 pt-5">
            <p>
              <small>Actualizar la contraseña</small>
            </p>
            <h1>Nueva contraseña</h1>
          </div>
          <div className="col-lg-7 col-sm-6 pt-5">
            <div className="form-wrapper px-lg-5 px-sm-3 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="col-sm-6">
                  <h3>Ingresa una nueva contraseña</h3>
                  <p>La contraseña debe contener al menos 6 caracteres y un número.</p>
                  <form className="mt-2" onSubmit={formik.handleSubmit}>
                    <div className="mb-3">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <label className="form-label" htmlFor={'password1'}>
                          Nueva contraseña *
                        </label>
                        <p className="errorMessagge red small form-label">
                          {formik.errors?.password1}
                        </p>
                      </div>
                      <div className="input-wrapper position-relative">
                        <input
                          type={passwordVisibility1 ? 'text' : 'password'}
                          name="password1"
                          className="form-control"
                          value={formik.values.password1}
                          id="password1"
                          onChange={formik.handleChange}
                        />
                        <button
                          className="grey btn btn-light"
                          type="button"
                          onClick={() => setPasswordVisibility1(!passwordVisibility1)}>
                          <i className="fas fa-eye"> </i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <label className="form-label" htmlFor={'password1'}>
                          Confirmar contraseña *
                        </label>
                        <p className="errorMessagge red small form-label">
                          {formik.errors?.password2}
                        </p>
                      </div>
                      <div className="input-wrapper position-relative">
                        <input
                          type={passwordVisibility2 ? 'text' : 'password'}
                          name="password2"
                          className="form-control"
                          value={formik.values.password2}
                          id="password2"
                          onChange={formik.handleChange}
                        />
                        <button
                          className="grey btn btn-light"
                          type="button"
                          onClick={() => setPasswordVisibility2(!passwordVisibility2)}>
                          <i className="fas fa-eye"> </i>
                        </button>
                      </div>
                    </div>
                    {error && (
                      <p className="errorMessagge red small">
                        {'No se pudo cambiar la contraseña. Intente de nuevo'}
                      </p>
                    )}
                    <p className="text-center">
                      <button
                        disabled={
                          Boolean(formik.errors?.password1) || Boolean(formik.errors?.password2)
                        }
                        type="submit"
                        className="btn primary-btn btn-rounded red_bg white my-3">
                        CAMBIAR CONTRASEÑA
                      </button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
