import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '@/components/errorMessage';

type Props = {
  onSubmit: (values: any) => void;
  response: string;
  location: any;
  validationScheme: any;
};

export const LogInView = (props: Props) => {
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);

  return (
    <div>
      <h4 className="mb-2 text-center">Te damos la bienvenida a Mundos E</h4>
      <Formik
        onSubmit={props.onSubmit}
        initialValues={{ email: '', password: '' }}
        validationSchema={props.validationScheme}>
        {formik => (
          <Form className="d-flex flex-column align-items-center px-5">
            <div className={'mb-3 w-100'}>
              <label className="form-label" htmlFor="email">
                Correo electrónico
              </label>
              <Field type="email" className="form-control" name="email" />
            </div>
            <div
              className={`mb-3 w-100 ${
                formik.errors.password && formik.touched.password ? 'error' : ''
              }`}>
              <label className="form-label" htmlFor="password">
                Contraseña
              </label>
              <div className="input-wrapper position-relative">
                <Field
                  type={passwordVisibility ? 'text' : 'password'}
                  className="form-control"
                  name="password"
                />
                <button
                  className="grey btn btn-light"
                  type="button"
                  onClick={() => setPasswordVisibility(!passwordVisibility)}>
                  <i className="fas fa-eye"> </i>
                </button>
              </div>
            </div>

            {props.response && (
              <ErrorMessage
                message={
                  'El correo electrónico o la contraseña no son válidos. Volvé a intentarlo o hacé clic en restablecer.'
                }
              />
            )}
            <button
              type="submit"
              className="mt-2 btn primary-btn btn-rounded red_bg white"
              disabled={!formik.dirty}>
              Iniciar sesión
            </button>
            <p className="mt-3">
              <Link to={'/recuperar-contrasena'} state={props.location.state} className="link">
                Recuperar contraseña
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};
