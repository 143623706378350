import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal as BootModal } from 'react-bootstrap';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const Modal: React.FC<Props> = ({ children, title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    if (state) {
      const { backgroundLocation } = state as any;
      navigate(backgroundLocation.pathname, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    handleShow();
  }, []);

  return (
    <>
      <BootModal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <BootModal.Header>
          <BootModal.Title>{title}</BootModal.Title>
          <button
            onClick={() => handleClose()}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </BootModal.Header>
        <BootModal.Body>
          <>{children}</>
        </BootModal.Body>
      </BootModal>
      {/*{state.fromElement}*/}
      {/*{backRoute ? getRoute(backRoute) : <Home/>}*/}
      {/*<Outlet/>*/}
    </>
  );
};
