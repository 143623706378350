import { useField } from 'formik';
import ReactFlagsSelect from 'react-flags-select';

type FlagsSelectInputProps = {
  label: string;
  name: string;
  errorFlag?: string | undefined;
  touchedFlag?: boolean | undefined;

  [x: string]: any;
};

export const PhoneInputField = ({
  label,
  errorFlag,
  touchedFlag,
  ...props
}: FlagsSelectInputProps) => {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      {/* flag ======================== */}
      <label className="form-label" htmlFor={props.id || props.name}>
        Código país *
      </label>
      <ReactFlagsSelect
        showSecondarySelectedLabel={true}
        {...props}
        {...field}
        selected={field.value}
        onSelect={code => {
          helpers.setValue(code);
        }}
        className={'form-control mb-2 '}
        placeholder={props.placeholder}
        showOptionLabel
        showSelectedLabel
        countries={[
          'AR',
          'BO',
          'BR',
          'CO',
          'CL',
          'CR',
          'EC',
          'ES',
          'US',
          'GT',
          'HN',
          'MX',
          'PE',
          'PY',
          'SV',
          'UY',
        ]}
        customLabels={{
          AR: { primary: 'Argentina', secondary: '+54' },
          BO: { primary: 'Bolivia', secondary: '+591' },
          BR: { primary: 'Brasil', secondary: '+55' },
          CO: { primary: 'Colombia', secondary: '+57' },
          CL: { primary: 'Chile', secondary: '+56' },
          CR: { primary: 'Costa Rica', secondary: '+506' },
          EC: { primary: 'Ecuador', secondary: '+593' },
          ES: { primary: 'España', secondary: '+34' },
          US: { primary: 'Estados Unidos', secondary: '+1' },
          GT: { primary: 'Guatemala', secondary: '+502' },
          HN: { primary: 'Honduras', secondary: '+504' },
          MX: { primary: 'México', secondary: '+52' },
          PE: { primary: 'Perú', secondary: '+51' },
          PY: { primary: 'Paraguay', secondary: '+595' },
          SV: { primary: 'El Salvador', secondary: '+503' },
          UY: { primary: 'Uruguay', secondary: '+598' },
        }}
        id={props.name}
      />
    </>
  );
};
