import {
  // Specs,
  Related,
  // About,
  Certifications,
  // Functionalities,
  Hero,
  Learnings,
  Loader,
  Questions,
  // Requirements,
  Schedules,
  Structure,
  // Teachers,
  useCourse,
} from '@/features/course';
// import { chunkArray } from '@/features/course/utils';
// import { User } from '@/interfaces';
import { useAppSelector } from '@/store/hooks';

export const Course = () => {
  const { isLoading, course } = useCourse();
  const { isLoading: loadingIP } = useAppSelector(state => state.userIP);

  if (isLoading || loadingIP) {
    return <Loader />;
  }

  // const groupTeachers: User[][] | undefined = chunkArray(course?.teachers, 1);

  return (
    <div className="lms-item">
      <Hero />
      {/* <Specs /> */}
      <Learnings />
      <Structure />
      {course?.mode === 'online' && <Schedules />}
      {/* {groupTeachers.length > 0 && <Teachers groupTeachers={groupTeachers} />} */}
      <Certifications />
      <Related />
      <Questions />
    </div>
  );
};
