import * as React from 'react';
import { Form, FormikHelpers, Formik } from 'formik';
import * as Yup from 'yup';
import { dniOptions } from '@/utils';
import { PhoneInputField, SelectComponent, TextInput } from '@/components/form';
import { FormValues } from '../interfaces';
import { commonValidation, DNIValidation, InputContainer, passportValidation } from './index';
import { useAppSelector } from '@/store/hooks';
import { ErrorMessage } from '@/components/errorMessage';
import { countryOptions } from '@/features/user';
// eslint-disable-next-line no-restricted-imports
import '@/features/purchase/Purchase.css';

type PurchaseFormProps = {
  children?: React.ReactNode;
  initialValues: FormValues;
  schema: any;
  handleSubmit: (values: FormValues, actions: FormikHelpers<FormValues>) => Promise<void>;
  logged: boolean;
  errorsResponse?: string;
};

export const DataForm = ({ initialValues, schema, ...props }: PurchaseFormProps) => {
  const { user } = useAppSelector(state => state.user);
  const [isDNISelected, setIsDNISelected] = React.useState(false);
  const [isPassportSelected, setIsPassportSelected] = React.useState(false);

  const handleCurrentOption = ({ value }: any) => {
    setIsDNISelected(value === 'DNI');
    setIsPassportSelected(value === 'PSP');
    return value === 'DNI';
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        isDNISelected
          ? schema.concat(DNIValidation)
          : isPassportSelected
          ? schema.concat(passportValidation)
          : schema.concat(commonValidation)
      }
      onSubmit={props.handleSubmit}>
      {formik => (
        <Form>
          {props.errorsResponse && (
            <ErrorMessage message="El Correo electrónico ingresado, ya esta registrado en nuestra base de datos" />
          )}
          {props.children}
          <div className="row mb-2 pe-sm-5">
            <InputContainer errors={formik.errors.name} touched={formik.touched.name}>
              <TextInput label="Nombre *" name="name" placeholder="Escribí tu nombre" />
            </InputContainer>
            <InputContainer errors={formik.errors.surname} touched={formik.touched.surname}>
              <TextInput label="Apellido *" name="surname" placeholder="Escribí tu apellido" />
            </InputContainer>
          </div>
          <div className="row mb-2 pe-sm-5">
            <div
              className={`col-md-6 ${
                formik.errors.tipodoc && formik.touched.tipodoc ? ' error' : ''
              }`}>
              <label htmlFor="tipodoc" className="form-label">
                Tipo de Id. *
              </label>
              <SelectComponent
                att={'tipodoc'}
                fullWidth={true}
                options={dniOptions}
                placeholder={'Seleccionar el tipo de Id.'}
                formik={formik}
                handleKeys={any => {}}
                handleCurrentOption={handleCurrentOption}
              />
            </div>
            <InputContainer errors={formik.errors.nrodoc} touched={formik.touched.nrodoc}>
              <TextInput
                label="Número de Id. *"
                name="nrodoc"
                placeholder={
                  isDNISelected
                    ? 'Ej.: 12345678'
                    : isPassportSelected
                    ? 'Ej.: AA1234567'
                    : '"Nro. de identificación"'
                }
                maxLength={16}
              />{' '}
            </InputContainer>
          </div>
          <div className="row mb-2 pe-sm-5">
            <InputContainer
              errors={formik.errors.phone?.countryCode}
              touched={formik.touched.phone?.countryCode}>
              <PhoneInputField label="Código de país *" name="phone.countryCode" />
            </InputContainer>
            <div className="col-md-6 ">
              <div className="row">
                <InputContainer
                  errors={formik.errors.phone?.area}
                  touched={formik.touched.phone?.area}>
                  <TextInput
                    label="Código de área *"
                    name="phone.area"
                    placeholder="Ej.:11"
                    type="number"
                  />
                </InputContainer>
                <InputContainer
                  errors={formik.errors.phone?.number}
                  touched={formik.touched.phone?.number}>
                  <TextInput
                    label="Número de teléfono *"
                    name="phone.number"
                    placeholder="Ej.: 1234567"
                    type="number"
                  />
                </InputContainer>
              </div>
            </div>
          </div>
          <div className="row mb-2 pe-sm-5">
            {props.logged && (
              <div className="col-md-6">
                <div className="data">
                  <div className="d-flex justify-content-center flex-column">
                    <span className="mb-2">
                      <i className="fas fa-check-circle"> </i> Dirección de correo verificada
                    </span>
                    <input
                      className="form-control"
                      defaultValue={user.mail}
                      disabled
                      style={{ cursor: 'auto' }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className={`col-md-6 ${
                formik.errors.address?.country && formik.touched.address?.country ? ' error' : ''
              }`}>
              <label htmlFor="address.country" className="form-label">
                País de Residencia *
              </label>
              <SelectComponent
                att={'address'}
                subatt={'country'}
                options={countryOptions.map(({ label, value }) => ({ value: value, label: label }))}
                placeholder={'Seleccionar Pais'}
                formik={formik}
                fullWidth={true}
                handleKeys={any => {}}
              />
            </div>
            <InputContainer
              errors={formik.errors.address?.state}
              touched={formik.touched.address?.state}>
              <TextInput
                label="Provincia / Estado / Región *"
                name="address.state"
                placeholder="Ingresá lugar de residencia"
              />
            </InputContainer>
            <InputContainer
              errors={formik.errors.address?.city}
              touched={formik.touched.address?.city}>
              <TextInput label="Ciudad *" name="address.city" placeholder="Ingresá la ciudad" />
            </InputContainer>
            <InputContainer
              errors={formik.errors.address?.street}
              touched={formik.touched.address?.street}>
              <TextInput
                label="Dirección *"
                name="address.street"
                placeholder="Ingresa el domicilio"
              />
            </InputContainer>
            <div className="col-md-6">
              <div className="row">
                <InputContainer
                  errors={formik.errors.address?.streetNumber}
                  touched={formik.touched.address?.streetNumber}>
                  <TextInput
                    label="Numero "
                    name="address.streetNumber"
                    placeholder="Ej.: 234"
                    type="number"
                  />
                </InputContainer>
                <InputContainer
                  errors={formik.errors.address?.departmentNumber}
                  touched={formik.touched.address?.departmentNumber}>
                  <TextInput
                    label="Piso / Dpto "
                    name="address.departmentNumber"
                    placeholder="Ej.: 2D"
                  />
                </InputContainer>
              </div>
            </div>
          </div>
          <p className="text-end mt-4">
            <button type="submit" className="btn primary-btn btn-rounded red_bg white">
              continuar
            </button>
          </p>
        </Form>
      )}
    </Formik>
  );
};
