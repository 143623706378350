import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayPalResponse } from './interfaces/PayPalResponse';

interface paymentSliceState {
  payment: string | null;
  isLoading: boolean;
}

const paymentSlice_INITIAL_STATE: paymentSliceState = {
  payment: null,
  isLoading: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: paymentSlice_INITIAL_STATE,
  reducers: {
    setPayment(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.payment = action.payload;
    },
  },
});

export const { setPayment } = paymentSlice.actions;
