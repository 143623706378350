type InputContainerProps = {
  children: React.ReactNode;
  errors: string | undefined;
  touched: boolean | undefined;
  colSize?: string;
};

export const InputContainer = ({
  children,
  errors,
  touched,
  colSize = 'col-sm-6',
}: InputContainerProps) => {
  return <div className={`${colSize} ${errors && touched ? ' error' : ''}`}>{children}</div>;
};
