import React from 'react';
import { NavBar } from './NavBar';

export const Header: React.FC = () => {
  return (
    <header className="logged-in">
      <NavBar />
    </header>
  );
};
