// eslint-disable-next-line no-restricted-imports
import styles from '@/features/purchase/Purchase.module.css';
import { Link } from 'react-router-dom';
import { usePurchase } from '../hooks';
import { Loader } from './Loader';

export const Thanks = () => {
  const { isLoading } = usePurchase();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.thanks}>
      <div className="row">
        <div className="col-sm-6 p-sm-5 pe-sm-0"></div>
        <section className={`${styles.compra} col-sm-6 p-5 ps-3`}>
          <h1>Gracias por tu compra</h1>
          <p>Podés comenzar a cursar ingresando en mis programas</p>
          <p className="text-end mt-4">
            <Link to={'/'} className="btn secondary-btn btn-rounded me-3">
              SALIR
            </Link>
            <Link to={'/usuario/programas'} className="btn primary-btn btn-rounded red_bg white">
              Continuar
            </Link>
          </p>
        </section>
      </div>
    </div>
  );
};
