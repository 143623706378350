import { CourseResponse } from '@/features/courses';
import { axios } from '@/lib/axios';
import { env } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { MercadoPagoResponse } from './interfaces/MercadoPagoResponse';
// import { useParams } from "react-router-dom";
declare global {
  interface Window {
    MercadoPago?: any;
  }
}
// TODO: improve this, this is just a quick fix
export interface ItemCourse {
  id: string;
  title: string;
  description: string;
  picture_url: string;
  currency_id?: string;
  quantity?: number;
  unit_price?: number;
}

export const useGetUrlOrder = () => {
  const [url, setUrl] = useState<string | null>(null);
  const domine =
    env === 'development'
      ? 'http://localhost:3000'
      : env === 'production'
      ? 'https://www.mundose.com'
      : 'https://testing.mundose.com';

  const getUrlPreference = useCallback(
    async (course: CourseResponse, email: string | null, price: number) => {
      if (!course && !email) {
        return;
      }

      try {
        const dataToSend = {
          items: {
            id: course.shortname,
            title: course.fullname,
            currency_id: 'ARS',
            //   picture_url: 'https://www.mercadopago.com/org-img/MP3/home/logomp3.gif',
            description: course.description || 'description',
            quantity: 1,
            unitPrice: price,
          },
          auto_return: 'approved',
          payerObject: {
            email,
          },
          back_urlsObject: {
            success: `${domine}/programas/${course.id}/compra/gracias`,
            failure: `${domine}/programas/${course.id}/compra`,
            pending: 'https://www.success.com',
          },
          external_reference: email,
        };

        const response = await axios.post<MercadoPagoResponse>(
          '/mercadopago/createPreferenceMp',
          dataToSend
        );
        setUrl(response.data.initPoint);
      } catch (error) {
        return { error: error };
      }
    },
    []
  );

  return { url, getUrlPreference };
};
