import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { confirmPurchase } from '@/store/slices';

import { useGetPayment } from './useGetPayment';
import { useNavigate, useParams } from 'react-router-dom';

export const usePurchase = () => {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isLoading, user: userPurchase, billingData } = useAppSelector(state => state.purchase);
  const { user } = useAppSelector(state => state.user);

  const { payment } = useGetPayment();

  const confirmPurchaseActions = async () => {
    const response = await dispatch(
      confirmPurchase(userPurchase, payment, billingData, params.id, user.id)
    );

    if (response.error || response !== 200) {
      navigate(`/programas/${params.id}/compra/error`, { replace: true });
    }
  };

  useEffect(() => {
    if (!isLoading && payment) {
      confirmPurchaseActions();
    }
  }, [payment]);

  return {
    isLoading,
    userPurchase,
  };
};
