import { Route, Routes, useLocation } from 'react-router-dom';
import { getToken } from '@/utils/cookieManager';
import { PrivateRoutes, privateRoutes } from './private';
import { PublicRoutes, publicRoutes } from './public';
import { ModalRoute, modalsRoutes } from './modals';

export const Navigation = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const auth = getToken() !== '';

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        {publicRoutes.map((route: any) => (
          <Route
            key={route.path}
            path={route.path}
            element={<PublicRoutes>{route.element}</PublicRoutes>}
          />
        ))}
        {auth &&
          privateRoutes.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              element={<PrivateRoutes>{route.element}</PrivateRoutes>}
            />
          ))}
      </Routes>

      {state?.backgroundLocation && (
        <Routes>
          {modalsRoutes.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              element={<ModalRoute title={route.title}>{route.element}</ModalRoute>}
            />
          ))}
        </Routes>
      )}
    </>
  );
};
