import { CertificateResponse } from '@/features/user';
import { axios } from '@/lib/axios';
import { Dispatch } from '@reduxjs/toolkit';

import { setCertificates, startLoadingCertificates } from './certificates';

export const getMyCertificates = (dni: string, docType: string, email: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingCertificates());
    // if (!dni || !docType) {
    //   return dispatch(setCertificates({} as CertificateResponse));
    // }
    try {
      const response = await axios.post<CertificateResponse>(
        '/course/find_user_certificates_crm_moodle',
        {
          dni,
          tipodocumento: docType,
          email,
        }
      );

      dispatch(setCertificates(response.data));
    } catch (error) {
      throw { error: error };
    }
  };
};
