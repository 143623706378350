import React from 'react';
import { resendEmail } from '@/features/authentication';
import { getUserEmail } from '@/utils';

export const ConfirmAccount: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const handleSendEmail = async () => {
    await resendEmail(getUserEmail());
  };

  return (
    <div className="px-5">
      <figure className="d-flex justify-content-center align-items-center">
        <img src={'/img/resetPass.svg'} alt="confirm account" />
      </figure>
      <h3 className="my-3 text-center">Ya casi terminás, revisá tu correo</h3>
      <p className="mb-3 small text-center">
        Te vamos a enviar un correo con la activación de la cuenta, verifica tu correo electrónico o
        spam.
      </p>
      <p className="mb-3 small text-center">
        <a
          onClick={() => {
            setLoading(true);
            handleSendEmail().then(() => setLoading(false));
          }}>
          <p className={`${loading ? '' : 'link'}`}>
            {loading ? 'Enviando...' : 'Reenviar correo'}
          </p>
        </a>
      </p>
    </div>
  );
};
