import { InquiryResponse } from '@/features/inquiry';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface inquiryState {
  isLoading: boolean;
  inquiry: InquiryResponse[];
}

const inquiry_INITIAL_STATE: inquiryState = {
  isLoading: false,
  inquiry: [],
};

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState: inquiry_INITIAL_STATE,
  reducers: {
    startLoadingInquiry: state => {
      state.isLoading = true;
    },
    setInquiry: (state, action: PayloadAction<InquiryResponse[]>) => {
      state.isLoading = false;
      state.inquiry = action.payload;
    },
  },
});

export const { startLoadingInquiry, setInquiry } = inquirySlice.actions;
