import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

// eslint-disable-next-line no-restricted-imports
import styles from '@/features/purchase/Purchase.module.css';
import { CheckboxInput } from '@/components/form';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setChecked } from '@/store/slices';
import { MercadoPago, Paypal } from '../components';
import { reviewSchema } from '.';

export const ReviewForm = () => {
  const { course, price } = useAppSelector(state => state.course);
  const { userDataChecked, billingData } = useAppSelector(state => state.purchase);

  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [newChecked, setNewChecked] = useState(location.state?.newChecked);

  const { userIP } = useAppSelector(state => state.userIP);

  useEffect(() => {
    if (!userDataChecked && !billingData?.name) {
      setError(true);
    }
  }, [userDataChecked]);

  const onChecked = (value: boolean, setFieldValue: any) => {
    if (value) setNewChecked(false);
    dispatch(setChecked(value));
    setError(false);
  };

  const handleClick = () => navigate(`/programas/${course.id}/compra/factura`);

  return (
    <>
      <header className="mb-4">
        <div className="d-flex flex-xs-column justify-content-between align-items-center">
          <h1 className="m-0">Revisa tu compra</h1>
          <p className="text-end m-0">Paso 2 de 2</p>
        </div>
        <hr className={styles.separator} />
        <h3>Datos para tu factura</h3>
      </header>
      <Formik
        initialValues={{
          userData: false,
          terms: false,
        }}
        validationSchema={reviewSchema}
        onSubmit={(values, actions) => {
          console.log(values);
        }}>
        {({ errors, dirty, handleChange, setFieldValue }) => (
          <Form>
            <article className="usar-datos-personales mb-5">
              <div className="form-check mb-4">
                <CheckboxInput
                  name="userData"
                  label="Usar datos personales para facturación."
                  onChange={(e: any) => {
                    handleChange(e);
                    onChecked(e.target.checked, setFieldValue);
                  }}
                />
              </div>
              <div className="form-check mb-4">
                <CheckboxInput
                  name="billData"
                  label="Cargar nuevos datos de facturación."
                  onChange={handleClick}
                  value={newChecked}
                  checked={newChecked ? 'checked' : ''}
                />
              </div>
            </article>
            <article
              className={`${userIP?.formaPago === 'MercadoPago' ? 'mercadoPago' : 'payPal'}`}>
              <figure className="mb-4">
                <img
                  src={`${
                    userIP?.formaPago === 'MercadoPago'
                      ? '/img/mercado_pago.svg'
                      : '/img/PayPal.svg'
                  }`}
                  alt={userIP?.formaPago}
                />
              </figure>
              <h3 className="mb-2">
                Pago a través de{' '}
                {`${userIP?.formaPago === 'MercadoPago' ? 'Mercado Pago' : 'PayPal'}`}
              </h3>
              <p className="mb-4">
                Presiona IR A PAGAR para definir las formas de pago dentro de la plataforma.
              </p>
              <div className={`form-check mb-4 `}>
                <CheckboxInput
                  name="terms"
                  label={
                    <>
                      Leí y acepto las{' '}
                      <Link
                        to={'/legales'}
                        title="Políticas de Cambios y Cancelaciones"
                        target="_blank"
                        className="link">
                        Políticas de Cambios y Cancelaciones.
                      </Link>
                    </>
                  }
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                />
              </div>
            </article>
            <div>
              {userIP?.formaPago === 'MercadoPago' ? (
                <MercadoPago
                  dirty={dirty}
                  errors={{ ...errors, validateData: error }}
                  price={Number(price?.listprice.split(',').join(''))}
                />
              ) : (
                <Paypal
                  dirty={dirty}
                  errors={{ ...errors, validateData: error }}
                  price={price?.listprice}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
