import React, { useEffect } from 'react';

import {
  Hero,
  LearnWithUs,
  RelatedNews,
  Skills,
  UniversityAlliances,
  WeTransformLearning,
} from '@/features/home';
import { Footer } from '@/components/footer';
import { Header } from '@/components/header';
import { toTop } from '@/utils/toTop';

export const Home = () => {
  useEffect(() => {
    toTop();
  }, []);

  return (
    <div className="home">
      <Hero />
      <Skills />
      <UniversityAlliances />
      <LearnWithUs />
      <WeTransformLearning />
      <RelatedNews />
    </div>
  );
};
