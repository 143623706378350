import React from 'react';
import { getUserEmail } from '@/utils';
import { resetPassword } from '@/features/user';

export const ConfirmNewPassword: React.FC = () => {
  const email = getUserEmail();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="px-5">
      <figure className="d-flex justify-content-center align-items-center">
        <img src={'/img/resetPass.svg'} alt="confirm new password" />
      </figure>
      <h3 className="my-3 text-center">Ya casi terminas</h3>
      <p className="mb-3 small text-center">
        {`Te enviamos un enlace para restablecer tu contraseña a la dirección ${email}`}
      </p>
      <a
        onClick={() => {
          setLoading(true);
          resetPassword(email).then(() => setLoading(false));
        }}>
        <p className={`mb-3 small text-center ${loading ? '' : 'link'}`}>
          {loading ? 'Enviando...' : 'No te llego? Enviar de nuevo'}
        </p>
      </a>
    </div>
  );
};
