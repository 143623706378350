import React, { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import '@/features/purchase/Purchase.css';
import { Outlet } from 'react-router-dom';
import { toTop } from '@/utils';

export const LayoutFeedback = () => {
  useEffect(() => toTop(), []);

  return (
    <div>
      <main className="purchase">
        <div className="container">
          <Outlet />
        </div>
      </main>
    </div>
  );
};
