/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { env } from '@/utils';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const LegalOptions = () => {
  const navigate = useNavigate();
  return (
    <section className="legales_main d-flex align-items-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 d-flex justify-content-start align-items-start flex-column">
            <header>
              <h1>Privacidad y Términos</h1>
              <p>Elige un tema a consultar</p>
            </header>
            <div className="option-list w-100">
              <ul className="list-unstyled">
                <li
                  onClick={() => navigate(`/legales/Politica-de-Privacidad`)}
                  className="mt-3 shadow-1 w-100">
                  Politica de Privacidad
                  <button className="btn btn-large">
                    <i className="fas fa-arrow-right white"></i>
                  </button>
                </li>
                <li
                  onClick={() => navigate(`/legales/Politica-de-Compras-y-Cancelaciones`)}
                  className="mt-3 shadow-1 w-100">
                  Politica de Compras y Cancelaciones
                  <button className="btn btn-large">
                    <i className="fas fa-arrow-right white"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
