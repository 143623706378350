import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserIPResponse } from './interfaces';

interface userIPState {
  userIP: UserIPResponse;
  isLoading: boolean;
}

const userIP_INITIAL_STATE: userIPState = {
  userIP: {
    ipAddress: '',
    country: '',
    countryCod: '',
    formaPago: '',
    countryCurrencyISO3: '',
    countryCurrencySimbolo: '',
    countryCurrencyNombre: '',
    countryCurrencyCodigo: 0,
  },
  isLoading: false,
};

export const userIPSlice = createSlice({
  name: 'ip',
  initialState: userIP_INITIAL_STATE,
  reducers: {
    startLoadingUserIP: state => {
      state.isLoading = true;
    },
    setUserIP: (state, action: PayloadAction<UserIPResponse>) => {
      state.isLoading = false;
      state.userIP = action.payload;
    },
  },
});

export const { startLoadingUserIP, setUserIP } = userIPSlice.actions;
