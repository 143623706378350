import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useGetUrlOrder } from '../order';
import { getObjectKeys } from '@/utils';
import { userActions } from '@/store/slices/user';
import { Spinner } from 'react-bootstrap';

type MercadoPagoProps = {
  dirty: boolean;
  errors: any;
  price: number;
};

export const MercadoPago = ({ dirty, errors, price }: MercadoPagoProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { course } = useAppSelector(state => state.course);
  const { user } = useAppSelector(state => state.purchase);
  const { activation, loadingActions } = useAppSelector(state => state.user);

  const { url, getUrlPreference } = useGetUrlOrder();

  useEffect(() => {
    const email = user?.mail;
    if (course && email) {
      getUrlPreference(course, email, price);
    }
  }, [course]);

  const userActionsPurchase = async () => {
    const formatUser = user;
    const { mail, password, nrodoc: nroDoc, ...rest } = formatUser;
    const renamedProps = { nroDoc, ...rest };

    const attributes = getObjectKeys({ user: renamedProps });

    const response = await dispatch(userActions(user, activation, attributes));
    if (response.error) {
      navigate(`/programas/${course.id}/compra/error`, { replace: true });
    } else {
      window.open(url as string, '_self');
    }
  };

  const handleClick = e => {
    e.preventDefault();
    userActionsPurchase();
  };

  return (
    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
      <button
        className="btn btn-rounded back me-md-2"
        disabled={loadingActions}
        onClick={() => navigate(-1)}>
        VOLVER
      </button>
      <button
        className="btn primary-btn btn-rounded red_bg white d-flex align-items-center justify-content-center payment-button"
        disabled={(!url && !dirty) || errors.terms || errors.validateData}
        onClick={handleClick}>
        {loadingActions || !url ? (
          <Spinner animation="border" />
        ) : (
          <span className="payment-text">IR A PAGAR</span>
        )}
      </button>
    </div>
  );
};
