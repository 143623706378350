import * as React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

// eslint-disable-next-line no-restricted-imports
import '@/features/courses/Courses.css';
import { mode } from '@/features/course';
import { CourseResponse } from '../interfaces';

export const Thumbnail: React.FC<{ course?: CourseResponse; empty?: boolean }> = ({
  course,
  empty,
}) => {
  function msToTime(value?: number) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return value ? 'Inicia ' + dayjs(value).format('DD/MM/YYYY') : '';
  }

  return (
    <article className="col-sm-4 course_thumb">
      <figure className="modalidad modalidad-online">
        <img
          className="img-fluid"
          src={
            course
              ? course.image
                ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  'data:image/png;base64,' + course?.image?.data
                : '/img/mindfulness-y-gestion-emocional.png'
              : '/img/shimmerLoad.gif'
          }
          alt={course ? course.code : 'Cargando...'}
        />
        <figcaption className="d-flex flex-column py-4 justify-content-between">
          <div className="d-flex flex-column">
            <div className="tags d-flex gap-2 justify-content-between align-items-center px-2 pb-4">
              <em className="course_icon">
                <img
                  src={`/img/${
                    course?.mode === 'online' ? 'image_online_icon.svg' : 'onDemand_icon.svg'
                  }`}
                  alt="..."
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              </em>
              {course?.encounters && (
                <h5 className="blue_pill p-2 m-0">
                  {empty ? '' : course?.encounters}{' '}
                  {course?.mode === 'online' ? 'ENCUENTROS' : 'CLASES'}
                </h5>
              )}
              {course?.dedication && (
                <>
                  {!empty && mode[course.mode].dedication && (
                    <h5 className="blue_pill p-2 m-0">{`Dedicación ${course?.dedication}`}</h5>
                  )}
                  {!empty && mode[course.mode].startDate && (
                    <h5 className="blue_pill p-2 m-0">{msToTime(course?.start)}</h5>
                  )}
                </>
              )}
            </div>
            <h3 className="mb-2 px-4">{empty ? '' : course?.fullname}</h3>
            <p className="px-4 m-0">{empty ? '' : course?.description}</p>
          </div>
          <p className="px-4 m-0">
            {!empty && (
              <Link
                to={`/programas/${course?.id}`}
                className="btn primary-btn btn-small w-100 mt-4">
                Más información
              </Link>
            )}
          </p>
        </figcaption>
      </figure>
    </article>
  );
};
