import {
  Ad,
  Ar,
  Bo,
  Br,
  Bz,
  Cl,
  Co,
  Cr,
  Cu,
  Do,
  Ec,
  Es,
  Gi,
  Gq,
  Gt,
  Hn,
  Mx,
  Ni,
  Pa,
  Pe,
  Pr,
  Py,
  Sv,
  Us,
  Uy,
  Ve,
} from 'react-flags-select';

export enum GenderEnum {
  female = 'femenino',
  male = 'masculino',
  other = 'sin especificar',
}

export const EducationLevelType = [
  { value: 'Secundario', label: 'Secundario' },
  { value: 'Secundario incompleto', label: 'Secundario incompleto' },
  { value: 'Terciario incompleto', label: 'Terciario incompleto' },
  { value: 'Universitario', label: 'Universitario' },
  { value: 'Universitario incompleto', label: 'Universitario incompleto' },
  { value: 'Posgrado', label: 'Posgrado' },
  { value: 'Posgrado incompleto', label: 'Posgrado incompleto' },
];
export const KnowledgeAreaType = [
  { value: 'Abogacía', label: 'Abogacía' },
  { value: 'Administración De Empresas', label: 'Administración De Empresas' },
  { value: 'Administración Financiera', label: 'Administración Financiera' },
  { value: 'Agrobiotecnología', label: 'Agrobiotecnología' },
  { value: 'Agroindustria', label: 'Agroindustria' },
  { value: 'Agronegocios', label: 'Agronegocios' },
  { value: 'Agronomía', label: 'Agronomía' },
  { value: 'Alimentos y Biotecnología', label: 'Alimentos y Biotecnología' },
  { value: 'Antropología', label: 'Antropología' },
  { value: 'Arquitectura', label: 'Arquitectura' },
  { value: 'Artes Audiovisuales', label: 'Artes Audiovisuales' },
  { value: 'Artes Escénicas', label: 'Artes Escénicas' },
  { value: 'Artes Visuales', label: 'Artes Visuales' },
  { value: 'Biología', label: 'Biología' },
  { value: 'Coaching', label: 'Coaching' },
  { value: 'Comunicación Social', label: 'Comunicación Social' },
  { value: 'Contaduría', label: 'Contaduría' },
  { value: 'Cs. Políticas', label: 'Cs. Políticas' },
  { value: 'Deportes', label: 'Deportes' },
  { value: 'Diseño de Interiores', label: 'Diseño de Interiores' },
  { value: 'Diseño de Moda', label: 'Diseño de Moda' },
  { value: 'Diseño Gráfico', label: 'Diseño Gráfico' },
  { value: 'Diseño Industrial', label: 'Diseño Industrial' },
  { value: 'Docencia', label: 'Docencia' },
  { value: 'Economía', label: 'Economía' },
  { value: 'Enfermería', label: 'Enfermería' },
  { value: 'Farmacéutica', label: 'Farmacéutica' },
  { value: 'Filosofía', label: 'Filosofía' },
  { value: 'Física', label: 'Física' },
  { value: 'Geografía', label: 'Geografía' },
  { value: 'Historia', label: 'Historia' },
  { value: 'Humanidades', label: 'Humanidades' },
  { value: 'Informática', label: 'Informática' },
  { value: 'Ingeniería Biomédica', label: 'Ingeniería Biomédica' },
  { value: 'Ingeniería Bioquímica', label: 'Ingeniería Bioquímica' },
  { value: 'Ingeniería Civil', label: 'Ingeniería Civil' },
  { value: 'Ingeniería Electrónica', label: 'Ingeniería Electrónica' },
  { value: 'Ingeniería Industrial', label: 'Ingeniería Industrial' },
  { value: 'Ingeniería Informática', label: 'Ingeniería Informática' },
  { value: 'Ingeniería Química', label: 'Ingeniería Química' },
  { value: 'Letras', label: 'Letras' },
  { value: 'Locución', label: 'Locución' },
  { value: 'Logística y Transporte', label: 'Logística y Transporte' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Matemáticas', label: 'Matemáticas' },
  { value: 'Medicina', label: 'Medicina' },
  { value: 'Música', label: 'Música' },
  { value: 'Nanotecnología', label: 'Nanotecnología' },
  { value: 'Periodismo', label: 'Periodismo' },
  { value: 'Psicología', label: 'Psicología' },
  { value: 'Química', label: 'Química' },
  { value: 'Recursos Humanos', label: 'Recursos Humanos' },
  { value: 'Relaciones Internacionales', label: 'Relaciones Internacionales' },
  { value: 'Relaciones Públicas', label: 'Relaciones Públicas' },
  { value: 'Robótica', label: 'Robótica' },
  { value: 'Sistemas', label: 'Sistemas' },
  { value: 'Sociología', label: 'Sociología' },
  { value: 'Sonido', label: 'Sonido' },
  { value: 'Tecnologías de la Información', label: 'Tecnologías de la Información' },
  { value: 'Trabajo Social', label: 'Trabajo Social' },
  { value: 'Traducción', label: 'Traducción' },
  { value: 'Turismo', label: 'Turismo' },
  { value: 'Urbanismo y Medioambiente', label: 'Urbanismo y Medioambiente' },
  { value: 'Veterinaria', label: 'Veterinaria' },
  { value: 'Videojuegos', label: 'Videojuegos' },
  { value: 'Otros', label: 'Otros' },
];
export const OcupationType = [
  { value: 'Estudiante', label: 'Estudiante' },
  { value: 'Emprendedor', label: 'Emprendedor' },
  { value: 'Independiente', label: 'Independiente' },
  { value: 'Empresario', label: 'Empresario' },
  { value: 'Comerciante', label: 'Comerciante' },
  { value: 'Director / Gerente', label: 'Director / Gerente' },
  { value: 'Funcionario del Estado', label: 'Funcionario del Estado' },
  { value: 'Supervisor / Coordinador', label: 'Supervisor / Coordinador' },
  { value: 'Empleado Privado', label: 'Empleado Privado' },
  { value: 'Empleado Público', label: 'Empleado Público' },
  { value: 'Desempleado', label: 'Desempleado' },
  { value: 'No trabaja', label: 'No trabaja' },
];

export const PersonnelInChargeType = [
  { value: 'No posee', label: 'No posee' },
  { value: '1 a 5', label: '1 a 5' },
  { value: '6 a 15', label: '6 a 15' },
  { value: '16 a 30', label: '16 a 30' },
  { value: '+30', label: '+30' },
];

export const DniType = [
  { value: 'CC', label: 'CC: Cedula de Ciudadania' },
  { value: 'CIE', label: 'CIE: Cedula de identidad electoral' },
  { value: 'CIC', label: 'CIC: Cedula de Identidad Civil' },
  { value: 'CIP', label: 'CIP: Cedula de Identidad Personal' },
  { value: 'DNI', label: 'DNI: Documento Nacional de Identidad' },
  { value: 'DIP', label: 'DIP: Documento Personal de Id' },
  { value: 'DUI', label: 'DUI: Documento Unico de Identidad' },
  { value: 'LNC', label: 'LNC: Licencia de Conducir' },
  { value: 'PSP', label: 'PSP: Pasaporte' },
];

export const countryLabels = {
  AD: 'Andorra',
  AR: 'Argentina',
  BZ: 'Belice',
  CL: 'Chile',
  UY: 'Uruguay',
  PY: 'Paraguay',
  BO: 'Bolivia',
  PE: 'Peru',
  EC: 'Ecuador',
  MX: 'Mexico',
  HN: 'Honduras',
  GT: 'Guatemala',
  CO: 'Colombia',
  CR: 'Costa rica',
  ES: 'España',
  US: 'Estados Unidos',
  BR: 'Brasil',
  CU: 'Cuba',
  SV: 'El Salvador',
  GI: 'Gibraltar',
  GQ: 'Guinea Ecuatorial',
  NI: 'Nicaragua',
  PA: 'Panamá',
  PR: 'Puerto Rico',
  DO: 'Rep. Dominicana',
  VE: 'Venezuela',
  OT: 'Otro',
};

/*eslint-disable */
export const countryOptions = [
  { value: 'Andorra', label: <div> <Ad /> Andorra </div> },
  { value: 'Argentina', label: <div> <Ar /> Argentina </div> },
  { value: 'Belice', label: <div> <Bz /> Belice </div> },
  { value: 'Bolivia', label: <div> <Bo /> Bolivia </div> },
  { value: 'Brasil', label: <div> <Br /> Brasil </div> },
  { value: 'Chile', label: <div> <Cl /> Chile </div> },
  { value: 'Colombia', label: <div> <Co /> Colombia </div> },
  { value: 'Costa Rica', label: <div> <Cr /> Costa Rica </div> },
  { value: 'Cuba', label: <div> <Cu /> Cuba </div> },
  { value: 'Ecuador', label: <div> <Ec /> Ecuador </div> },
  { value: 'El Salvador', label: <div> <Sv /> El Salvador </div> },
  { value: 'España', label: <div> <Es /> España </div> },
  { value: 'Estados Unidos', label: <div> <Us /> Estados Unidos </div> },
  { value: 'Gibraltar', label: <div> <Gi /> Gibraltar </div> },
  { value: 'Guatemala', label: <div> <Gt /> Guatemala </div> },
  { value: 'Guinea Ecuatorial', label: <div> <Gq /> Guinea Ecuatorial </div> },
  { value: 'Honduras', label: <div> <Hn /> Honduras </div> },
  { value: 'Mexico', label: <div> <Mx /> Mexico </div> },
  { value: 'Nicaragua', label: <div> <Ni /> Nicaragua </div> },
  { value: 'Panamá', label: <div> <Pa /> Panamá </div> },
  { value: 'Paraguay', label: <div> <Py /> Paraguay </div> },
  { value: 'Peru', label: <div> <Pe /> Peru </div> },
  { value: 'Puerto Rico', label: <div> <Pr /> Puerto Rico </div> },
  { value: 'Rep. Dominicana', label: <div> <Do /> Rep. Dominicana </div> },
  { value: 'Uruguay', label: <div> <Uy /> Uruguay </div> },
  { value: 'Venezuela', label: <div> <Ve /> Venezuela </div> },
  { value: 'Otro', label: <div> Otro </div> },
];
/*eslint-enable */

export const countryCodes = {
  Chile: 'CL',
  Uruguay: 'UY',
  Paraguay: 'PY',
  Bolivia: 'BO',
  Peru: 'PE',
  Ecuador: 'EC',
  Mexico: 'MX',
  Honduras: 'HN',
  Guatemala: 'GT',
  Colombia: 'CO',
  'El Salvador': 'SV',
  'Costa rica': 'CR',
  España: 'ES',
  'Estados Unidos': 'US',
  Brasil: 'BR',
  Argentina: 'AR',
  Andorra: 'AD',
  Belice: 'BZ',
  Cuba: 'CU',
  Gibraltar: 'GI',
  'Guinea Ecuatorial': 'GQ',
  Panamá: 'PA',
  Nicaragua: 'NI',
  'Puerto Rico': 'PR',
  'Rep. Dominicana': 'DO',
  Venezuela: 'VE',
  Otro: 'OT',
};
