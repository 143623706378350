import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { LogInView } from './LoginView';
import { signin } from '@/store/slices/user';
import { useAppDispatch } from '@/store/hooks';

export const LogIn = () => {
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().max(200, 'Too Long!').required('Required'),
  });

  const [response, setResponse] = React.useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function submit(values: { email: string; password: string }) {
    dispatch(signin(values.email, values.password)).then(res => {
      if (res.error) setResponse(res.error.message);
      else navigate(location.state.backgroundLocation);
    });
  }

  return (
    <LogInView
      onSubmit={submit}
      location={location}
      response={response}
      validationScheme={LoginSchema}
    />
  );
};
