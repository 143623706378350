import { axios } from '@/lib/axios';
import { FormValues } from '../interfaces/formValues';

export const sendCourseInquiry = async (data: FormValues) => {
  try {
    const response = await axios.post('/inquiry/send', data);
    if (response.data.error) {
      throw new Error();
    } else {
      return response.data;
    }
  } catch (error: any | unknown) {
    throw { error: error };
  }
};
