import { useField } from 'formik';
import ReactFlagsSelect from 'react-flags-select';

type FlagsSelectInputProps = {
  label: string;
  name: string;
  [x: string]: any;
};

export const FlagsSelectInput = ({ label, ...props }: FlagsSelectInputProps) => {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <ReactFlagsSelect
        {...props}
        {...field}
        selected={field.value}
        onSelect={code => {
          helpers.setValue(code);
        }}
        className={'form-control mb-2'}
        placeholder={props.placeholder}
        showOptionLabel
        showSelectedLabel
        countries={[
          'AR',
          'BO',
          'BR',
          'CO',
          'CL',
          'CR',
          'EC',
          'ES',
          'US',
          'GT',
          'HN',
          'MX',
          'PE',
          'PY',
          'SV',
          'UY',
        ]}
        customLabels={{
          CL: 'Chile',
          UY: 'Uruguay',
          PY: 'Paraguay',
          BO: 'Bolivia',
          PE: 'Peru',
          EC: 'Ecuador',
          MX: 'Mexico',
          HN: 'Honduras',
          GT: 'Guatemala',
          CO: 'Colombia',
          SV: 'Salvador',
          CR: 'Costa rica',
          ES: 'España',
          US: 'Estados Unidos',
          BR: 'Brasil',
        }}
        id={props.name}
      />
    </>
  );
};
