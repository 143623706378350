import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '@/interfaces';

interface userState {
  user: User;
  isLoading: boolean;
  activation: boolean;
  loadingActions: boolean;
  error: string;
}

const initialState: userState = {
  user: {} as User,
  isLoading: false,
  activation: false,
  loadingActions: false,
  error: '',
};

export const userSlice = createSlice({
  name: 'userState',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<User | null>) => {
      state.isLoading = false;
      if (action.payload) {
        state.user = action.payload;
      } else {
        state.user = {} as User;
      }
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.activation = true;
      } else {
        state.activation = false;
      }
    },
    startLoadingUser: state => {
      state.isLoading = true;
    },
    setLoadingActions: (state, action: PayloadAction<any>) => {
      state.loadingActions = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loadingActions = false;
    },
  },
});

export const { setUser, startLoadingUser, setActive, setLoadingActions, setError } =
  userSlice.actions;

export default userSlice.reducer;
