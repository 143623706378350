import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '@/features/authentication';
import { useAppSelector } from '@/store/hooks';
import { activateToken, loginMoodleKey } from '@/utils';
import { FormValues } from '../interfaces';
import { createUsersCrm } from '../services';

import CryptoJS from 'crypto-js';

export const useRegister = () => {
  const [errorsResponse, setErrorsResponse] = useState<string | null>(null);
  const { course } = useAppSelector(state => state.course);
  const navigate = useNavigate();

  const registerUser = async (values: FormValues) => {
    const registerDTO = {
      name: values.name,
      surname: values.surname,
      mail: values.mail,
      password: values.password as string,
    };

    const encrypted = CryptoJS.AES.encrypt(registerDTO.password, 'secret key').toString();

    const response = await register(registerDTO);

    if (response?.error) {
      setErrorsResponse('No se ha podido registrar en el sistema');
    } else {
      const responseCrm = await usersCrm(values);
      if (responseCrm.status === 500) {
        return;
      } else {
        activateToken(response.user.token);
        loginMoodleKey(encrypted);
        navigate(`/programas/${course.id}/compra/revisar`);
      }
    }
  };

  const usersCrm = async (values: FormValues) => {
    const { password, ...rest } = values;
    try {
      const response = await createUsersCrm(rest);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        setErrorsResponse('Ocurrió un error. Intente de nuevo más tarde');
      }
      return error.response;
    }
  };

  return {
    registerUser,
    usersCrm,
    errorsResponse,
    setErrorsResponse,
  };
};
