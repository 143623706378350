import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { env } from '@/utils/globals';
import { Navigation } from './routes';
import { useUserSession } from '@/features/session';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getUserIP } from './store/slices/userIP';

function App() {
  const { isLoading } = useUserSession();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    console.log(env);
    // async function fetchSession() {
    //   setLoading(false);
    // }
    // fetchSession();
    dispatch(getUserIP());
  }, []);

  if (isLoading) return <></>;

  return (
    <Router>
      <Navigation />
    </Router>
  );
}

export default App;
