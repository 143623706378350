import { axios } from '@/lib/axios';
import { login } from '@/features/authentication';

export const changePassword = async (
  mail: string,
  currentPassword: string,
  newPassword: string
) => {
  try {
    const loginResponse = await login(mail, currentPassword);
    if (loginResponse.data.error)
      throw Error('La contraseña vieja no es válida', { cause: 'login error' });

    const changePasswordResponse = await axios.post('/user/updatePasswordBasic', {
      mail: mail,
      password: newPassword,
    });
    if (changePasswordResponse.data.error)
      throw Error('Ocurrió un error con el cambio de contraseña');

    return changePasswordResponse.data;
  } catch (error) {
    if (error.cause === 'login error') {
      throw { loginerror: error };
    } else {
      throw { error: error };
    }
  }
};
