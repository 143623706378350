import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useCourse, useDownload } from '@/features/course';
import { ErrorMessage } from '@/components/errorMessage';
import { Hero } from './Hero';

export const CourseInquiryThanks = () => {
  const { course } = useCourse();
  const { downloadFile, download, error } = useDownload();
  const navigate = useNavigate();

  return (
    <div>
      <section className="formConsulta_hero d-flex justify-content-center">
        <div className="container mt-5">
          <div className="row">
            <Hero course={course} />

            <div className="col-sm-6 d-flex justify-content-center align-items-start flex-column position-relative ps-md-5">
              <h2 className="my-3">¡Gracias por contactarte!</h2>
              <p className="mb-3 light-grey">
                En unos minutos recibirás un correo electrónico con toda la información. Revisá
                todas las bandejas de entrada de tu correo, spam, promociones o social.
              </p>
              <p>
                <strong>¡A veces los correos se despistan!</strong>
              </p>
              {error && <ErrorMessage message={'Ocurrio un error al descargar el programa'} />}
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-rounded mt-4 cancelbutton me-md-2"
                  onClick={() => navigate(-1)}>
                  VOLVER
                </button>
                <button
                  onClick={() => downloadFile(course?.shortname)}
                  className="btn primary-btn btn-rounded red_bg white mt-4 d-flex align-items-center justify-content-center downloadbutton"
                  disabled={download}>
                  {download ? <Spinner animation="border" /> : <span>DESCARGAR PROGRAMA</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
