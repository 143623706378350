import { User } from '@/interfaces';
import { toTop } from '@/utils';
import moment from 'dayjs';

type StateProps = {
  user: User;
  setEdit: React.Dispatch<React.SetStateAction<string>>;
};

export const ViewPersonalData: React.FC<StateProps> = ({ user, setEdit }) => {
  const yearCurrent = new Date().getFullYear();
  const yearBirth = new Date(user.birth).getFullYear();
  const formatted = moment(user.birth).format('DD/MM/YYYY');

  return (
    <div className="card datos-personales mb-5">
      <div className="card-body p-5">
        <div className="row mb-5">
          <div className="col-sm-8">
            <h3 className="card-title mb-4">Datos personales</h3>
            <p className="card-subtitle">
              El nombre se crea con tu registro, con el mismo vamos a generar tu certificado.
              Verifica que sea el que aparece en tu documento único de identidad. En caso de que no
              sea el correcto, podés modificarlo por única vez.
            </p>
          </div>
          <div className="col-sm-4 d-flex justify-content-sm-end">
            <figure className="d-flex">
              <img
                src={user.image_profile ? user.image_profile : '/img/mi_perfil.svg'}
                alt="..."
                className="avatar img-fluid"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
                width="144"
              />
            </figure>
          </div>
        </div>
        <ul className="list-unstyled mb-4 data">
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Nombre y apellido</h4>
            <p>{`${user.name} ${user.surname}`}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Fecha de Nacimiento</h4>
            <p>{formatted}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Edad</h4>
            <p>{user.birth && yearCurrent >= yearBirth ? `${yearCurrent - yearBirth} años` : ''}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Género</h4>
            <p>{user.genre}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Nacionalidad</h4>
            <p>{user.nationality}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Tipo y número de identificación</h4>
            <p>
              {user.tipodoc} {user.nrodoc}
            </p>
          </li>
        </ul>
        <p className="text-end mt-2">
          <button
            onClick={() => {
              setEdit('personal');
              toTop();
            }}
            className="btn primary-btn btn-rounded red_bg white btn-small">
            Editar datos
          </button>
        </p>
      </div>
    </div>
  );
};
