import { axios } from '@/lib/axios';

interface RegisterParams {
  name: string;
  surname: string;
  mail: string;
  password: string;
}

export const register = async (data: RegisterParams) => {
  const response = await axios.post('/session/register', data);

  return response.data;
};
