type InputContainerProps = {
  children: React.ReactNode;
  errors: string | undefined;
  touched: boolean | undefined;
  profileSize?: boolean | undefined;
};

export const InputContainer = ({ children, errors, touched, profileSize }: InputContainerProps) => {
  return (
    <div
      className={`col-md-6  ${errors && touched ? ' error' : ''} ${profileSize ? 'col-lg-4' : ''}`}>
      {children}
    </div>
  );
};
