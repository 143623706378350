import React from 'react';
import { getFacebookLink, getLinkedInLink, Certificate } from '@/features/user';

export const MyCertificate: React.FC<{ certificate: Certificate }> = ({ certificate }) => {
  const handleClick = () => (window.location.href = certificate.link_certificado);

  return certificate ? (
    <article className="col-sm-4 mb-5">
      <div
        className={`shadow-sm rounded h-100 ${
          certificate?.condicion !== 'Aprobado' ? 'disabled-card' : ''
        }`}>
        <figure className="m-0">
          <img
            className="img-fluid w-100 "
            src={
              certificate?.certificado_image_base64
                ? 'data:image/png;base64,' + certificate?.certificado_image_base64
                : certificate.link_certificado_image
            }
            alt={certificate?.certificado_image_base64 && certificate?.nombre_curso}
          />

          <figcaption className="py-2 px-3 d-flex flex-column ">
            <h4 className="mb-2 overline d-flex align-items-center gap-2">
              <em>
                <img
                  src={
                    certificate?.condicion !== 'Aprobado'
                      ? '/img/Icon_no_aprobado.svg'
                      : '/img/Icon_aprobado.svg'
                  }
                  alt="aprobado"
                />
              </em>{' '}
              <span className={certificate?.condicion !== 'Aprobado' ? 'red' : ''}>
                {certificate?.condicion}
              </span>
            </h4>
            <button
              type="button"
              className="btn btn-link btn-certificate"
              onClick={handleClick}
              disabled={certificate?.condicion !== 'Aprobado'}>
              <h3>{certificate?.nombre_curso}</h3>
            </button>
          </figcaption>
        </figure>
        <footer className="w-100 m-auto px-4 pb-4 pt-0">
          <ul className="list-unstyled d-flex justify-content-end align-items-center gap-2 m-0">
            <li>
              <a
                rel="noreferrer"
                target={'_blank'}
                className={certificate?.condicion !== 'Aprobado' ? 'disabled-social-link' : ''}
                href={getLinkedInLink(certificate)}>
                <img src={'/img/linkedin.svg'} alt="Compartir en Linkedin" />
              </a>
            </li>
            <li>
              <a
                href={getFacebookLink(certificate.link_certificado_image.toString())}
                target={'_blank'}
                rel="noreferrer"
                className={certificate?.condicion !== 'Aprobado' ? 'disabled-social-link' : ''}>
                <img src={'/img/fb.svg'} alt="Compartir en Facebook" />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </article>
  ) : (
    <article className="col-sm-4 mb-5">
      <div className="shadow-sm rounded h-100">
        <figure className="m-0">
          <img src={'/img/shimmerLoad.gif'} alt={'cargando...'} className="img-fluid w-100 " />
          <figcaption className="py-2 px-3 d-flex flex-column ">
            <h4 className="mb-2 overline d-flex align-items-center gap-2">
              <em />{' '}
            </h4>
            <h3> </h3>
          </figcaption>
        </figure>
        <footer className="w-100 m-auto px-4 pb-4 pt-0" />
      </div>
    </article>
  );
};
