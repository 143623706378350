import { getProgramFileById } from '@/features/course';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

export const useDownload = () => {
  const [download, setDownload] = useState(false);
  const [error, setError] = useState(null);

  const downloadFile = async (shortname: string) => {
    try {
      setDownload(true);

      const {
        data: { data: file },
      } = await getProgramFileById({ code: shortname });

      const byteCharacters: any = window.atob(file);
      if (byteCharacters != null) {
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray as BlobPart], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        if (isMobile) {
          window.open(url, '_self');
        } else {
          window.open(url, '_blank');
        }
      }
      setDownload(false);
    } catch (error) {
      setError(error);
      setDownload(false);
    }
  };

  return {
    downloadFile,
    download,
    error,
  };
};
