import { CourseInquiryThanks } from '@/features/courseInquiry';
import {
  UserContainer,
  ReviewForm,
  Thanks,
  BillContainer,
  Error,
  LayoutFeedback,
} from '@/features/purchase';
import { Course, CourseInquiry, Courses, Purchase } from '@/pages';
import { Route, Routes } from 'react-router-dom';
import { Search } from '../components';

export const CoursesRoutes = () => {
  return (
    <Routes>
      <Route index element={<Courses />} />
      <Route path="categorias/:category/:mode" element={<Search />} />
      <Route path="categorias/:category" element={<Search />} />
      <Route path=":id">
        <Route index element={<Course />} />
        <Route path="consulta" element={<CourseInquiry />} />
        <Route path="consulta/gracias" element={<CourseInquiryThanks />} />
        <Route path="compra" element={<Purchase />}>
          <Route index element={<UserContainer />} />
          <Route path="revisar" element={<ReviewForm />} />
          <Route path="factura" element={<BillContainer />} />
        </Route>
        <Route path="compra" element={<LayoutFeedback />}>
          <Route path="gracias/*" element={<Thanks />} />
          <Route path="error" element={<Error />} />
        </Route>
      </Route>
    </Routes>
  );
};
