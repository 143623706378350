import React from 'react';

export const RelatedNews = () => {
  return (
    <section className="related-news py-100 lighter-grey_bg">
      <div className="container">
        <div className="row">
          <div
            id="testimonials_carousel"
            className="carousel slide testimonials_carousel"
            data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#testimonials_carousel"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"></button>
              <button
                type="button"
                data-bs-target="#testimonials_carousel"
                data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            </div>
            <div className="carousel-inner">
              <div className=" carousel-item active">
                <div className="row">
                  <article className="col-lg-4">
                    <figure>
                      <a
                        href="https://puntoapunto.com.ar/https-puntoapunto-com-ar-la-escuela-de-negocios-mundos-e-proyecta-llegar-a-europa-en-2022/"
                        target="_blank"
                        rel="noreferrer"
                        title="La escuela de negocios Mundos E proyecta llegar a Europa en 2022">
                        <img
                          className="img-fluid"
                          src="/img/puntoApunto.png"
                          width="240px"
                          alt="Punto a punto"
                        />
                      </a>
                    </figure>
                    <hr />
                    <p>La escuela de negocios Mundos E proyecta llegar a Europa 2022</p>
                  </article>
                  <article className="col-lg-4">
                    <figure>
                      <a
                        href="https://puntoapunto.com.ar/mundos-e-suma-nuevos-programas-tecnologia-negocios-y-transformacion-personal/"
                        target="_blank"
                        rel="noreferrer"
                        title="Mundos E suma nuevos programas: tecnología, negocios y transformación personal">
                        <img
                          className="img-fluid"
                          width="245px"
                          src="/img/puntoApunto.png"
                          alt="Punto a punto"
                        />
                      </a>
                    </figure>
                    <hr />
                    <p>
                      Mundos E suma nuevos programas: tecnología, negocios y transformación personal
                    </p>
                  </article>
                  <article className="col-lg-4">
                    <figure>
                      <a
                        href="https://www.lavoz.com.ar/negocios/recursos-humanos-estrategia-2022-y-el-caso-globant/"
                        target="_blank"
                        rel="noreferrer">
                        <img
                          className="img-fluid"
                          src="/img/laVoz.png"
                          alt="LA Voz"
                          width="145px"
                        />
                      </a>
                    </figure>
                    <hr />
                    <p>
                      Llega el segundo encuentro del ciclo anual para gerentes de recursos humanos
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
