// eslint-disable-next-line no-restricted-imports
import styles from '@/features/purchase/Purchase.module.css';
import { Spinner } from 'react-bootstrap';

export const Loader = () => {
  return (
    <div className={styles.thanks}>
      <div className="row">
        <div className="col-sm-6 p-sm-5 pe-sm-0"></div>
        <section className={`${styles.compra} col-sm-6 p-5 ps-3`}>
          <h2>Estamos procesando tu compra...</h2>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" className="mt-4" />
          </div>
        </section>
      </div>
    </div>
  );
};
