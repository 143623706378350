// eslint-disable-next-line no-restricted-imports
import '@/features/courses/Courses.css';
import React, { useMemo, useState } from 'react';
import { useSearch, useCourses } from '../hooks';
import { Loader, Thumbnail } from '../components';
import { DropDown } from '@/components/dropdown';
import { useWindowDimensions } from '@/hooks';

const categoryData: { [key: string]: { label: string; description: string } } = {
  digital: {
    label: 'Habilidades Digitales',
    description:
      'Aprende a incorporar el uso de tecnologías para aplicarlas en este mundo digital.',
  },
  personal: {
    label: 'Habilidades Personales',
    description: 'Desarrolla habilidades humanas para el trabajo en equipo o crecimiento personal.',
  },
  gestion: {
    label: 'Habilidades de Gestión',
    description:
      'Conoce sobre la coordinación en equipos para llevar a cabo o para conseguir algo y resolver problemáticas.',
  },
  none: {
    label: 'Categorías',
    description: '',
  },
};

export const Search = () => {
  const wide = useWindowDimensions(575);

  const {
    onChangeMode,
    modeSelection,
    setCategoryCollapse,
    categoryCollapse,
    categorySelection,
    onChangeCategory,
    params,
  } = useSearch();

  const { courses, isLoading } = useCourses();

  const total = useMemo(() => {
    let mode = params.mode;
    let category = params.category;
    if (mode === undefined) mode = 'none';
    if (category === undefined) category = 'none';

    return courses
      .filter(item => (category === 'none' ? item : item.category === category))
      .filter(item => (mode === 'none' ? item : item.mode === mode));
  }, [params.category, params.mode, courses]);

  const handleClick = e => {
    e.preventDefault();
    const { name } = e.target;
    onChangeMode({
      category: params.category ? params.category : 'none',
      mode: params.mode === name ? 'none' : name,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const setCategorySelection = (key: string) => {
    onChangeMode({
      category: key,
      mode: params.mode ? params.mode : 'none',
    });
  };

  return (
    <div>
      <section className="courses_hero d-flex align-items-center pt-3 pb-5">
        <div className="container">
          <div className="row filter-nav lighter-grey_bg rounded-3 p-3 d-flex justify-content-between align-items-center">
            <div className="col-sm-8 toolbar d-flex gap-3 align-items-center">
              <h6 className="m-0">Filtrar</h6>
              <div className="m-0 p-0 d-flex gap-3">
                <button
                  name="ondemand"
                  className={`grey_pill ${
                    params.mode === 'ondemand' ? 'modeIsActive' : ''
                  } btn btn-xs`}
                  onClick={e => handleClick(e)}>
                  MODALIDAD ONDEMAND
                </button>
                <button
                  name="online"
                  className={`grey_pill ${
                    params.mode === 'online' ? 'modeIsActive' : ''
                  } btn btn-xs`}
                  onClick={e => handleClick(e)}>
                  MODALIDAD ONLINE EN VIVO
                </button>
                <button
                  name="presencial"
                  className={`grey_pill ${
                    params.mode === 'presencial' ? 'modeIsActive' : ''
                  } btn btn-xs`}
                  onClick={e => handleClick(e)}>
                  MODALIDAD PRESENCIAL
                </button>
              </div>
            </div>
            <div
              className="col-sm-4 categorias_selector"
              style={{
                marginTop: !wide ? '1.6rem' : '',
              }}>
              <DropDown
                handleChange={setCategorySelection}
                showNone
                title={'Categoria'}
                options={categoryData}
                key={'category-drop-down'}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="courses_list container">
        <div className="row pb-5 px-5 gap-5">
          {total.length > 0 ? (
            total.map(course => {
              return <Thumbnail key={course.id} course={course} />;
            })
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}>
              <h3>No hay cursos disponibles en esta categoria</h3>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
