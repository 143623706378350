import { Field, Formik, Form as FormFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { register } from '../services';
import { storeUserEmail } from '@/utils';

interface Props {
  errorsRegister?: any;
  setErrorsRegister: (arg0: any) => void;
}

const registerSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  surname: Yup.string().required('El apellido es requerido'),
  mail: Yup.string().email('El mail es inválido').required('El mail es requerido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracteres')
    .minNumbers(1, 'La contraseña debe tener al menos 1 número'),
});

export const Form = ({ setErrorsRegister }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mailPlaceholder, setMailPlaceholder] = React.useState('Ingresá tu correo electrónico');
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <Formik
      initialValues={{
        name: '',
        surname: '',
        mail: '',
        password: '',
      }}
      validationSchema={registerSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { validateForm, setSubmitting }) => {
        validateForm().then(errors => {
          if (Object.keys(errors).length > 0) {
            return;
          }
          register(values).then(response => {
            if (response?.error) {
              setErrorsRegister(response?.error);
              setSubmitting(false);
            }
            if (response?.user) {
              storeUserEmail(response?.user.mail);
              navigate(`/confirmar-cuenta`, {
                replace: true,
                state: location.state,
              });
              setSubmitting(false);
            }
          });
        });
      }}>
      {({ isSubmitting, errors }) => (
        <FormFormik className="px-5">
          <div className={'mb-3 position-relative ' + (errors.name ? 'error' : '')}>
            <label htmlFor="nombre" className="form-label">
              Nombre
            </label>
            <Field
              type="text"
              className="form-control"
              id="nombre"
              aria-describedby="nombre"
              placeholder="Escribí tu nombre"
              name="name"
            />
          </div>
          <div className={'mb-3 position-relative ' + (errors.surname ? 'error' : '')}>
            <label htmlFor="apellido" className="form-label">
              Apellido
            </label>
            <Field
              type="text"
              className="form-control"
              id="apellido"
              aria-describedby="apellido"
              placeholder="Escribí tu apellido"
              name="surname"
            />
          </div>
          <div className={'mb-3 position-relative ' + (errors.mail ? 'error' : '')}>
            <label htmlFor="email_login" className="form-label">
              Email
            </label>
            <Field
              type="email"
              className="form-control"
              id="email_login"
              name="mail"
              onMouseOver={() => {
                setMailPlaceholder('Ej.: sanb@mail.com');
              }}
              onMouseOut={() => {
                setMailPlaceholder('Ingresá tu correo electrónico');
              }}
              placeholder={mailPlaceholder}
            />
          </div>
          <div className={'mb-3 position-relative ' + (errors.password ? 'error' : '')}>
            <label htmlFor="password_login" className="form-label">
              Contraseña
            </label>
            <div className="input-wrapper position-relative">
              <Field
                type={passwordVisibility ? 'text' : 'password'}
                className="form-control"
                id="password_login"
                name="password"
                placeholder="Ingresá una contraseña"
              />
              <button
                className="grey btn btn-light"
                type="button"
                onClick={() => setPasswordVisibility(!passwordVisibility)}>
                <i className="fas fa-eye"> </i>
              </button>
            </div>
            {errors.password && <p className="errorMessagge red small">{errors.password}</p>}
          </div>
          <p className="light-grey small text-center">
            Al finalizar el registro aceptás los
            <br />
            <Link to={'/legales'} className="link">
              Términos y condiciones
            </Link>{' '}
            y{' '}
            <Link to={'/legales'} className="link">
              Políticas de privacidad
            </Link>
            .
          </p>
          <p className="text-center">
            <button
              className="btn primary-btn btn-rounded red_bg white mt-3"
              type="submit"
              disabled={isSubmitting}>
              Finalizar Registro
            </button>
          </p>
          <Outlet />
          <p className="mt-3 mb-0 text-center text-dark">
            <Link to={'/login'} state={location.state} className="link">
              Ya tengo cuenta, ingresar
            </Link>
          </p>
        </FormFormik>
      )}
    </Formik>
  );
};
