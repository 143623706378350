import React from 'react';
import { Form } from './components';

export const Register = () => {
  const [errorsRegister, setErrorsRegister] = React.useState(null);

  return (
    <div>
      <h4 className="mb-2 text-center">Te damos la bienvenida a Mundos E</h4>
      <p className="mb-2 text-center small">
        Asegurate de que coincida con el nombre que figura en tu identificación oficial.
      </p>
      {errorsRegister && (
        <div className="callout warning d-flex mx-5">
          <figure>
            <i className="fas fa-exclamation"> </i>
          </figure>
          <div className="content d-flex align-items-center">
            <p>
              No se ha podido registrar en el sistema.
              <br />
              Por favor, volvé a intentarlo.
            </p>
          </div>
        </div>
      )}
      <div className="form-wrapper">
        <Form setErrorsRegister={setErrorsRegister} />
      </div>
    </div>
  );
};
