import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toTop } from '@/utils';

interface ChangeCategory {
  mode: string;
  category: string;
}

export const useSearch = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [modeSelection, setModeSelection] = React.useState('none');
  const [categorySelection, setCategorySelection] = React.useState('Seleccione categoria');
  const [categoryCollapse, setCategoryCollapse] = React.useState(false);

  const categoryData: { [key: string]: string } = {
    digital: 'Habilidades Digitales',
    personal: 'Habilidades Personales',
    gestion: 'Habilidades de Gestión',
    none: 'Seleccione categoria',
  };
  const modeData: { [key: string]: string } = {
    online: 'Online en vivo',
    ondemand: 'On Demand',
    none: '¿De qué manera te gustaría aprender?',
  };
  const onChangeCategory = (change: ChangeCategory) => {
    setCategoryCollapse(false);
    onChange(change);
    setCategorySelection(categoryData[change.category === undefined ? 'none' : change.category]);
  };

  const onChange = (change: ChangeCategory) => {
    setCategoryCollapse(false);
    if (change.mode === 'none' && change.category === 'none') {
      navigate('/programas', { replace: true });
    } else {
      navigate(`/programas/categorias/${change.category}/${change.mode}`, { replace: true });
    }
  };

  const onChangeMode = (change: ChangeCategory) => {
    onChange(change);
    setModeSelection(categoryData[change.mode === undefined ? 'none' : change.mode]);
  };

  React.useEffect(() => {
    if (params.category !== undefined && params.mode !== undefined) {
      setCategorySelection(categoryData[params.category ? params.category : 'none']);
      setModeSelection(modeData[params.mode ? params.mode : 'none']);
      onChange({ category: params.category, mode: params.mode });
    }

    toTop();
  }, [params.category, params.mode]);

  return {
    modeSelection,
    categorySelection,
    categoryCollapse,
    onChangeCategory,
    onChangeMode,
    setCategoryCollapse,
    params,
  };
};
