import React from 'react';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

import { EmailForm } from './EmailForm';

export const Newsletter = () => {
  const [showToast, setShowToast] = React.useState(false);
  const [showToastError, setShowToastError] = React.useState(false);

  return (
    <article>
      <h5 className="mb-3 footerTitle">Suscribite a nuestro Newsletter</h5>
      <EmailForm setShowToast={setShowToast} setShowToastError={setShowToastError} />

      <Row style={{ paddingLeft: '0.9rem' }}>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body className={'row'}>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <i className="fas fa-check"> </i>
            </div>
            <div className="col-9">
              <p className="small">
                Gracias por suscribirte a nuestro Newsletter, vamos a enviarte un correo con la
                confirmación.
              </p>
            </div>
          </Toast.Body>
        </Toast>
        <Toast onClose={() => setShowToastError(false)} show={showToastError} delay={3000} autohide>
          <Toast.Body className={'row'}>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <i style={{ color: 'red' }} className="fa fa-times">
                {' '}
              </i>
            </div>
            <div className="col-9">
              <span style={{ color: 'red' }}>Hubo un error, por favor intente más tarde.</span>
            </div>
          </Toast.Body>
        </Toast>
      </Row>
    </article>
  );
};
