import { useState } from 'react';
import { Contact } from './Contact';
import { LegalAndHelp } from './LegalAndHelp';
import { Newsletter } from './Newsletter';
import { SocialMedias } from './SocialMedias';

export const Footer = () => {
  const [hover, setHover] = useState(false);

  // Subject=Quiero%20anular%20mi%20compra%20del%20Curso&body=Your%20suggestions%20are%20really%0D%0Aimportant%20to%20us%20,thanks!
  const cancelPurchase = {
    subject: 'Quiero anular mi compra del Curso',
    body: `Lamentamos que hayas decidido cancelar tu compra.
    %0D%0APara gestionar la baja de la misma necesitamos:
    %0D%0A
    %0D%0ANombre completo
    %0D%0AMail con el que realizaste la compra
    %0D%0ADNI
    %0D%0ANúmero de compra`,
  };

  return (
    <footer className="py-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4 ">
            <Newsletter />
          </div>
          <div className="col-lg-7 ">
            <LegalAndHelp />
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-4 ">
            <article>
              <h5
                className="mb-3 footerTitle"
                style={{
                  marginTop: '2rem',
                }}>
                Cancelación de compra
              </h5>
              <a
                className="nav-link btn-tertiary"
                href={`mailto:administracion@mundose.com?Subject=${cancelPurchase.subject}&body=${cancelPurchase.body}`}
                style={{
                  fontWeight: 600,
                  padding: '5px 8px',
                  borderRadius: '32px',
                  whiteSpace: 'nowrap',
                  fontSize: '0.875rem',
                  color: !hover ? '#5a5e66' : '#ffffff',
                  backgroundColor: hover ? '#7f838c99' : '#7f838c0d',
                  border: '1px solid #7f838c',
                  letterSpacing: '0.029rem',
                  textDecoration: 'none',
                  textTransform: 'initial',
                  minWidth: 'initial',
                  width: 300,
                  textAlign: 'center',
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
                onMouseEnter={() => {
                  setHover(true);
                }}>
                Botón de Arrepentimiento
              </a>
            </article>
          </div>
        </div>
        <SocialMedias />

        <hr />
        <Contact />
      </div>
    </footer>
  );
};

export default Footer;
