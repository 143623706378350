import React from 'react';

export const Contact = () => {
  return (
    <div className="row d-flex justify-content-between align-items-center ">
      <div className="col-lg-7 ">
        <div className="row d-flex align-items-center contact_text">
          <div className="col-lg">
            <h4 className="m-0">Contacto</h4>
          </div>
          <div className="col-lg">
            <p className="m-0">
              <a
                href="mailto:info@mundose.com "
                target="_blank "
                title="Escribinos un correo a info@mundose.com "
                className="grey ">
                <i className="far fa-envelope white grey_bg "></i> info@mundose.com
              </a>
            </p>
          </div>
          <div className="col-lg">
            <p className="m-0">
              <a
                href="https://wa.link/yqukyz"
                target="_blank "
                title="Encontranos en WhatsApp "
                className="grey ">
                <i className="fab fa-whatsapp red_bg white "></i> +54 9 3516 57-3170
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="col-lg-5 d-flex justify-content-end ">
        <p className="text-right ">
          <small>© 2021 Mundos E, todos los derechos reservados.</small>
        </p>
      </div>
    </div>
  );
};
