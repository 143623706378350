import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const ChangeSuccessModal: React.FC = () => {
  const location = useLocation();
  return (
    <div className="px-5">
      <figure className="d-flex justify-content-center align-items-center">
        <img src={'/img/bienvenida.svg'} alt="password confirmation update" />
      </figure>
      <h3 className="my-3 text-center">Hola de nuevo!</h3>
      <p className="mb-3 small text-center">Tu contraseña ya está actualizada.</p>
      <p className="mt-3 text-center">
        <Link
          to={'/login'}
          state={{
            backgroundLocation: {
              pathname: '/',
              search: '',
              hash: '',
              state: null,
              key: '2jfvtckq',
            },
          }}
          className="mt-2 btn primary-btn btn-rounded red_bg white">
          Iniciar sesión
        </Link>
      </p>
    </div>
  );
};
