import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setPurchaseData, setUserData } from '@/store/slices';

import { commonSchema, DataForm, RegisterForm, registerSchema } from '../forms';
import { useRegister, useUserLogged } from '../hooks';
import { FormValues } from '../interfaces';

import { Header } from './Header';
import { getCodeCountryByCodeName, getCodeCountryByCodeNumber } from '@/utils/codeCountryNumber';

export const UserContainer = () => {
  const { registerUser, usersCrm, errorsResponse } = useRegister();
  const { logged } = useUserLogged();

  const { course } = useAppSelector(state => state.course);
  const { user } = useAppSelector(state => state.user);
  const { user: userPurchase } = useAppSelector(state => state.purchase);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = logged ? commonSchema : commonSchema.concat(registerSchema);
  const codeNumberCountryUserPurchase =
    userPurchase?.phone?.countryCode &&
    getCodeCountryByCodeNumber(userPurchase?.phone?.countryCode).name;

  const codeNumberCountryUser =
    user?.phone?.number && getCodeCountryByCodeNumber(user?.phone?.countryCode).name;

  const initialValues: FormValues = {
    name: userPurchase?.name || user?.name || '',
    surname: userPurchase?.surname || user?.surname || '',
    mail: userPurchase?.mail || user?.mail || '',
    tipodoc: userPurchase?.tipodoc || user?.tipodoc || '',
    nrodoc: userPurchase?.nrodoc || user?.nrodoc || '',
    phone: {
      countryCode:
        codeNumberCountryUserPurchase ||
        codeNumberCountryUser ||
        getCodeCountryByCodeNumber('').name,
      area: userPurchase?.phone?.area || user?.phone?.area || '',
      number: userPurchase?.phone?.number || user?.phone?.number || '',
    },
    address: {
      country: userPurchase?.address?.country || user?.address?.country || '',
      state: userPurchase?.address?.state || user?.address?.state || '',
      city: userPurchase?.address?.city || user?.address?.city || '',
      street: userPurchase?.address?.street || user?.address?.street || '',
      departmentNumber:
        userPurchase?.address?.departmentNumber || user?.address?.departmentNumber || '',
      streetNumber: userPurchase?.address?.streetNumber || user.address?.streetNumber || '',
    },
    password: '',
  };

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const setUserValues = {
      ...values,
      phone: {
        ...values.phone,
        countryCode: getCodeCountryByCodeName(values.phone.countryCode).codeNumber,
      },
    };

    dispatch(setPurchaseData(setUserValues, setUserData, 'userPurchase'));

    if (!logged) {
      await registerUser(setUserValues);
    } else {
      await usersCrm(setUserValues);
      navigate(`/programas/${course.id}/compra/revisar`);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      {logged && (
        <>
          <Header
            title="Datos del usuario"
            action={
              'Es importante que controles y completes tus datos porque serán los que figurarán en tu certificado final.'
            }
          />
          <DataForm
            initialValues={initialValues}
            schema={validationSchema}
            handleSubmit={handleSubmit}
            logged={logged}
            errorsResponse={errorsResponse}
          />
        </>
      )}
      {!logged && (
        <>
          <Header
            title="Creación de usuario"
            action={
              <Link to={'/login'} className="link" state={{ backgroundLocation: location }}>
                Ya tengo cuenta, Iniciar sesión
              </Link>
            }
          />
          <DataForm
            initialValues={initialValues}
            schema={validationSchema}
            handleSubmit={handleSubmit}
            logged={logged}
            errorsResponse={errorsResponse}>
            <RegisterForm />
          </DataForm>
        </>
      )}
    </>
  );
};
