import { User } from '@/interfaces';
import { axios } from '@/lib/axios';

const containLettersAndNumbers = (text: string) => {
  const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  return regex.test(text);
};

export const sellOndemand = async (payment: any, user: User) => {
  const { password, ...rest } = user;
  const invoiceData = {
    user: rest,
    billingData: { ...rest, condicioniva: 'Consumidor Final' },
    payment: containLettersAndNumbers(payment) ? 0 : Number(payment),
    paymentPaypal: containLettersAndNumbers(payment) ? payment : 'string',
  };

  try {
    if (containLettersAndNumbers(payment)) {
      const resp = await axios.post('/ventaondemand/post_venta_ondemand_paypal', invoiceData);
      return {
        status: resp.status,
        data: resp.data,
      };
    } else {
      const resp = await axios.post('/ventaondemand/post_venta_ondemand_MP', invoiceData);
      return { status: resp.status, data: resp.data };
    }
  } catch (error) {
    return { error: error };
  }
};

export const sellOndemandWithExternalPayer = async (
  payment: any,
  user: User,
  billingData: User
) => {
  const { password, ...rest } = user;
  const { password: passwordBD, ...restBD } = billingData;

  const invoiceData = {
    user: rest,
    billingData: { ...restBD, condicioniva: 'Consumidor Final' },
    payment: containLettersAndNumbers(payment) ? 0 : Number(payment),
    paymentPaypal: containLettersAndNumbers(payment) ? payment : 'string',
  };
  try {
    if (containLettersAndNumbers(payment)) {
      const resp = await axios.post('/ventaondemand/post_venta_ondemand_paypal', invoiceData);
      return { status: resp.status, data: resp.data };
    } else {
      const resp = await axios.post('/ventaondemand/post_venta_ondemand_MP', invoiceData);
      return { status: resp.status, data: resp.data };
    }
  } catch (error) {
    return { error: error };
  }
};
