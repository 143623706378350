import { Question } from '@/features/courses';
import { useAppSelector } from '@/store/hooks';

export const Questions = () => {
  const { course } = useAppSelector(state => state.course);

  return (
    <section className="faqs container py-5">
      <header className="row mb-5">
        <h2>Preguntas frecuentes</h2>
      </header>
      <div className="row">
        <div className="col-12">
          <div className="accordion faqs_accordion" id="faqs_accordion">
            {course.questions &&
              course.questions.map((question: Question, i) => {
                return (
                  <div key={'question_' + i} className="accordion-item py-2 px-0">
                    <h2 className="accordion-header" id={'question_heading' + i}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={'#question_collapse' + i}
                        aria-controls={'question_collapse' + i}>
                        {question.header}
                      </button>
                    </h2>
                    <div
                      id={'question_collapse' + i}
                      className="accordion-collapse collapse "
                      aria-labelledby={'question_heading' + i}
                      data-bs-parent="#faqs_accordion">
                      <div className="accordion-body">
                        <p>{question.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
