import React, { useReducer, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

import { InputContainer } from '@/features/purchase';
import { Form, FormikHelpers, Formik, Field } from 'formik';
import { useAppSelector } from '@/store/hooks';
import { changePassword } from '../../services';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('La contraseña es requerida'),
  newPassword: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'Debe tener al menos 6 caracteres')
    .minNumbers(1, 'Debe tener al menos 1 número'),
  confirmsNewPassword: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'Debe tener al menos 6 caracteres')
    .minNumbers(1, 'Debe tener al menos 1 número')
    .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas deben ser iguales'),
});

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmsNewPassword: string;
};

const initialState = {
  passwordVisibility1: false,
  passwordVisibility2: false,
  passwordVisibility3: false,
};

const handlingStatesReducer = (state, action) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        [action.fieldName!]: action.payload,
      };
    }
    default:
      return state;
  }
};

type SettingsFormProps = {
  setError: React.Dispatch<
    React.SetStateAction<{
      loginError: string;
      changePasswordError: string;
    }>
  >;
};

export const SettingsForm = ({ setError }: SettingsFormProps) => {
  const { user } = useAppSelector(state => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state, dispatch] = useReducer(handlingStatesReducer, initialState);

  const { passwordVisibility1, passwordVisibility2, passwordVisibility3 } = state;

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    setIsLoading(true);
    const { currentPassword, newPassword } = values;

    try {
      const response = await changePassword(user.mail, currentPassword, newPassword);
      setIsLoading(false);

      actions.setSubmitting(false);
      actions.resetForm();

      return response.data;
    } catch (error) {
      if (error.loginerror) {
        setError({
          ...error,
          loginError: 'Ocurrió un error. La contraseña actual no es válida.',
        });
      } else {
        setError({
          ...error,
          changePasswordError: 'Ocurrió un error con el cambio de contraseña.',
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmsNewPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formik => (
        <Form>
          <div className="row mb-4">
            <InputContainer
              profileSize
              errors={formik.errors.currentPassword}
              touched={formik.touched.currentPassword}>
              <label className="form-label" htmlFor={'currentPassword'}>
                Contraseña actual
              </label>
              <div className="input-wrapper position-relative">
                <Field
                  type={passwordVisibility1 ? 'text' : 'password'}
                  className="form-control"
                  name="currentPassword"
                  placeholder="Ingresá tu contraseña"
                />
                <button
                  className="grey btn btn-light"
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: 'setState',
                      fieldName: 'passwordVisibility1',
                      payload: !passwordVisibility1,
                    })
                  }>
                  <i className="fas fa-eye"> </i>
                </button>
              </div>
            </InputContainer>
            <p className="errorMessagge red small form-label">{formik.errors?.currentPassword}</p>
          </div>
          <div className="row mb-4">
            <InputContainer
              profileSize
              errors={formik.errors.newPassword}
              touched={formik.touched.newPassword}>
              <label className="form-label" htmlFor={'newPassword'}>
                Nueva contraseña
              </label>
              <div className="input-wrapper position-relative">
                <Field
                  type={passwordVisibility2 ? 'text' : 'password'}
                  className="form-control"
                  name="newPassword"
                  placeholder="Ingresá la contraseña nueva"
                />
                <button
                  className="grey btn btn-light"
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: 'setState',
                      fieldName: 'passwordVisibility2',
                      payload: !passwordVisibility2,
                    })
                  }>
                  <i className="fas fa-eye"> </i>
                </button>
              </div>
            </InputContainer>
            <p className="errorMessagge red small form-label">{formik.errors?.newPassword}</p>
          </div>
          <div className="row mb-4">
            <InputContainer
              profileSize
              errors={formik.errors.confirmsNewPassword}
              touched={formik.touched.confirmsNewPassword}>
              <label className="form-label" htmlFor={'confirmsNewPassword'}>
                Confirmación de nueva contraseña
              </label>
              <div className="input-wrapper position-relative">
                <Field
                  type={passwordVisibility3 ? 'text' : 'password'}
                  className="form-control"
                  name="confirmsNewPassword"
                  placeholder="Repetí la contraseña nueva"
                />
                <button
                  className="grey btn btn-light"
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: 'setState',
                      fieldName: 'passwordVisibility3',
                      payload: !passwordVisibility3,
                    })
                  }>
                  <i className="fas fa-eye"> </i>
                </button>
              </div>
            </InputContainer>
            <p className="errorMessagge red small form-label">
              {formik.errors?.confirmsNewPassword}
            </p>
          </div>
          <p className="text-end mt-2">
            <button
              type="submit"
              disabled={
                Boolean(formik.errors?.currentPassword) ||
                Boolean(formik.errors?.newPassword) ||
                Boolean(formik.errors?.confirmsNewPassword) ||
                isLoading
              }
              className="btn primary-btn btn-rounded red_bg white btn-small w-25">
              {isLoading ? <Spinner animation="border" /> : <span>Guardar cambios</span>}
            </button>
          </p>
        </Form>
      )}
    </Formik>
  );
};
