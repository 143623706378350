import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { useParams } from 'react-router-dom';
import { toTop } from '@/utils/toTop';
import { getCourses } from '@/store/slices/courses';

export const useCourses = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const { courses, isLoading } = useAppSelector(state => state.courses);

  useEffect(() => {
    if (courses.length === 0 && !isLoading) {
      dispatch(getCourses());
      toTop();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    courses,
    isLoading,
  };
};
