import { IPrice } from '@/features/course';
import { ClassContentResponse, CourseResponse } from '@/features/courses';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface courseState {
  course: CourseResponse;
  content: ClassContentResponse[];
  price: IPrice;
  isLoading: boolean;
}

const INITIAL_STATE: courseState = {
  course: {} as CourseResponse,
  content: [] as ClassContentResponse[],
  price: {} as IPrice,
  isLoading: false,
};

export const courseSlice = createSlice({
  name: 'course',
  initialState: INITIAL_STATE,
  reducers: {
    startLoadingCourse: state => {
      state.isLoading = true;
    },
    setCourse: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.course = action.payload;
    },
    setContent: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    setPrice: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.price = action.payload;
    },
  },
});

export const { startLoadingCourse, setCourse, setContent, setPrice } = courseSlice.actions;
