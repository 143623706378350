import * as React from 'react';

export const ThanksPage: React.FC = () => {
  return (
    <div>
      <section className="thanks_hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 py-5 pt-0 pt-md-5 d-flex justify-content-between flex-column">
              <header>
                <h2 className="mb-4">¡Gracias por contactarte!</h2>
                <p className="grey ">
                  Recibimos tu pedido de ayuda, el mismo será recibido por el área correspondiente.
                </p>
                <p className="grey mb-5">
                  Nos estaremos poniendo en contacto para brindarte una respuesta.
                </p>
              </header>
            </div>
            <div className="col-sm-4 pb-0 py-5 px-5">
              <figure>
                <img src={'/img/Ilustracion_contacto.svg'} alt="Gracias" className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
