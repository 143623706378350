import * as Yup from 'yup';

export const inquiryCourseSchema = Yup.object().shape({
  user: Yup.object({
    name: Yup.string().required('Nombre es requerido'),
    surname: Yup.string().required('Apellido es requerido'),
    country: Yup.string().required('Pais de residencia es requerido'),
    mail: Yup.string()
      .required('Correo electrónico es querido')
      .email('Correo electrónico inválido. Formato requerido: email@emailejemplo.com'),
    phone: Yup.object({
      number: Yup.string().min(6).max(10).required('Teléfono es requerido'),
      countryCode: Yup.string().required('Código de país es requerido'),
      area: Yup.string().required('Código de área es requerido').min(1).max(4),
    }),
  }),
  message: Yup.string().required('Consulta es requerida'),
});
