import React from 'react';
import { Footer } from '../footer';
import { Header } from '../header';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="home">
      <Header />
      {children}
      <Footer />
    </div>
  );
};
