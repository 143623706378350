import { useWindowDimensions } from '@/hooks';
import { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Form } from './forms';

export const InquiryForm = () => {
  const [fail, setFailed] = useState<any>();
  const location = useLocation();
  const locationState = location.state;

  const getInquiryType = () => {
    switch (locationState?.inquiry.inquiryType?.name) {
      case 'academic':
        return 'Académica';
      case 'administrative':
        return 'de Administración';
      case 'problem':
        return 'de Reportes de Problemas';
      case 'buy':
        return 'de Compras';

      default:
        return 'General';
    }
  };

  const wide = useWindowDimensions(575);

  return (
    <div>
      <ToastContainer position="top-end" style={{ position: 'fixed', marginTop: '5rem' }}>
        <Toast
          onClose={() => setFailed(undefined)}
          show={fail !== undefined}
          delay={3000}
          autohide
          bg={'danger'}>
          <Toast.Header>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>Algo salió mal! Intente de nuevo más tarde</Toast.Body>
        </Toast>
      </ToastContainer>
      <section className="formContacto_hero ayuda d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 d-flex flex-column align-items-start position-relative">
              <header className="pt-5">
                <Link to={'/consultas'} className="link breadcrumb">
                  <i className="fas fa-chevron-left"></i> Volver a consultas
                </Link>
                <h1>Consultanos</h1>
                <p>Para poder ayudarte</p>
              </header>
              <figure
                className="my-2"
                style={{
                  display: !wide ? 'none' : 'block',
                }}>
                <img src={'/img/contacto.png'} alt="Formulario de contacto" className="img-fluid" />
              </figure>
            </div>
            <div className="col-sm-6 pt-5">
              <h2>{locationState?.inquiry?.inquiryType?.description}</h2>
              {/* <h2>{locationState?.inquiry?.inquiryType?.description}</h2> */}
              <p className="light-grey">
                Seleccioná una de las opciones para poder contactarte desde el área{' '}
                {getInquiryType()}
              </p>
              <Form inquiry={locationState} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
