export const Loader = () => {
  return (
    <div>
      <section className="formConsulta_hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 d-flex flex-column justify-content-between position-relative pe-5">
              <header>
                <h4>
                  <span className="badge rounded-pill grey_bg white  online">Modalidad...</span>
                </h4>
                <h1 className="mt-2">Cargando...</h1>
              </header>
              <figure className="mb-2 d-none d-sm-block">
                <img
                  src={'/img/formConsulta.svg'}
                  alt="Formulario de consulta"
                  className="img-fluid"
                  style={{ filter: 'opacity(25%)' }}
                />
              </figure>
            </div>
            <div className="col-sm-6 d-flex justify-content-center align-items-start flex-column position-relative ps-5">
              <h2>¡Pongámonos en contacto!</h2>
              <p className="light-grey">Cargando...</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
