import { Dispatch } from '@reduxjs/toolkit';
import { PayPalResponse } from './interfaces/PayPalResponse';

import { setPayment } from './payment';

interface PaymentProps {
  payment?: string | null;
  paymentID?: string;
}

export const getPayment = ({ payment, paymentID }: PaymentProps) => {
  return async (dispatch: Dispatch) => {
    if (paymentID && !payment) {
      dispatch(setPayment(paymentID));
    }

    if (payment && !paymentID) {
      dispatch(setPayment(payment));
    }
  };
};
