import { axios } from '@/lib/axios';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';

interface EmailFormProps {
  setShowToast: (showToast: boolean) => void;
  setShowToastError: (showToastError: boolean) => void;
}

const SignupSchema = Yup.object().shape({
  mail: Yup.string().email('Email invalido').required('Required'),
  terms: Yup.boolean().oneOf([true], 'Debes aceptar terminos y condiciones'),
});

export const EmailForm = ({ setShowToast }: EmailFormProps) => {
  return (
    <Formik
      initialValues={{
        mail: '',
        terms: false,
      }}
      onSubmit={async values => {
        try {
          const resp = await axios.post('/inquiry/add_to_newsletter', { mail: values.mail });

          if (resp.status === 200) {
            setShowToast(true);
          }
          //clear form
          values.mail = '';
          values.terms = false;
        } catch (err) {
          return { err };
        }
      }}
      validationSchema={SignupSchema}>
      {({ values, errors }) => (
        <Form>
          <div className="form-check">
            <Field className="form-check-input" type="checkbox" name="terms" id="tycs" />
            <label className="form-check-label" htmlFor="tycs">
              Acepto términos y condiciones
            </label>
          </div>
          <div className="row d-flex ">
            <div className="col-10 pr-0 ">
              <label htmlFor="email" className="form-label d-none">
                Email
              </label>
              <Field
                type="email"
                name="mail"
                className="form-control "
                aria-describedby="emailHelp "
                placeholder="Ingresa tu correo "
              />
            </div>

            <div className="col-2 ">
              <button
                disabled={!values.terms || Boolean(errors.mail)}
                type="submit"
                className="btn red_bg white ">
                <i className="fas fa-arrow-right "> </i>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
