import { useField } from 'formik';
import { TextInputProps } from './types';

export const TextAreaInput = ({ label, ...props }: TextInputProps) => {
  const [field] = useField(props);

  return (
    <>
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea {...field} {...props} className="form-control"></textarea>
    </>
  );
};
