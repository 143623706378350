import { Legal } from '@/pages/Legal';
import { Route, Routes } from 'react-router-dom';
import { Terms } from '../privacyAndTerms';
import { Privacy } from '../privacyAndTerms/Privacy';

export const LegalRoutes = () => {
  return (
    <Routes>
      <Route index element={<Legal />} />
      <Route path="/Politica-de-Privacidad" element={<Privacy />} />
      <Route path="/Politica-de-Compras-y-Cancelaciones" element={<Terms />} />
    </Routes>
  );
};
