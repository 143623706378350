import { CouseInquiryForm } from '../CourseInquiryForm';

export const FormContainer = () => {
  return (
    <div className="col-sm-6 d-flex justify-content-center align-items-start flex-column position-relative ps-md-5">
      <h2>¡Pongámonos en contacto!</h2>
      <p className="light-grey">Déjanos tus datos y te contactaremos en seguida</p>
      <CouseInquiryForm />
    </div>
  );
};
