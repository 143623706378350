import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

export const registerSchema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracteres')
    .minNumbers(1, 'La contraseña debe tener al menos 1 número'),
});
