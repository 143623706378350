import React from 'react';
import Collapse from 'react-bootstrap/Collapse';

type Props = {
  handleChange: (string) => void;
  onToggleCollapse?: () => void;
  title: string;
  options: { [key: string]: { label: string; description: string } };
  showNone?: boolean;
  showTitle?: boolean;
  setCollapsed?: (a: boolean) => void;
  isCollapsed?: boolean;
};

export function DropDown(props: Props) {
  const [isCollapsed, setCollapsed] = React.useState(false);
  const [selected, setSelected] = React.useState('none');

  const onClick = (key: string) => {
    setSelected(key);
    props.handleChange(key);
    props.setCollapsed ? props.setCollapsed(false) : setCollapsed(false);
  };

  const handleCollapse = e => {
    e.preventDefault();
    props.onToggleCollapse ? props.onToggleCollapse() : {};
    props.setCollapsed ? props.setCollapsed(!props.isCollapsed) : setCollapsed(!isCollapsed);
  };

  return (
    <nav>
      {props.showTitle ? (
        <button
          onClick={handleCollapse}
          className={'d-block text-left filter-button'}
          type="button"
          data-bs-toggle={'collapse'}
          data-bs-target={`#${props.title}`}
          aria-controls={props.title}
          aria-label="Toggle navigation">
          {props.title}
          <span className="d-block light-grey "> {props.options[selected].label} </span>
        </button>
      ) : (
        <button
          onClick={handleCollapse}
          className={'d-block btn d-flex justify-content-between align-items-center'}
          type="button"
          data-bs-toggle={'collapse'}
          data-bs-target={`#${props.title}`}
          aria-controls={props.title}
          aria-label="Toggle navigation">
          {props.options[selected].label} <i className="fas fa-arrow-down"> </i>
        </button>
      )}
      <Collapse in={isCollapsed}>
        <nav
          className={
            'collapse filter-nav filter-categorías position-absolute' +
            (props.isCollapsed || isCollapsed ? ' show' : '')
          }
          id={props.title}>
          <ul className="list-unstyled">
            {Object.keys(props.options).map(k => {
              if (k != 'none' || props.showNone)
                return (
                  <li onClick={() => onClick(k)} key={`${props.options[k].label}-key`}>
                    <h4 className="overline">
                      {k === 'none' ? 'VER TODOS' : props.options[k].label}
                    </h4>
                    <p> {props.options[k].description} </p>
                  </li>
                );
            })}
          </ul>
        </nav>
      </Collapse>
    </nav>
  );
}
