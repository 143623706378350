import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import App from './App';
import { store } from './store';
import { env } from './utils';

/* eslint-disable */
const container = document.getElementById('root')!;
const root = createRoot(container);
/* eslint-enable */

const clientID =
  env === 'production'
    ? 'AbhTjgrxZPi18zWz0FgdRZtKd1BxS4NL8-O5oF7MH-US5ueObBrvP8d9fplMPntP80BtnHjx2doLb2iH'
    : 'AbsXdswUi8zX5Iaje0st6LVWi47UGxcZglUVdIIIVZKm4GTm7MN1CTVRvt79uBZOmpARj4TdJk2DSYRx';

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PayPalScriptProvider
      options={{
        'client-id': clientID,
      }}>
      <App />
    </PayPalScriptProvider>
  </Provider>
  // </React.StrictMode>
);
