import { useWindowDimensions } from '@/hooks';
import { useAppSelector } from '@/store/hooks';
import { useMemo, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUserLogged } from '../purchase';
import { useInquiry } from './hooks';
import { InquiryResponse } from './interfaces/InquiryResponse';

export const InquiryOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAppSelector(state => state.user);
  useInquiry();
  const { inquiry } = useAppSelector(state => state.inquiry);

  const verify = (inquiry: InquiryResponse) => {
    if (!user.mail || user.mail === '') return;
    const userInquiry = {
      inquiry: { user: { name: user?.name, surname: user?.surname, mail: user?.mail } },
    };

    navigate('/consultas/opciones', {
      state: { inquiry: { ...userInquiry.inquiry, inquiryType: inquiry } },
    });
  };

  const isLogged = useMemo(() => user.mail != null && user.mail != '', [user]);

  const capitalize = (description: string) => {
    if (description === 'Consultas administrativas') {
      return description
        .split(' ')
        .map(word => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    } else {
      return description;
    }
  };
  const wide = useWindowDimensions(575);
  return (
    <div>
      <section className="formContacto_hero ayuda d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 d-flex flex-column align-items-start position-relative">
              <header className="pt-5">
                <Link to={'/'} className="link breadcrumb">
                  <i className="fas fa-chevron-left"></i> Volver al home
                </Link>
                <h1>Consultanos</h1>
                <p>Para poder ayudarte</p>
              </header>
              <figure
                className="my-2"
                style={{
                  display: !wide ? 'none' : 'block',
                }}>
                <img src={'/img/contacto.png'} alt="Formulario de contacto" className="img-fluid" />
              </figure>
            </div>
            <div className="col-sm-6 pt-5">
              <h2>¿En qué podemos ayudarte?</h2>
              {isLogged ? (
                <p>
                  Selecciona una área sobre la que deseas consultar y nos pondremos en contacto a la
                  brevedad
                </p>
              ) : (
                <p className="light-grey">
                  Selecciona una área sobre la que deseas consultar, recuerda que para poder ver
                  todas las opciones habilitadas necesitas
                  <Link to={'/registro'} state={{ backgroundLocation: location }} className="link">
                    {' '}
                    Registrarte
                  </Link>{' '}
                  o{' '}
                  <Link to={'/login'} state={{ backgroundLocation: location }} className="link">
                    Iniciar Sesión
                  </Link>
                  .
                </p>
              )}

              <form>
                <div className="row">
                  <div className="col-sm-12 option-list">
                    <ul className="list-unstyled row justify-content-center flex-column align-items-center mt-4">
                      {inquiry.length > 0 &&
                        inquiry.map((value: InquiryResponse, k) => {
                          return (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <li
                              onClick={e => {
                                e.preventDefault();
                                if (isLogged) {
                                  verify(value);
                                }
                              }}
                              key={'inquiry_type_' + k}
                              className={`mt-2 col-sm-8 shadow-1`}>
                              {capitalize(value.description)}
                              <button className="btn btn-large" disabled={!isLogged}>
                                <i className="fas fa-arrow-right white"></i>
                              </button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
