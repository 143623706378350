import { Layout } from '@/components/layout';
import { CoursesRoutes } from '@/features/courses';
import { InquiryRoutes } from '@/features/inquiry';
import { LegalRoutes } from '@/features/legal';
import { ChangePassword } from '@/features/user';
import { Home, Purchase } from '@/pages';
import { RouteObject } from 'react-router-dom';
import { Activated } from '@/features/authentication';

export const PublicRoutes = ({ children }: any) => {
  return <Layout>{children}</Layout>;
};

export const publicRoutes: RouteObject[] = [
  { path: 'programas/*', element: <CoursesRoutes /> },
  { path: 'legales/*', element: <LegalRoutes /> },
  { path: 'consultas/*', element: <InquiryRoutes /> },
  { path: 'user/token/:token', element: <Activated /> },
  { path: 'user/newPassword/:token', element: <ChangePassword /> },
  // { path: 'compra', element: <Purchase /> },
  { path: '*', element: <Home /> },
];
