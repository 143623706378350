import * as React from 'react';
import { countryOptions, DniType, GenderEnum } from '@/features/user';
import { SelectComponent, TextInput } from '@/components/form';
import { InputContainer } from '@/features/purchase';
import Resizer from 'react-image-file-resizer';

type FormProps = {
  handleKeys: (keys: any) => void;
  formik: any;
  handleCurrentOption?: (value: any) => void;
};

export const EditPersonalData = ({ handleKeys, formik, handleCurrentOption }: FormProps) => {
  const dniTypes = DniType;
  const fileInputRef = React.useRef<HTMLInputElement>();
  const [optimizedImage, setOptimizedImage] = React.useState<string>(formik.values.image_profile);

  function handleChange(att: string, e) {
    handleKeys([att]);
    formik.handleChange(e);
  }

  const loadFile = e => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleKeyEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    const optimizedImageDataURI = await optimizeImage(file);
    setOptimizedImage(optimizedImageDataURI as string);
    handleKeys(['user.image_profile']);
    formik.values['image_profile'] = optimizedImageDataURI as string;
  };

  function optimizeImage(file) {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        800, //  max width of the image
        800, // max height of the image
        'JPEG', // compress format WEBP, JPEG, PNG
        80, // quality
        0, // rotation in degrees
        uri => {
          resolve(uri);
        },
        'base64', // output type base64 or blob
        800, // max width of the output image
        800 // max height of the output image
      );
    });
  }

  return (
    <div className="card datos-personales mb-5">
      <div className="card-body p-5">
        <div className="row mb-5">
          <div className="col-sm-8">
            <h3 className="card-title mb-4">Datos personales</h3>
            <p className="card-subtitle mb-4">
              El nombre se crea con tu registro, con el mismo vamos a generar tu certificado.
              Verifica que sea el que aparece en tu documento único de identidad. En caso de que no
              sea el correcto, podés modificarlo por única vez.
            </p>
            <p className="card-subtitle">* Datos obligatorios</p>
          </div>
          <div className="col-md-6 col-lg-4 camarita">
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              accept="image/*"
              onChange={e => handleImageChange(e)}
            />
            <img
              src={optimizedImage ? optimizedImage : '/img/mi_perfil.svg'}
              style={{ objectFit: 'cover' }}
              className="avatar img-fluid"
              width="144"
              alt={'profile'}
            />
            <button onClick={e => loadFile(e)} className="btn-avatar">
              <i className="fas fa-camera"> </i>
            </button>
          </div>
        </div>
        <div className="row mb-4">
          <InputContainer profileSize errors={formik.errors.name} touched={formik.touched.name}>
            <TextInput
              label="Nombre *"
              name="name"
              value={formik.values.name}
              onChange={e => handleChange('user.name', e)}
              id={'name'}
              placeholder="Escribí tu nombre"
              onKeyDown={handleKeyEnter}
            />
          </InputContainer>
          <InputContainer
            profileSize
            errors={formik.errors.surname}
            touched={formik.touched.surname}>
            <TextInput
              label="Apellido *"
              value={formik.values.surname}
              onChange={e => handleChange('user.surname', e)}
              id="surname"
              name="surname"
              placeholder="Escribí tu apellido"
              onKeyDown={handleKeyEnter}
            />
          </InputContainer>
        </div>
        <div className="row mb-4">
          <InputContainer profileSize errors={formik.errors.birth} touched={formik.touched.birth}>
            <label htmlFor="birthday" className="form-label">
              Fecha de Nacimiento *
            </label>
            <input
              type="date"
              className="form-control"
              value={formik.values.birth}
              onChange={e => handleChange('user.birth', e)}
              id="birth"
              aria-describedby="birthday"
              placeholder="Fecha de nacimiento"
              onKeyDown={handleKeyEnter}
            />
          </InputContainer>
        </div>
        <div className={`row mb-4 ${formik.errors.genre && formik.touched.genre ? ' error' : ''}`}>
          <label htmlFor="genre" className="form-label">
            Selecciona tu género *
          </label>
          <SelectComponent
            att={'genre'}
            options={[
              { label: 'Femenino', value: GenderEnum.female },
              { label: 'Masculino', value: GenderEnum.male },
              { label: 'Sin especificar', value: GenderEnum.other },
            ]}
            placeholder={'Selecciona tu género'}
            formik={formik}
            handleKeys={handleKeys}
          />
        </div>
        <div
          className={`row mb-4 ${
            formik.errors.nationality && formik.touched.nationality ? ' error' : ''
          }`}>
          <label htmlFor="nationality" className="form-label">
            Pais de Nacionalidad *
          </label>
          <SelectComponent
            att={'nationality'}
            options={countryOptions.map(({ label, value }) => ({ value: value, label: label }))}
            placeholder={'Seleccionar Pais'}
            formik={formik}
            handleKeys={handleKeys}
          />
        </div>
        <div
          className={`row mb-4 ${formik.errors.tipodoc && formik.touched.tipodoc ? ' error' : ''}`}>
          <div className={'col-md-6 col-lg-4'}>
            <label htmlFor="tipodoc" className="form-label">
              Tipo de Id. *
            </label>
            <SelectComponent
              att={'tipodoc'}
              options={dniTypes.map(({ label, value }) => ({ value: value, label: label }))}
              placeholder={'Seleccionar el tipo de Id.'}
              formik={formik}
              handleKeys={handleKeys}
              fullWidth={true}
              handleCurrentOption={handleCurrentOption}
            />
          </div>
          <InputContainer profileSize errors={formik.errors.nrodoc} touched={formik.touched.nrodoc}>
            <TextInput
              label="Número de Id. *"
              name="nrodoc"
              placeholder="Nro. de identificación"
              maxLength={16}
              value={formik.values.nrodoc}
              onChange={e => handleChange('user.nroDoc', e)}
              id="nrodoc"
              onKeyDown={handleKeyEnter}
            />
          </InputContainer>
        </div>
        <p className="text-end mt-2">
          <button type="submit" className="btn primary-btn btn-rounded red_bg white btn-small">
            Guardar cambios
          </button>
        </p>
      </div>
    </div>
  );
};
