// eslint-disable-next-line no-restricted-imports
import '@/features/course/Course.css';
import { useAppSelector } from '@/store/hooks';

export const Learnings = () => {
  const { course } = useAppSelector(state => state.course);

  const subtitle = course?.learnings?.slice(0, 1);

  return (
    <section className="que_vas_a_aprender py-5 shadow-border lighter-grey_bg">
      <div className="container">
        <div className="row py-0 py-md-5">
          <div className="col-md-5">
            <h2 className="mb-3">¿Qué vas a aprender?</h2>
            <p className="mb-4">{subtitle && subtitle[0]?.description}</p>
            <ul className="list-unstyled mt-4">
              {course?.learnings &&
                course?.learnings?.slice(1, 4).map((learning, index) => (
                  <li key={`learning_${index}`} className="mb-2">
                    <img
                      src={'/img/verificacion_icon.svg'}
                      alt={learning?.description}
                      className="me-3"
                    />
                    {learning?.description}
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-md-6 offset-md-1">
            <div className="ratio ratio-16x9">
              <iframe
                allowFullScreen={true}
                width="560"
                height="315"
                src={course?.functionalityVideo}
                title="..."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
