import { ErrorMessage, useField } from 'formik';
import { TextInputProps } from './types';

export const TextInput = ({ label, ...props }: TextInputProps) => {
  const [field] = useField(props);

  return (
    <>
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input className="form-control" {...field} {...props} max={props.maxLength} />
      {props.errormessage && (
        <ErrorMessage name={props.name} component="span" className="errorMessagge red small" />
      )}
    </>
  );
};
