import { useCourse } from '@/features/course';
import { FormContainer, Hero, Loader } from '@/features/courseInquiry';
import { useAppSelector } from '@/store/hooks';

export const CourseInquiry = () => {
  const { course, isLoading } = useCourse();
  const { isLoading: isLoadingUser } = useAppSelector(state => state.user);

  if (!course.id && isLoading && isLoadingUser) {
    return <Loader />;
  }

  return (
    <div>
      <section className="formConsulta_hero d-flex justify-content-center">
        <div className="container mt-5">
          <div className="row">
            <Hero course={course} />
            <FormContainer />
          </div>
        </div>
      </section>
    </div>
  );
};
