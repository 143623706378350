import { Link } from 'react-router-dom';
export const LegalAndHelp = () => {
  return (
    <div className="row ">
      <div className="col-6 ">
        <h5 className="mb-3 ">Legales y ayuda</h5>
        <nav>
          <ul className="list-unstyled p-0 m-0 ">
            <li>
              <Link to={'/consultas'} className="nav-link dark-grey">
                Ayuda
              </Link>
            </li>
            <li>
              <Link to={'/legales'} className="nav-link dark-grey">
                Políticas de privacidad
              </Link>
            </li>
            <li>
              <Link to={'/legales'} className="nav-link dark-grey">
                Política de compra y cancelaciones
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="col-6 ">
        <h5 className="mb-3 ">Programas</h5>
        <nav>
          <ul className="list-unstyled p-0 m-0 ">
            <li>
              <Link to={'/programas/categorias/digital'} className="nav-link dark-grey ">
                Habilidades Digitales
              </Link>
            </li>
            <li>
              <Link to={'/programas/categorias/personal'} className="nav-link dark-grey ">
                Habilidades Personales
              </Link>
            </li>
            <li>
              <Link to={'/programas/categorias/gestion'} className="nav-link dark-grey ">
                Habilidades de Gestión
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
