import { Modal } from '@/components/modal';
import { ConfirmAccount, LogIn, Register } from '@/features/authentication';
import { CourseInquiryThanks } from '@/features/courseInquiry';
import { ChangeSuccessModal, ResetPassword, ConfirmNewPassword } from '@/features/user';

export const ModalRoute = ({ title, children }: any) => {
  return <Modal title={title}>{children}</Modal>;
};

export const modalsRoutes = [
  { path: '/login', element: <LogIn />, title: 'Iniciar Sesión' },
  { path: '/registro', title: 'Crear Usuario', element: <Register /> },
  { path: '/confirmar-cuenta', title: 'Crear cuenta', element: <ConfirmAccount /> },
  {
    path: '/programas/:id/consulta/gracias',
    title: 'Envío de consulta',
    element: <CourseInquiryThanks />,
  },
  { path: '/recuperar-contrasena', element: <ResetPassword /> },
  { path: '/confirmacion-contrasena', element: <ConfirmNewPassword /> },
  { path: '/exito-contrasena', element: <ChangeSuccessModal /> },
];
