import { axios } from '@/lib/axios';

export const setUserEnroll = async (userID: number, courseID: number) => {
  try {
    const res = await axios.put(`/user/enroll/${userID}/${courseID}`);
    return res.status;
  } catch (error) {
    console.log(error);
    return error;
  }
};
