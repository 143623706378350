import * as React from 'react';
import { Form, Formik } from 'formik';
import 'react-phone-input-2/lib/style.css';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { toTop } from '@/utils';
import {
  EditEducationData,
  EditPersonalData,
  EditContactData,
  ViewPersonalData,
  ViewContactData,
  ViewEducationData,
  personalSchema,
  contactSchema,
  educationSchema,
} from '@/features/user';
import { updateProfileUser } from '@/store/slices/user';
import { ErrorMessage } from '@/components/errorMessage';
import { getCodeCountryByCodeName, getCodeCountryByCodeNumber } from '@/utils/codeCountryNumber';
import { DNIValidation, passportValidation } from '@/features/purchase';

export const Profile: React.FC = () => {
  const { user, isLoading } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const initialValues = {
    id: user.id,
    code: user.code,
    mail: user.mail,
    name: user.name,
    surname: user.surname,
    image: user.image,
    image_profile: user.image_profile,
    description: user.description,
    phone: {
      number: user?.phone?.number,
      countryCode: getCodeCountryByCodeNumber(user?.phone?.countryCode).name,
      area: user?.phone?.area,
    },
    country: user.country,
    nationality: user.nationality,
    genre: user.genre,
    birth: user.birth,
    age: user.age,
    address: user.address,
    educationLevel: user.educationLevel,
    knowledgeArea: user.knowledgeArea,
    occupation: user.occupation,
    personnelInCharge: user.personnelInCharge,
    interests: user.interests,
    lifeStyles: user.lifeStyles,
    tipodoc: user.tipodoc,
    nrodoc: user.nrodoc,
    state: user.state,
    empresa: user.empresa,
  };
  const [schema, setSchema] = React.useState<any>();
  const onSubmit = async values => {
    const valuesToSend = {
      ...values,
      phone: {
        ...values.phone,
        countryCode: getCodeCountryByCodeName(values.phone.countryCode).codeNumber,
      },
    };

    const response = await dispatch(
      updateProfileUser(valuesToSend, keys.concat('user.phone.countryCode'))
    );
    if ((response as { error: string }).error) {
      toTop();
      setError(true);
    } else setEdit(undefined);
  };
  const [keys, setKeys] = React.useState<any>([]);
  const [edit, setEdit] = React.useState<string>();
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    toTop();
  }, []);

  React.useEffect(() => {
    if (edit === 'personal') setSchema(personalSchema);
    if (edit === 'contact') setSchema(contactSchema);
    if (edit === 'education') setSchema(educationSchema);
  }, [edit]);

  const handleKeys = (newKeys: any) => setKeys(Array.from(new Set([...keys, ...newKeys])));

  const [isDNISelected, setIsDNISelected] = React.useState(false);
  const [isPassportSelected, setIsPassportSelected] = React.useState(false);

  const handleCurrentOption = ({ value }: any) => {
    setIsDNISelected(value === 'DNI');
    setIsPassportSelected(value === 'PSP');
    return value === 'DNI';
  };

  if (isLoading) {
    return (
      <div>
        <main className="myProfile">
          <header className="lighter-grey_bg shadow-2 mb-5">
            <div className="container">
              <div className="row py-4">
                <h1 className="m-0 py-2">
                  <em>
                    <img src={'/img/icon_perfil.svg'} alt={''} />
                  </em>{' '}
                  Mi Perfil
                </h1>
              </div>
            </div>
          </header>
          <section className="cards">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-10">
                  <div className="row d-flex align-items-sm-stretch">
                    <p> cargando... </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }

  return (
    <div>
      <main className="myProfile">
        <header className="lighter-grey_bg shadow-2 mb-5">
          <div className="container">
            <div className="row py-4">
              <h1 className="m-0 py-2">
                <em>
                  <img src={'/img/icon_perfil.svg'} alt={''} />
                </em>{' '}
                Mi Perfil
              </h1>
            </div>
          </div>
        </header>
        <section className="cards">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <div className="row d-flex align-items-sm-stretch">
                  {!edit && (
                    <>
                      <ViewPersonalData user={user} setEdit={setEdit} />
                      <ViewContactData user={user} setEdit={setEdit} />
                      <ViewEducationData user={user} setEdit={setEdit} />
                    </>
                  )}
                  {edit && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={
                        edit === 'personal' && isDNISelected
                          ? schema.concat(DNIValidation)
                          : edit === 'personal' && isPassportSelected
                          ? schema.concat(passportValidation)
                          : schema
                      }
                      onSubmit={onSubmit}>
                      {formik => (
                        <Form>
                          {error && (
                            <ErrorMessage
                              message={'No se pudieron modificar los datos correctamente.'}
                            />
                          )}
                          {edit === 'personal' && (
                            <EditPersonalData
                              handleKeys={handleKeys}
                              formik={formik}
                              handleCurrentOption={handleCurrentOption}
                            />
                          )}
                          {edit === 'contact' && (
                            <EditContactData handleKeys={handleKeys} formik={formik} />
                          )}
                          {edit === 'education' && (
                            <EditEducationData handleKeys={handleKeys} formik={formik} />
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
