export const dniOptions = [
  { value: 'CC', label: 'CC: Cedula de Ciudadania' },
  { value: 'CIE', label: 'CIE: Cedula de identidad electoral' },
  { value: 'CIC', label: 'CIC: Cedula de Identidad Civil' },
  { value: 'CIP', label: 'CIP: Cedula de Identidad Personal' },
  { value: 'DNI', label: 'DNI: Documento Nacional de Identidad' },
  { value: 'DIP', label: 'DIP: Documento Personal de Id' },
  { value: 'DUI', label: 'DUI: Documento Unico de Identidad' },
  { value: 'LNC', label: 'LNC: Licencia de Conducir' },
  { value: 'PSP', label: 'PSP: Pasaporte' },
];
