import { getCourseById, getPriceByShortname, IPrice } from '@/features/course';
import { axios } from '@/lib/axios';
import { Dispatch } from '@reduxjs/toolkit';
import { setCourse, startLoadingCourse, setContent, setPrice } from './courseSlice';

const getCourseContent = async (id: string) => {
  try {
    const response = await axios.put(`/course/getcontent/${id}`);

    const formatResponse = response.data.filter(({ name }) => name !== 'Certificado');

    return formatResponse;
  } catch (error) {
    console.log(error);
  }
};

const getPrices = async (shortname: string, currency: string) => {
  const response = await getPriceByShortname({ nombre: shortname });

  const priceFiltered: IPrice = response.data.prices.filter(
    ({ currency_id }) => currency_id === currency
  )[0];

  return priceFiltered;
};

const getPricesRequest = async (shortname: string, currency_id: string) => {
  try {
    const price = await getPrices(shortname, currency_id);
    if (price.listprice === '0.00' || price.listprice === '0,00') {
      throw new Error('Invalid value');
    } else {
      return price;
    }
  } catch (error) {
    return null;
  }
};

export const getCourse = (id: string, currency_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingCourse());
    try {
      const response = await getCourseById({ code: id });

      const priceResponse =
        response.data.mode === 'online'
          ? null
          : await getPricesRequest(response.data.shortname, currency_id);
      const resp = await getCourseContent(JSON.stringify(response.data.id));

      dispatch(setCourse(response.data));
      priceResponse ? dispatch(setPrice(priceResponse)) : dispatch(setPrice({}));
      dispatch(setContent(resp));
    } catch (error) {
      throw { error: error };
    }
  };
};
