import { Spinner } from 'react-bootstrap';

export const Loader = () => {
  return (
    <div className="lms-item">
      <section className="course_hero d-flex align-items-center">
        <div className="container ">
          <div className="row">
            <figure className="col-md-7 px-0">
              <img src={'/img/shimmerLoad.gif'} className="img-fluid w-100" alt={'loader'} />
            </figure>
            <div className="col-md-5 px-0 d-flex flex-column justify-content-center descripcion shadow-1">
              <div className="p-5 d-flex align-items-center mb-5 justify-content-center">
                <Spinner animation="border" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
