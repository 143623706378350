import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { useParams } from 'react-router-dom';
import { toTop } from '@/utils/toTop';
import { getInquiry } from '@/store/slices';

export const useInquiry = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const { inquiry, isLoading } = useAppSelector(state => state.inquiry);

  useEffect(() => {
    if (inquiry.length === 0 && !isLoading) {
      dispatch(getInquiry());
      toTop();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    isLoading,
  };
};
