import Axios, { AxiosRequestConfig } from 'axios';
import Qs from 'qs';
import dayjs from 'dayjs';
import { getToken } from '@/utils/cookieManager';

// const backendUrl: string = env === 'development' ? 'https://testing.mundose.com/api' : '/api';

const backendUrl = '/api';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = getToken();
  if (config.headers === undefined) {
    config.headers = {};
  }
  config.headers.authorization = `Bearer ${token}`;
  // config.headers.Accept = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
}

export const axios = Axios.create({
  baseURL: backendUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (dayjs(value) || value instanceof Date) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);
