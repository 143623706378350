import * as Yup from 'yup';

export const commonSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z\sÁÉÍÓÚáéíóúÑñ]*$/, 'Nombre inválido')
    .max(40)
    .required('Nombre es requerido'),
  surname: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Nombre inválido')
    .max(40)
    .required('Apellido es requerido'),
  tipodoc: Yup.string().required('Tipo de Documento es requerido'),

  phone: Yup.object({
    countryCode: Yup.string().required('Código país es requerido').min(1).max(4),
    area: Yup.string()
      .matches(/^[0-9]*$/)
      .required('Código de área es requerido')
      .min(1)
      .max(4),
    number: Yup.string()
      .matches(/^[0-9]*$/)
      .min(6)
      .max(10)
      .required('Teléfono es requerido'),
  }),
  mail: Yup.string()
    .required('Correo electrónico es querido')
    .email('Correo electrónico inválido. Formato requerido: email@emailejemplo.com'),
  address: Yup.object({
    city: Yup.string().required('Ciudad es requerido'),
    street: Yup.string().required('Dirección es requerido'),
    state: Yup.string()
      .matches(/^[A-Za-z\sÁÉÍÓÚáéíóúÑñ]*$/, 'Provincia / Estado / Región inválido')
      .required('Provincia / Estado / Región es requerido'),
    country: Yup.string()
      .matches(/^[A-Za-z ]+[\u00E0-\u00FC]*$/, 'País de residencia inválido')
      .required('País de residencia es requerido'),
  }),
});

export const passportValidation = Yup.object().shape({
  nrodoc: Yup.string()
    .matches(/^[A-Z]{2,3}[0-9]{7}$/, 'Nro de Identificación inválido 1')
    .max(16)
    .required('Nro de Identificación es requerido'),
});

export const commonValidation = Yup.object().shape({
  nrodoc: Yup.string().max(16).required('Nro de Identificación es requerido 2'),
});

export const DNIValidation = Yup.object().shape({
  nrodoc: Yup.string()
    .matches(/^[0-9]*$/, 'Nro de Identificación inválido 3')
    .max(10)
    .required('Nro de Identificación es requerido'),
});
