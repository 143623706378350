import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getPayment } from '@/store/slices';
import React, { useEffect } from 'react';
import { setUserEnroll } from '../services';
import { getQueryVariable } from '../utils/getParams';

export const useGetPayment = () => {
  const dispatch = useAppDispatch();
  const { payment } = useAppSelector(state => state.payment);
  const { user } = useAppSelector(state => state.user);

  const handleGetPayment = async (paymentID: string) => {
    try {
      dispatch(
        getPayment({
          paymentID,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const paymentID = getQueryVariable('payment_id');

  useEffect(() => {
    const status = getQueryVariable('status');
    if (payment) return;

    if (paymentID && status === 'approved' && user.id) {
      handleGetPayment(paymentID);
    }
  }, [paymentID]);

  return {
    payment,
  };
};
