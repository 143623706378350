import { CourseResponse } from '@/features/courses';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface coursesState {
  courses: CourseResponse[];
  isLoading: boolean;
}

const courses_INITIAL_STATE: coursesState = {
  courses: [],
  isLoading: false,
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState: courses_INITIAL_STATE,
  reducers: {
    startLoadingCourses: state => {
      state.isLoading = true;
    },
    setCourses: (state, action: PayloadAction<CourseResponse[]>) => {
      state.isLoading = false;
      state.courses = action.payload;
    },
  },
});

export const { startLoadingCourses, setCourses } = coursesSlice.actions;
