import { mode } from '@/features/course';
import { CourseResponse } from '@/features/courses';

type HeroProps = {
  course: CourseResponse;
};

export const Hero = ({ course }: HeroProps) => {
  return (
    <div className="col-sm-6 d-flex flex-column justify-content-between position-relative pe-5">
      <header>
        <h5 className="my-3 caption">modalidad {course.mode}</h5>
        <div className="container-course-name">
          <h1 className="mt-3 course-name">{course?.fullname}</h1>
        </div>
      </header>
    </div>
  );
};
