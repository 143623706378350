import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { courseSlice, coursesSlice, inquirySlice, userIPSlice, purchaseSlice } from './slices';
import { paymentSlice } from './slices/payment';
import { userSlice } from './slices/user';
import { certificatesSlice } from './slices/userCertificates';

export const store = configureStore({
  reducer: {
    courses: coursesSlice.reducer,
    course: courseSlice.reducer,
    inquiry: inquirySlice.reducer,
    user: userSlice.reducer,
    purchase: purchaseSlice.reducer,
    userIP: userIPSlice.reducer,
    payment: paymentSlice.reducer,
    myCertificates: certificatesSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
/* eslint-disable */
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
/* eslint-enable */
