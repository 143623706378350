import React from 'react';

import { LegalOptions } from '@/features/legal';
import { toTop } from '@/utils/toTop';

export const Legal = () => {
  React.useEffect(() => {
    toTop();
  }, []);

  return (
    <div>
      <LegalOptions />
    </div>
  );
};
