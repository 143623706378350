import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router-dom';
import { toTop } from '@/utils';
import { getCourse } from '@/store/slices/course';

export const useCourse = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { course, isLoading } = useAppSelector(state => state.course);
  const { userIP, isLoading: loadingIP } = useAppSelector(state => state.userIP);

  useEffect(() => {
    if (!userIP || (!loadingIP && userIP.country !== '')) {
      const currency = userIP.country === 'Argentina' ? '1' : '2';
      if (!course?.fullname || !isLoading) {
        dispatch(getCourse(id, currency));
        toTop();
      }
    }
  }, [userIP, id]);

  return {
    course,
    isLoading,
  };
};
