import React from 'react';

export const UniversityAlliances = () => {
  return (
    <section className="alianzas-universitarias lighter-grey_bg container-fluid pt-5 pb-5 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 pr-0">
            <figure className="splash">
              <img
                src="img/home-new/alianzas.png"
                className="img-fluid"
                alt="Alianzas Universitarias"
              />
            </figure>
          </div>
          <div className="col-lg-8 pl-0">
            <article>
              <h2 className="my-5">Alianzas Universitarias</h2>
              <p
                className="lead"
                style={{
                  fontSize: '1.1rem',
                  width: '85%',
                }}>
                Todos nuestros programas cuentan con aval y sello de calidad certificado por la{' '}
                Universidad Nacional de Córdoba y la Facultad de Ciencias Exactas, Físicas y
                Naturales .
              </p>
              <figure className="d-flex align-items-center">
                <a
                  href="https://www.unc.edu.ar/"
                  style={{
                    marginTop: '3rem',
                  }}>
                  <img
                    src="/img/home-new/logo-UNC.png"
                    alt="UNC"
                    className="img-fluid"
                    width="90"
                  />
                </a>
                <a
                  href="https://fcefyn.unc.edu.ar/"
                  style={{
                    marginTop: '3rem',
                  }}>
                  <img
                    src="/img/logo_fcefyn.svg"
                    alt="FCEF y N"
                    className="img-fluid"
                    width="105"
                  />
                </a>
                <a
                  href="https://usil.edu.pe/"
                  style={{
                    marginTop: '3rem',
                  }}>
                  <img src="/img/Lodo_USIL.svg" alt="USIL" className="img-fluid" width="115" />
                </a>
                <a
                  href="https://romebusinessschool.com/"
                  style={{
                    marginTop: '3rem',
                  }}>
                  <img src="/img/Logo_RMBS.svg" alt="RMBS" className="img-fluid" width="220" />
                </a>
              </figure>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
