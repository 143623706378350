import * as React from 'react';
import { countryOptions } from '@/features/user';
import { PhoneInputField, SelectComponent, TextInput } from '@/components/form';
import { InputContainer } from '@/features/purchase';

type FormProps = {
  handleKeys: (keys: any) => void;
  formik: any;
};

export const EditContactData = ({ handleKeys, formik }: FormProps) => {
  function handleChange(att: string, e) {
    handleKeys([att]);
    formik.handleChange(e);
  }

  return (
    <div className="card datos-contacto mb-5">
      <div className="card-body p-5">
        <div className="row mb-5">
          <div className="col">
            <h3 className="card-title mb-4">Datos de contacto</h3>
            <p className="card-subtitle mb-4">
              Los datos de contacto nos permiten comunicarnos con vos, chequea que tu correo y
              numero telefónicos sean los correctos.
            </p>
            <p className="card-subtitle">* Campos obligatorios</p>
          </div>
        </div>
        <div
          className={`row mb-4 ${
            formik.errors.address?.country && formik.touched.address?.country ? ' error' : ''
          }`}>
          <label htmlFor="address.country" className="form-label">
            Pais *
          </label>
          <SelectComponent
            att={'address'}
            subatt={'country'}
            options={countryOptions.map(({ label, value }) => ({ value: value, label: label }))}
            placeholder={'Seleccionar Pais'}
            formik={formik}
            handleKeys={handleKeys}
          />
        </div>
        <div className="row mb-4">
          <InputContainer
            profileSize
            errors={formik.errors.address?.state}
            touched={formik.touched.address?.state}>
            <TextInput
              label="Provincia / Estado / Región *"
              name="address.state"
              placeholder="Ingresá lugar de residencia"
              onChange={e => handleChange('user.address.state', e)}
            />
          </InputContainer>
        </div>
        <div className="row mb-4">
          <InputContainer
            profileSize
            errors={formik.errors.address?.city}
            touched={formik.touched.address?.city}>
            <TextInput
              label="Ciudad *"
              name="address.city"
              value={formik.values.address?.city}
              onChange={e => handleChange('user.address.city', e)}
              id="address.city"
              placeholder="Ingresá la ciudad"
            />
          </InputContainer>
        </div>
        <div className="row mb-4">
          <InputContainer
            profileSize
            errors={formik.errors.address?.street}
            touched={formik.touched.address?.street}>
            <TextInput
              label="Dirección *"
              name="address.street"
              onChange={e => handleChange('user.address.street', e)}
              placeholder="Ingresa el domicilio"
            />
          </InputContainer>
          <div className="col-md-6 col-lg-4">
            <div className="row">
              <InputContainer
                errors={formik.errors.address?.streetNumber}
                touched={formik.touched.address?.streetNumber}>
                <TextInput
                  label="Numero "
                  name="address.streetNumber"
                  placeholder="Ej.: 234"
                  type="number"
                  onChange={e => handleChange('user.address.streetNumber', e)}
                />
              </InputContainer>
              <InputContainer
                errors={formik.errors.address?.departmentNumber}
                touched={formik.touched.address?.departmentNumber}>
                <TextInput
                  label="Piso / Dpto "
                  name="address.departmentNumber"
                  onChange={e => handleChange('user.address.departmentNumber', e)}
                  placeholder="Ej.: 2D"
                />
              </InputContainer>
            </div>
          </div>
        </div>
        {/*<div className="row mb-4">*/}
        {/*  <InputContainer profileSize errors={formik.errors.mail} touched={formik.touched.mail}>*/}
        {/*    <TextInput*/}
        {/*      label="Correo electrónico *"*/}
        {/*      name="mail"*/}
        {/*      disabled*/}
        {/*      placeholder="Ingresá tu correo electrónico"*/}
        {/*      onChange={e => handleChange('user.mail', e)}*/}
        {/*      type="email"*/}
        {/*    />*/}
        {/*  </InputContainer>*/}
        {/*</div>*/}
        <div className="row mb-4">
          <InputContainer
            profileSize
            errors={formik.errors.phone?.countryCode}
            touched={formik.touched.phone?.countryCode}>
            <PhoneInputField name="phone.countryCode" label="Teléfono *" />
          </InputContainer>
          <div className="col-md-6 col-lg-4">
            <div className="row">
              <InputContainer
                errors={formik.errors.phone?.area}
                touched={formik.touched.phone?.area}>
                <TextInput
                  label="Código de área *"
                  onChange={e => handleChange('user.phone.area', e)}
                  name="phone.area"
                  placeholder="Ej.:11"
                  type="number"
                />
              </InputContainer>
              <InputContainer
                errors={formik.errors.phone?.number}
                touched={formik.touched.phone?.number}>
                <TextInput
                  label="Teléfono *"
                  name="phone.number"
                  onChange={e => handleChange('user.phone.number', e)}
                  placeholder="Ej.: 1234567"
                  type="number"
                />
              </InputContainer>
            </div>
          </div>
        </div>
        <p className="text-end mt-2">
          <button type="submit" className="btn primary-btn btn-rounded red_bg white btn-small">
            Guardar cambios
          </button>
        </p>
      </div>
    </div>
  );
};
