// eslint-disable-next-line no-restricted-imports
import styles from '@/features/purchase/Purchase.module.css';
import { Link } from 'react-router-dom';

export const Error = () => {
  return (
    <div className={styles.thanks}>
      <div className="row">
        <div className="col-sm-6 p-sm-5 pe-sm-0"></div>
        <section className={`${styles.compra} col-sm-6 p-5 ps-3`}>
          <h1>Ha ocurrido un error con tu compra</h1>
          <p>Por favor, volvé a intentarlo más tarde</p>
          <p className="text-end mt-4">
            <Link to={'/'} className="btn secondary-btn btn-rounded me-3">
              SALIR
            </Link>
          </p>
        </section>
      </div>
    </div>
  );
};
