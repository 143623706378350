import { Mode } from '../types/mode';

export const mode: Mode = {
  ondemand: {
    image: '/img/modalidad_icon.svg',
    description: 'Modalidad On Demand',
    functionality: true,
    allowBuy: true,
    practicesDescription1: 'videos',
    practicesDescription2: 'prácticas',
    showLocation: true,
    dedication: true,
    startDate: false,
  },
  online: {
    image: '/img/modalidad_icon.svg',
    description: 'MODALIDAD ONLINE',
    functionality: false,
    allowBuy: false,
    practicesDescription1: 'Encuentros',
    practicesDescription2: 'Encuentros',
    showLocation: false,
    dedication: false,
    startDate: true,
  },
  presencial: {
    image: '/img/modalidad_icon.svg',
    description: 'Modalidad Presencial',
    functionality: false,
    allowBuy: false,
    practicesDescription1: 'Encuentros',
    practicesDescription2: 'Encuentros',
    showLocation: true,
    dedication: false,
    startDate: true,
  },
};
