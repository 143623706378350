// Generated by https://quicktype.io

export interface ClassContentResponse {
  id: number;
  name: string;
  visible: number;
  summary: string;
  summaryformat: number;
  section: number;
  hiddenbynumsections: number;
  uservisible: boolean;
  modules: Module[];
}

export interface Module {
  id: number;
  url: string;
  name: string;
  instance: number;
  contextid: number;
  visible: number;
  uservisible: boolean;
  visibleoncoursepage: number;
  modicon: string;
  modname: string;
  modplural: string;
  availability: null;
  indent: number;
  onclick: string;
  afterlink: null;
  customdata: string;
  noviewlink: boolean;
  completion: number;
  downloadcontent: number;
  dates: DateElement[];
  completiondata?: Completiondata;
  contents?: Content[];
  contentsinfo?: Contentsinfo;
}

export interface Completiondata {
  state: number;
  timecompleted: number;
  overrideby: null;
  valueused: boolean;
  hascompletion: boolean;
  isautomatic: boolean;
  istrackeduser: boolean;
  uservisible: boolean;
  details: Detail[];
}

export interface Detail {
  rulename: string;
  rulevalue: Rulevalue;
}

export interface Rulevalue {
  status: number;
  description: string;
}

export interface Content {
  type: string;
  filename: string;
  filepath: string;
  filesize: number;
  fileurl: string;
  timecreated: number | null;
  timemodified: number;
  sortorder: number;
  mimetype?: string;
  isexternalfile?: boolean;
  userid: number | null;
  author: null | string;
  license: null | string;
}

export interface Contentsinfo {
  filescount: number;
  filessize: number;
  lastmodified: number;
  mimetypes: string[];
  repositorytype: string;
}

export interface DateElement {
  label: Label;
  timestamp: number;
}

export enum Label {
  Abiertos = 'Abiertos:',
  Abrió = 'Abrió:',
  FechaEsperada = 'Fecha Esperada:',
}
