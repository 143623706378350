import { axios } from '@/lib/axios';
import { Dispatch } from '@reduxjs/toolkit';
import { default as axiosAPI } from 'axios';
import { MaxMindAPIResponse, UserIPResponse } from './interfaces';

import { startLoadingUserIP, setUserIP } from './userIp';

const getAsyncIpMain = async () => {
  const responseAPI = await axiosAPI.get<MaxMindAPIResponse>(
    'https://geoip.maxmind.com/geoip/v2.1/city/me'
  );
  if (responseAPI.status !== 200) return null;
  return responseAPI;
};

const getAsyncIp = async (): Promise<string> => {
  try {
    const maxMindApi = await getAsyncIpMain();
    const responseAPI = maxMindApi
      ? maxMindApi
      : await axiosAPI.get('https://geolocation-db.com/json/');
    if (responseAPI.status !== 200) throw new Error('Error getting IP');
    const IP = maxMindApi ? maxMindApi.data.traits.ip_address : responseAPI.data?.IPv4;
    return IP;
  } catch (error) {
    const responseAPI = await axiosAPI.get('https://api.ipify.org/?format=json');
    if (responseAPI.status !== 200) throw new Error('Error getting IP');
    return responseAPI.data?.ip;
  }
};

export const getUserIP = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingUserIP());
    try {
      // request geolocation api
      const IP = await getAsyncIp();

      // request mundose api
      const response = await axios.get<UserIPResponse>(`/geoIP/${IP}`);
      const {
        country,
        countryCod,
        countryCurrencyCodigo,
        countryCurrencyISO3,
        countryCurrencyNombre,
        countryCurrencySimbolo,
        formaPago,
        ipAddress,
      } = response.data;

      dispatch(
        setUserIP({
          country,
          countryCod,
          countryCurrencyCodigo,
          countryCurrencyISO3,
          countryCurrencyNombre,
          countryCurrencySimbolo,
          formaPago,
          ipAddress,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};
