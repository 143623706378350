import React, { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import '@/features/course/Course.css';
import { Thumbnail } from '@/features/courses';
import { useAppSelector } from '@/store/hooks';
import { Link } from 'react-router-dom';
import { mode } from '../utils/mode';
import { userSlice } from '@/store/slices/user';

export const Related = () => {
  const { course } = useAppSelector(state => state.course);
  const { courses } = useAppSelector(state => state.courses);

  const relatedCourses = useMemo(() => {
    const coursesFiltered = courses.filter(_course => _course.category === course.category);
    if (coursesFiltered.length < 3) {
      const coursesRelated = [...courses].slice(0, 3);
      return coursesRelated;
    }
    return coursesFiltered;
  }, [course, courses]);

  return (
    <section className="te_puede_interesar lighter-grey_bg py-5">
      <div className="py-md-5 py-0 container">
        <div className="row">
          <h2 className="mb-5">También te puede interesar</h2>
        </div>
        <section className="courses_list container">
          <div className="row pb-5 px-sm-5 gap-5">
            {relatedCourses.length > 0 ? (
              relatedCourses?.slice(0, 3).map(course => {
                return <Thumbnail key={course.id} course={course} />;
              })
            ) : (
              <Thumbnail empty />
            )}
          </div>
        </section>
      </div>
    </section>
  );
};
