import { useWindowDimensions } from '@/hooks';
import { Link } from 'react-router-dom';

/* eslint-disable react/no-unescaped-entities */
export const Terms = () => {
  const wide = useWindowDimensions(700);
  const position = wide ? 'fixed' : 'static';
  const paddingTop = wide ? null : '60px';
  return (
    <div>
      <section className="legales_hero gradient-50 d-flex align-items-start">
        <div className="container">
          <div
            className="row position-relative"
            style={{
              paddingTop,
            }}>
            <div
              className="nav flex-column nav-pills me-3 col-sm-6 selector-legales py-5 pe-sm-5"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
              style={{
                position,
              }}>
              <header className="pb-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/legales'}>
                        <i className="fas fa-chevron-left"></i> Privacidad y Términos
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Términos y Condiciones
                    </li>
                  </ol>
                </nav>
                <h3>Términos y Condiciones</h3>
                <p className="lead">Selecciona para acceder a la información</p>
              </header>
              <button
                className="nav-link active"
                id="v-pills-Introduccion-tycs-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Introduccion-tycs"
                type="button"
                role="tab"
                aria-controls="v-pills-Introduccion-tycs"
                aria-selected="true">
                Introducción
              </button>

              <button
                className="nav-link"
                id="v-pills-sobre-servicio-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-sobre-servicio"
                type="button"
                role="tab"
                aria-controls="v-pills-sobre-servicio"
                aria-selected="false">
                Sobre el servicio
              </button>

              <button
                className="nav-link"
                id="v-pills-cancelaciones-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-cancelaciones"
                type="button"
                role="tab"
                aria-controls="v-pills-cancelaciones"
                aria-selected="false">
                Política de compra y cancelaciones
              </button>

              <button
                className="nav-link"
                id="v-pills-usuario-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-usuario"
                type="button"
                role="tab"
                aria-controls="v-pills-usuario"
                aria-selected="false">
                Sobre el usuario
              </button>

              <button
                className="nav-link"
                id="v-pills-acuerdo-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-acuerdo"
                type="button"
                role="tab"
                aria-controls="v-pills-acuerdo"
                aria-selected="false">
                Sobre el acuerdo
              </button>
            </div>
            <div className="tab-content col-sm-6 legales-content py-5" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-Introduccion-tycs"
                role="tabpanel"
                aria-labelledby="v-pills-Introduccion-tycs-tab"
                tabIndex={0}>
                {' '}
                <h3>Introducción</h3>
                <p>
                  El presente documento describe los términos y condiciones generales (los “Términos
                  y Condiciones Generales” o “TyC”) aplicables a la adquisición de los Servicios
                  ofrecidos en la plataforma de TRANSFORMARME S.A.S., (“los Servicios”) dentro del
                  sitio web mundose.com (“Mundos E”, o el “Sitio”) y/o cualquier otro canal de
                  comercialización que se utilice en el futuro para ofrecer dichos servicios.{' '}
                </p>
                <p>
                  Cualquier persona (en adelante, “Usuario” o en plural “Usuarios”) que desee
                  acceder y/o usar el Sitio a fin adquirir las capacitaciones ofrecidas por Mundos E
                  cuyo contenido se promociona y comercializa allí, podrá hacerlo sujetándose a los
                  presentes Términos y Condiciones Generales, junto con todas las demás políticas y
                  principios que rigen Mundos E y que son incorporados al presente por referencia,
                  todo lo cual resulta obligatorio y vinculante.
                </p>
                <p>
                  El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los
                  TyC así como en los demás documentos incorporados a los mismos por referencia,
                  previamente a su registración como Usuario de Mundos E, pudiendo optar de forma
                  expresa por recibir los mismos y toda otra información por medios digitales; de
                  modo que la utilización del Servicio implica la aceptación por parte del Usuario
                  de los Términos y Condiciones Generales. El sitio se basa en la confianza y en la
                  honestidad de sus Usuarios para que se puedan realizar las operaciones en un
                  ambiente amistoso y confiable.
                </p>
                <p>
                  En consecuencia, el acceso y/o el uso del Sitio y/o de los servicios promocionados
                  implican que el Usuario ha leído y comprendido los Términos y Condiciones
                  Generales de Mundos E. Ante la falta de acuerdo con los presentes TyC el Usuario
                  deberá abstenerse de utilizar el Sitio.{' '}
                </p>
                <p>
                  Todas las visitas y todos los contratos y transacciones que se realicen en este
                  Sitio, así como sus efectos jurídicos, quedarán regidos por estas reglas y
                  sometidos a la legislación aplicable en la República Argentina, de modo que los
                  TyC contenidos en este instrumento se aplicarán y se entenderán como parte
                  integral de todos los actos y contratos que se ejecuten o celebren mediante los
                  sistemas de oferta y comercialización comprendidos en este Sitio.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-sobre-servicio"
                role="tabpanel"
                aria-labelledby="v-pills-sobre-servicio-tab"
                tabIndex={0}>
                <h3>Sobre el servicio</h3>

                <h6>I. ¿En qué consisten los servicios de Mundos E? </h6>
                <p>
                  Mundos E ofrece una plataforma en línea donde los Usuarios podrán acceder a
                  diferentes programas de capacitación. Así, el Usuario podrá navegar en el sitio de
                  Mundos E y estará habilitado para seleccionar aquellas capacitaciones que resulten
                  de su interés a los fines de contratarlas en línea. Las capacitaciones serán
                  dictadas a través de diferentes modalidades conforme se detalla en el presente
                  documento. Los programas podrán tener condiciones particulares detalladas al
                  adquirirlos.
                </p>

                <h6>II. ¿Quiénes pueden adquirir los Servicios del Sitio? </h6>
                <p>
                  Los Servicios sólo están disponibles para personas que tengan capacidad legal para
                  contratar. No podrán utilizar los Servicios las personas que no tengan esa
                  capacidad, incluyendo expresamente a los menores de edad. Tampoco estarán
                  disponibles los Servicios para aquellos Usuarios que hayan sido suspendidos o
                  inhabilitados.{' '}
                </p>
                <p>
                  Quien registre un Usuario como empresa, deberá tener capacidad para contratar a
                  nombre de tal entidad y de obligar a la misma en los términos del presente.{' '}
                </p>
                <p>
                  Asimismo, para utilizar los Servicios de Mundos E es obligatorio que el Usuario
                  realice la Registración de su información personal.{' '}
                </p>

                <h6>V. ¿En qué consisten las Publicaciones de Servicios? </h6>
                <p>
                  El Sitio Web de Mundos E publicará en la sección ‘’Programas’’ todos los cursos
                  vigentes, individualizando cuáles son los Servicios que se ofrecen. Los Programas
                  adquiridos en el Sitio Web podrán ser bajo alguna de las siguientes modalidades:{' '}
                </p>
                <p>
                  (i) Programa online Ondemand, que consistirá en capacitaciones pregrabadas y
                  alojadas en el sitio web de Mundos E, donde el Usuario podrá hacer uso y
                  visualizar las grabaciones cuantas veces quiera dentro del plazo estipulado. Cada
                  programa incluirá un detalle de la cantidad de clases, la duración de cada una y
                  el tiempo de duración de la capacitación.
                </p>
                <p>
                  (ii) Programa online en vivo, que consistirá en capacitaciones dictadas por
                  diferentes capacitadores a través de una plataforma online de forma sincrónica,
                  donde el Usuario podrá hacer uso del dictado de la cursada desde cualquier parte
                  del mundo. Cada programa incluirá un detalle de la cantidad de clases, la duración
                  de cada una y a través de qué plataforma online se lleva adelante la cursada.{' '}
                </p>

                <h6>
                  {' '}
                  VIII. ¿Cuáles son las condiciones generales de los Programas de capacitación?
                </h6>
                <p>
                  En primer lugar, los Programas en vivo requieren una asistencia mínima del 80% de
                  los encuentros a los efectos de finalizar el mismo satisfactoriamente y obtener un
                  certificado de asistencia. En caso de inasistencia, Mundos E ofrece la posibilidad
                  al Usuario de visualizar la grabación del encuentro y responder un cuestionario de
                  tópicos tratados en el mismo, ello con el propósito de suplir su falta. En ese
                  sentido, queda terminantemente prohibida la difusión de las grabaciones de los
                  encuentros.
                </p>
                <p>
                  En segundo lugar, las capacitaciones no contienen un método de evaluación parcial
                  hacia los Usuarios a los efectos de obtener su certificación de asistencia, sino
                  que únicamente será requerido un proyecto de integración final al concluir la
                  cursada con requerimientos específicos para cada programa detallados por la
                  persona a cargo de los mismos.{' '}
                </p>
                <p>
                  En tercer lugar, el Usuario deberá tener la asistencia mínima requerida y el
                  proyecto integrador presentado de forma conjunta a los efectos de obtener su
                  certificación de asistencia. En caso que alguna de ambas condiciones no sea
                  cumplida, Mundos E se reserva el derecho de desaprobar al Usuario y no otorgar el
                  correspondiente certificado de asistencia.
                </p>
                <p>
                  En cuarto lugar, en caso que un Usuario sea considerado no apto para el Programa
                  contratado, Mundos E se reserva el derecho de sancionarlo a través de llamados de
                  atención o bien la expulsión del mismo de la cursada.{' '}
                </p>
                <p>
                  A modo ejemplificativo, las situaciones que pueden dar lugar a considerar al
                  Usuario como no apto pueden ser las siguientes: agresiones a sus pares o a los
                  capacitadores, realizar comentarios inapropiados, fomentar actividades ilegales,
                  entre otras situaciones.
                </p>

                <h6>IX. ¿Cómo se abonan las distintas modalidades de los Servicios?</h6>
                <p>
                  Las distintas modalidades de los Servicios publicados en Mundos E serán abonadas y
                  gestionadas a través del sitio web creado a sus efectos.{' '}
                </p>
                <p>
                  En ese sentido, el Usuario podrá optar por un Programa en particular y adquirirlo
                  a través de las siguientes plataformas de pago:
                </p>
                <ol>
                  <li>a. PAGOS360, provista por POWDER S.A., CUIT N° 30-71440731-3.</li>
                  <li>b. PAYPAL, provista por PAYPAL PTE. LTD., CDI N° 30-71114748-5. </li>
                  <li> c. WISE, provista por WISE PAYMENTS LTD.</li>
                  <li>d. STRIPE, provista por STRIPE INC. </li>
                  <li> e. PAYU, provista por PAYU ARGENTINA S.A., CUIT N° 30-70874351-4.</li>
                  <li> f. MERCADO PAGO, provista por MERCADOLIBRE S.R.L.,CUIT: 30-70308853-4.</li>
                  <li> g. TODO PAGO, provista por BOTON DE PAGO S.A. , 30-71460028-8</li>
                </ol>
                <p>
                  A tales fines, el Usuario deberá aceptar los Términos y Condiciones y Políticas de
                  Privacidad de cada sitio web de la plataforma de pago elegida.{' '}
                </p>
                <p>
                  Por su parte, los Programas podrán ser abonados dependiendo del medio de pago
                  elegido en cada una de las plataformas anteriormente nombradas, pudiendo ser a
                  través del débito automático mensual desde una tarjeta de débito, o bien a través
                  de cuotas a través de una tarjeta de crédito.{' '}
                </p>
                <p>
                  El Usuario será el único responsable de la efectiva contratación de una
                  capacitación y abono a través de una plataforma de pago, y por tanto ni Mundos E
                  ni ninguna de las plataformas de pago (a saber, PAGOS360, PAYPAL, WISE, TRIPE,
                  PAYU) serán partes en dicha relación contractual, y no tendrán ningún tipo de
                  responsabilidad por obligaciones derivadas de dicha relación contractual.{' '}
                </p>
                <p>
                  Por su parte, se deja expresa constancia que la falta de pago de una cuota por
                  parte del Usuario habilitará a Mundos E a intimarlo para que enderece su
                  incumplimiento dentro de un plazo de 10 días corridos. La falta de pago una vez
                  vencido el plazo anteriormente descripto será causa suficiente para dar de baja el
                  perfil del Usuario dentro del sitio de Mundos E.
                </p>
                <h6>X. ¿Cuál es el costo de los Servicios? </h6>
                <p>
                  Cada Servicio cuenta con un precio asignado en su descripción, el cual es asignado
                  por Mundos E.{' '}
                </p>
                <p>
                  Mundos E no se responsabiliza por el mejor costo que el Usuario pueda obtener de
                  dicho Servicio por sus propios medios, ni por los mejores precios que otro Sitio
                  pueda ofrecer por Servicios similares o análogos. Mundos E no se responsabilizará
                  por la dificultad sobreviniente o imposibilidad de utilizar cualquiera de los
                  Servicios contratados por los Usuarios.{' '}
                </p>
                <h6>XII. ¿Qué hago si se facturaron cargos que no correspondían? </h6>
                <p>
                  En caso de haberse facturado cargos que no hubiesen correspondido, el Usuario
                  deberá comunicarse con nuestro equipo de Atención al Usuario para resolver dicha
                  cuestión a{' '}
                  <a href="cobranzas@mundose.com" target="_blank">
                    cobranzas@mundose.com
                  </a>
                </p>
                <h6>XIV. ¿Qué ocurre si el sistema presenta fallas? </h6>
                <p>
                  Mundos E se compromete a realizar los mejores esfuerzos para asegurar la
                  disponibilidad sin interrupciones de los Servicios, así como la ausencia de
                  errores en cualquier transmisión de información que pudiera tener lugar; no
                  obstante, y debido a la naturaleza misma de Internet, no es posible garantizar
                  tales extremos.{' '}
                </p>
                <p>
                  Asimismo, el acceso a los Servicios de Mundos E puede eventualmente verse
                  suspendido o restringido a efectos de la realización de trabajos de reparación o
                  mantenimiento, o la introducción de nuevos productos o servicios, en cuyo caso
                  Mundos E se compromete a limitar la frecuencia y duración de tales suspensiones o
                  restricciones y comunicarlas las tareas en cuestión con anticipación.{' '}
                </p>
                <p>
                  Mundos E no garantiza el acceso y uso continuado o ininterrumpido de su sitio. El
                  sistema puede eventualmente no estar disponible debido a dificultades técnicas o
                  fallas de Internet, o por cualquier otra circunstancia ajena a Mundos E; en tales
                  casos se procurará restablecerlo con la mayor celeridad posible sin que por ello
                  pueda imputársele algún tipo de responsabilidad. Mundos E no será responsable por
                  ningún error u omisión de contenidos en el Sitio.{' '}
                </p>
                <p>
                  Mundos E no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario
                  causados por fallas en el sistema, en el servidor o en Internet. Mundos E tampoco
                  será responsable por cualquier virus que pudiera infectar el equipo del Usuario
                  como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier
                  transferencia de datos, archivos, imágenes, textos, o audio contenidos en el
                  mismo. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por
                  lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o
                  fallas en los sistemas o en Internet.{' '}
                </p>
                <h6>XVI. ¿Qué obligaciones asume Mundos E? </h6>
                <p>
                  Mundos E se obliga a brindar una plataforma de servicios de capacitación sin
                  asegurar resultados económicos y/o laborales para los Usuarios, ya que dichos
                  resultados dependerán estrictamente de factores personales y externos fuera del
                  control de la institución.
                </p>
                <p>
                  Mundos E no suplirá la falta de atención y diligencia que presten los Usuarios en
                  la contratación, de modo que no asume responsabilidad por la errónea
                  interpretación que realicen los Usuarios de los Servicios que contraten. En el
                  mismo sentido, Mundos E no se responsabiliza por los mejores precios en Servicios
                  que pueda obtener el Usuario por sus propios medios, así como tampoco garantiza
                  poder igualar aquellos.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-cancelaciones"
                role="tabpanel"
                aria-labelledby="v-pills-cancelaciones-tab"
                tabIndex={0}>
                <h3>Políticas de cancelación</h3>
                <h6>VII. ¿Cuáles son las políticas de cancelación y devolución que rigen?</h6>
                <p>
                  Las políticas de cancelación de las compras de los diferentes Programas variarán
                  conforme lo estipulado a continuación:{' '}
                </p>
                <p>
                  En primer lugar, la adquisición de un Programa por parte del Usuario podrá ser
                  cancelada dentro de los 10 días corridos desde la compra y la devolución del
                  precio se producirá mediante un contacto del Sector de Facturación de Mundos E por
                  medio de correo electrónico con el Usuario.
                </p>
                <p>
                  En segundo lugar, el plazo de 10 días corridos desde la compra que posee el
                  Usuario para cancelar la misma se encuentra supeditado a la no utilización del
                  Programa adquirido por parte del Usuario, mientras que en caso contrario queda
                  vedada la posibilidad de revocación de la compra por haber comenzado a utilizar el
                  derecho adquirido.{' '}
                </p>
                <p>
                  Es decir, en caso que el Usuario comience a conectarse a las clases adquiridas (en
                  caso de un Programa Online) o a visualizar las clases pregrabadas (en caso de un
                  Programa Pregrabado), no podrá cancelar la compra del Programa aunque se encuentre
                  dentro del plazo indicado, por más que el Programa sea debitado mensualmente de
                  las tarjetas del Usuario en virtud del medio de pago optado.
                </p>
                <p>
                  En tercer lugar, en caso de que el Usuario adquiera una ‘’Masterclass’’ o un
                  Programa con una duración de una clase, la misma podrá ser cancelada dentro del
                  plazo de 10 días corridos desde efectuada la compra, salvo que la clase comience
                  en un plazo menor. En ese supuesto, el Usuario solamente podrá cancelar la compra
                  siempre que no haga uso y no asista a la clase en cuestión, debido a que el
                  Usuario acepta conocer dicha modalidad que responde a un requerimiento específico
                  del mismo, y por imperio del art. 1116 del Código Civil y Comercial de la Nación,
                  queda vedada la posibilidad de revocar la misma y obtener la devolución de lo
                  abonado, con la excepción de la precisión hecha anteriormente.{' '}
                </p>
                <p>
                  En cuarto lugar, en caso de que el Usuario manifieste una disconformidad general
                  con el curso en cuestión una vez comenzado el mismo, se deja constancia que no
                  existe una obligación legal por parte de Mundos E a proceder al reintegro del
                  dinero, por lo que se le sugiere al Usuario la previa lectura detenida de los
                  contenidos del curso. De todas formas, ante cualquier sugerencia y/o reclamo, el
                  Usuario podrá contactarse con Mundos E a través del correo electrónico{' '}
                  <a href="mailto:administracion@mundose.com" target="_blank" rel="noreferrer">
                    administracion@mundose.com
                  </a>
                  , donde los Directivos de la empresa evaluarán su caso en particular.
                </p>
                <p>
                  La devolución que corresponda al caso se efectivizará según el modo en que haya
                  sido abonado y será procesado por Mundos E a través de un contacto vía correo
                  electrónico con el Usuario
                </p>
                <p>
                  Será considerada una falta muy grave cuando un Usuario adquiera un Servicio,
                  efectivamente lo utilice y niegue falsamente haberlo hecho. Mundos E se reserva el
                  derecho de iniciar las acciones legales que correspondan al caso, y suspender la
                  cuenta del Usuario.{' '}
                </p>
                <p>
                  Por su parte, Mundos E ofrece la posibilidad al Usuario de realizar un cambio en
                  la capacitación adquirida una vez iniciada la misma sin necesidad de esgrimir los
                  motivos. Esta posibilidad estará vigente para cualquier capacitación y el Usuario
                  podrá realizar el cambio hacia otro programa que resulte de su agrado hasta la
                  tercera clase inclusive de iniciado el paquete adquirido originariamente.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-usuario"
                role="tabpanel"
                aria-labelledby="v-pills-usuario-tab"
                tabIndex={0}>
                <h3>Sobre el usuario</h3>
                <h6>III. ¿En qué consiste la Registración como Usuario de Mundos E?</h6>
                <p>
                  Para registrarse, el Usuario deberá proporcionar en el formulario web del Sitio su
                  información personal de manera exacta, precisa y verdadera ("Datos Personales") y
                  asume el compromiso de actualizarlos conforme resulte necesario, revistiendo los
                  Datos Personales carácter de declaración jurada.{' '}
                </p>
                <p>
                  Se deja expresa constancia de que Mundos E no se responsabiliza por la certeza de
                  los Datos Personales de los Usuarios. El Usuario presta expresa conformidad con
                  que Mundos E utilice diversos medios para identificar sus Datos Personales,
                  asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados.
                </p>
                <p>
                  Mundos E se reserva el derecho de solicitar algún comprobante y/o dato adicional a
                  efectos de corroborar los Datos Personales, así como de suspender temporal o
                  definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.
                  Asimismo, se reserva el derecho de rechazar cualquier solicitud de registración o
                  de cancelar una registración previamente aceptada, sin que esté obligado a
                  comunicar o exponer las razones de su decisión y sin que ello genere algún derecho
                  a indemnización o resarcimiento
                </p>
                <p>
                  Los Datos Personales se procesan y almacenan en servidores o medios magnéticos que
                  mantienen altos estándares de seguridad y protección tanto física como
                  tecnológica. Para más información al respecto, las Políticas de Privacidad de
                  Mundos E se encuentran disponibles en el siguiente link:{' '}
                  <a href="/legal/Politica%20de%20Privacidad">Políticas de Privacidad</a>{' '}
                </p>
                <p>
                  Particularmente, la información volcada al sitio de registración de Mundos E en
                  relación a la cursada completa o incompleta de sus estudios secundarios deberá ser
                  exacta y veraz, revistiendo carácter de Declaración Jurada. Cabe destacar que
                  acorde a lo dispuesto por el art. 7, inc. h, punto 3 del Decreto 280/1997 (Ley del
                  Impuesto al Valor Agregado) los servicios prestados por establecimientos
                  educacionales privados incorporados a los planes de enseñanza oficial y
                  reconocidos como tales por las respectivas jurisdicciones, referidos a la
                  enseñanza de todos los niveles, grados y posgrados para egresados de los niveles
                  secundario, terciario o universitario, estarán exentas de tributar IVA. En
                  consecuencia, el Usuario al manifestar que ha finalizado sus estudios secundarios
                  está siendo beneficiado directamente por la presente exención, por lo que en caso
                  de corroborarse la falsedad de la información brindada a través de una Auditoría,
                  el Usuario será directamente responsable ante AFIP por el impuesto no abonado.
                </p>
                <h6>IV. ¿Cómo pueden acceder a su cuenta los Usuarios? </h6>
                <p>
                  El Usuario accederá a su cuenta personal ("Cuenta") mediante el ingreso de su
                  correo electrónico y una clave de seguridad personal elegida ("Clave de
                  Seguridad"), que le permitirá el acceso personalizado, confidencial y seguro. El
                  Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad.{' '}
                </p>
                <p>
                  La Cuenta es personal, única e intransferible. El Usuario será responsable por
                  todas las operaciones efectuadas en su Cuenta.{' '}
                </p>
                <p>
                  Asimismo, el Usuario se compromete a notificar a Mundos E en forma inmediata y por
                  medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el
                  ingreso por terceros no autorizados a la misma.
                </p>
                <h6>
                  VI. ¿Cuáles son las condiciones para que los Usuarios contraten las capacitaciones
                  ofrecidas por Mundos E?{' '}
                </h6>
                <p>
                  Una vez que el Usuario haya completado satisfactoriamente la Registración en el
                  Sitio, podrá adquirir los programas de capacitación online Ondemand ofrecidos por
                  la plataforma de Mundos E, donde podrá visualizar el detalle de los mismos, su
                  modalidad de dictado y condiciones particulares.{' '}
                </p>
                <p>
                  Al ingresar al apartado de ‘’Programas’’, los Usuarios interesados podrán reservar
                  únicamente aquellos Programas que se encuentren disponibles dependiendo del tipo
                  de modalidad publicada.{' '}
                </p>
                <p>
                  Por su parte, los cursos en vivo o presenciales podrán ser adquiridos por el
                  Usuario previa asistencia de un asesor de Mundos E, quien lo contactará y le
                  informará un detalle de los mismos, su modalidad de dictado y condiciones
                  particulares.{' '}
                </p>
                <p>
                  El pago de la Suscripción deberá realizarse bajo las condiciones que determine
                  Mundos E. Al reservar un Programa, el Usuario acepta quedar obligado por las
                  condiciones incluidas en la Publicación del mismo a través de la plataforma web.{' '}
                </p>
                <h6>XI. ¿Existe un deber de permanencia de los Usuarios en Mundos E? </h6>
                <p>
                  El Usuario tiene derecho a cancelar su registración en cualquier momento, sin que
                  exista una obligación de permanencia. La registración de los Usuarios continuará
                  vigente hasta que sea cancelada expresamente por el Usuario o en caso que Mundos E
                  decidiera darla de baja.{' '}
                </p>
                <p>
                  La falta de comunicación fehaciente a Mundos E de la cancelación de la suscripción
                  de parte del Usuario o el desuso del Sitio no indican la baja automática.
                </p>
                <h6>XIII. ¿Cuáles son las formas de comunicación de Mundos E con el Usuario? </h6>
                <p>
                  Mundos E podrá ponerse en contacto con el Usuario electrónicamente por distintos
                  medios: correo electrónico, mensajes de texto, y notificaciones, mensajes o
                  comunicaciones dentro del sitio web. A efectos del presente, el Usuario acepta que
                  todos los contratos, avisos y otras notificaciones y comunicaciones que sean
                  enviadas por medios electrónicos satisfacen cualquier requisito de forma escrita,
                  salvo que cualquier legislación aplicable con carácter imperativo exigiera una
                  forma distinta de comunicación.
                </p>
                <p>
                  Al aceptar los presentes Términos y Condiciones el Usuario entiende y acepta que
                  podrá recibir comunicaciones de Mundos E; incluyendo, pero no limitándose a
                  avisos, consultas, encuestas y/o notificaciones requeridas o no por el
                  ordenamiento jurídico vigente. En caso de optar por salir de nuestra lista de
                  destinatarios de comunicaciones de marketing directo, podrá ejercer tal opción
                  haciendo “click” en el link con dicha opción; la cual estará presente en cada
                  comunicación de marketing directo que Mundos E remita.{' '}
                </p>
                <h6>XV. ¿Qué sanciones se aplican ante una infracción del Usuario? </h6>
                <p>
                  Sin perjuicio de otras medidas, Mundos E podrá advertir, suspender en forma
                  temporal o definitivamente la Cuenta de un Usuario, iniciar las acciones que
                  estime pertinentes si: (a) se quebrantara alguna ley, o cualquiera de las
                  estipulaciones de los Términos y Condiciones Generales y demás políticas de Mundos
                  E; y (b) si se incurriera a criterio de Mundos E en conductas o actos dolosos o
                  fraudulentos.{' '}
                </p>
                <h6>XXI. ¿Cómo me comunico con Atención al Usuario?</h6>
                <p>
                  Para consultas sobre los presentes Términos y Condiciones o ante cualquier
                  eventualidad, contactar a Atención al Usuario al correo electrónico:{' '}
                  <a href="mailto:info@mundose.com" target="_blank" rel="noreferrer">
                    info@mundose.com
                  </a>{' '}
                  y obtendrá una respuesta en un plazo máximo de 5 días hábiles.{' '}
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="v-pills-acuerdo"
                role="tabpanel"
                aria-labelledby="v-pills-acuerdo-tab"
                tabIndex={0}>
                {' '}
                <h3>Sobre el Acuerdo</h3>
                <h6>XVII. ¿Pueden modificarse los Términos y Condiciones Generales?</h6>
                <p>
                  Mundos E podrá modificar los Términos y Condiciones Generales en cualquier momento
                  haciendo públicos en el Sitio los términos modificados. Todos los términos
                  modificados entrarán en vigor a los 10 (diez) días de su publicación. Dichas
                  modificaciones serán comunicadas por Mundos E a los Usuarios que en la
                  Configuración de su Cuenta Personal hayan indicado que desean recibir
                  notificaciones de los cambios en estos Términos y Condiciones.{' '}
                </p>
                <p>
                  Todo Usuario que no esté de acuerdo con las modificaciones efectuadas por Mundos E
                  podrá abstenerse de utilizar el Sitio o solicitar la baja de la cuenta en todo
                  momento
                </p>
                <h6>
                  XVIII. ¿Cuáles son las condiciones de privacidad de la Información del Sitio?
                </h6>
                <p>
                  Para utilizar los Servicios ofrecidos por Mundos E, los Usuarios deberán facilitar
                  determinados datos de carácter personal, todo lo cual se procesa y almacena en
                  servidores o medios magnéticos que mantienen altos estándares de seguridad y
                  protección física y tecnológica.{' '}
                </p>
                <p>
                  Mediante el uso del Sitio, el Usuario reconoce y conviene en que Mundos E puede a
                  su sola discreción conservar por tiempo indeterminado el contenido del Usuario,
                  así como también sus Datos Personales, ya sea direcciones de email, direcciones
                  IP, datos de tiempo y hora, y demás información del Usuario, sobre sus actividades
                  o acceso y uso del sitio. Podrá divulgar la información del Usuario únicamente
                  cuando considere que es necesario por ley o creyendo de buena fe que dicha
                  conservación o divulgación es razonablemente necesaria para lo siguiente: i)
                  cumplir con un proceso legal; ii) exigir el cumplimiento de estos Términos y
                  Condiciones; iii) responder a reclamos sobre la violación de los derechos de
                  terceros en virtud del contenido; y/o iv) proteger los derechos, la propiedad, o
                  la seguridad de Mundos E, sus usuarios o del público en general.{' '}
                </p>
                <h6>XIX. ¿Qué consecuencias tiene una violación del Sistema o Bases de Datos? </h6>
                <p>
                  No está permitida ninguna acción o uso de dispositivo, software, u otro medio
                  tendiente a interferir tanto en la operatoria del Sitio como en las cuentas o
                  bases de datos de Mundos E.
                </p>
                <h6>
                  Los Usuarios se comprometen a no tomar ninguna acción para poner en peligro,
                  limitar o interferir con el Sitio. Los Usuarios no pueden descompilar, realizar
                  ingeniería inversa o intentar descubrir el código fuente de cualquier elemento del
                  Sitio; ni tampoco aplicar o utilizar herramientas de automatización para
                  conectarse, modificar o controlar el contenido del Sitio.
                </h6>
                <p>
                  Mundos E puede bloquear, restringir, desconectar o interrumpir el acceso a
                  Usuarios a todo o parte del Sitio de Mundos E en cualquier momento, a su propio
                  criterio y sin previo aviso u obligación. Cualquier conducta que, a criterio
                  exclusivo de Mundos E, limite o impida el uso o aprovechamiento del Sitio por
                  parte de cualquier otra persona o entidad está estrictamente prohibida y puede
                  ocasionar la cancelación del acceso al Sitio sin previo aviso.
                </p>
                <p>
                  Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes
                  sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este
                  contrato harán pasible a su/s responsable/s de las acciones legales pertinentes, y
                  a las sanciones previstas por este acuerdo, así como lo hará responsable de
                  indemnizar los daños ocasionados.{' '}
                </p>
                <h6>XX. ¿Cuáles son las condiciones de propiedad intelectual del Sitio? </h6>
                <p>
                  Mundos E y/o sus sociedades controlantes, controladas, filiales o subsidiarias se
                  reservan todos los derechos, incluyendo los derechos de propiedad intelectual e
                  industrial, asociados con los servicios de Mundos E, sus sitios web, los
                  contenidos de sus pantallas, programas, bases de datos, redes, códigos,
                  desarrollo, software, arquitectura, hardware, contenidos, información, tecnología,
                  fases de integración, funcionalidades, dominios, archivos que permiten al Usuario
                  acceder y crear su Cuenta, herramientas, marcas, patentes, derechos de autor,
                  diseños y modelos industriales, nombres comerciales, entre otros, y declara que
                  están protegidos por leyes nacionales e internacionales vigentes.
                </p>
                <p>
                  En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los
                  mismos excepto para utilizar los servicios de Mundos E conforme a lo previsto en
                  estos Términos y Condiciones Generales. El uso indebido o contrario a la normativa
                  vigente de los derechos de propiedad intelectual e industrial de Mundos E, así
                  como su reproducción total o parcial, queda prohibido, salvo autorización expresa
                  y por escrito de Mundos E.
                </p>
                <h6>XXII. Jurisdicción y Ley Aplicable </h6>
                <p>
                  Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la
                  República Argentina.
                </p>
                <p>
                  Cualquier controversia derivada del presente acuerdo, su existencia, validez,
                  interpretación, alcance o cumplimiento, será sometida ante la Justicia Nacional
                  Ordinaria de la Ciudad Autónoma de Buenos Aires.{' '}
                </p>
                <h6>XXIII. Domicilio </h6>
                <p>
                  Se fija como domicilio de <strong> TRANSFORMARME S.A.S.</strong> la Avenida
                  Córdoba 785, piso 6, oficina 12, de la Ciudad Autónoma de Buenos Aires, código
                  postal 1054, República Argentina.{' '}
                </p>
                <p>
                  <strong>
                    CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS
                    CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE
                    UTILIZAR EL SITIO Y/O LOS SERVICIOS.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
