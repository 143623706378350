import { toTop } from '@/utils/toTop';
import React, { useEffect } from 'react';
import { Thumbnail } from './Thumbnail';

export const Loader = () => {
  const loaderArray = [0, 1, 2];

  useEffect(() => toTop(), []);

  return (
    <>
      <section className="courses_hero d-flex align-items-center py-5">
        <div className="container">
          <div className="row">
            <ul className="nav nav-tabs col-md-7 d-none" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  //   className={'nav-link ' + (modeSelection == 'online' ? 'active' : '')}
                  id="online-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#online"
                  type="button"
                  role="tab"
                  aria-controls="online"
                  aria-selected="true">
                  Modalidad Online
                </button>
              </li>
            </ul>
            <div className="categorias_selector col-md-5 position-relative">
              <button
                className="d-block btn d-flex justify-content-between align-items-center"
                type="button"
                aria-label="Toggle navigation">
                Cargando...
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="courses_list container">
        <div className="row py-5">
          {loaderArray.map(i => {
            return <Thumbnail key={i} empty />;
          })}
        </div>
      </section>
    </>
  );
};
