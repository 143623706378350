import { Inquiry } from '@/pages/Inquiry';
import { env } from '@/utils';
import { Route, Routes } from 'react-router-dom';
import { InquiryForm } from '../InquiryForm';
import { ThanksPage } from '../ThanksPage';

export const InquiryRoutes = () => {
  // TODO: remove env development
  return (
    <Routes>
      <>
        <Route index element={<Inquiry />} />
        <Route path="opciones" element={<InquiryForm />} />
        <Route path="gracias" element={<ThanksPage />} />
      </>
    </Routes>
  );
};
