import { User } from '@/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface purchaseState {
  isLoading: boolean;
  user: User;
  billingData: User;
  userDataChecked: boolean;
  error: any;
  activateUser: boolean;
}

const INITIAL_STATE: purchaseState = {
  isLoading: false,
  user: {} as User,
  billingData: {} as User,
  userDataChecked: false,
  error: null,
  activateUser: false,
};

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: INITIAL_STATE,
  reducers: {
    setLoadingPurchase: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    setUserData: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.user = action.payload;
      } else {
        state.user = {} as User;
      }
    },
    setBillingData: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.billingData = action.payload;
      } else {
        state.billingData = {} as User;
      }
    },
    setActivateUser: (state, action: PayloadAction<any>) => {
      state.activateUser = action.payload;
    },
    setChecked: (state, action: PayloadAction<any>) => {
      state.userDataChecked = action.payload;
    },
    setErrorFetch: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  setLoadingPurchase,
  setUserData,
  setBillingData,
  setActivateUser,
  setChecked,
  setErrorFetch,
} = purchaseSlice.actions;
