import { Action, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { setActive, setError, setLoadingActions, setUser, startLoadingUser } from './user';
import {
  clearActivateToken,
  clearMoodleToken,
  clearPurchase,
  clearToken,
  clearUserEmail,
  getActivateToken,
  getLoginMoodleKey,
  storeMoodleToken,
  storeToken,
  storeUserEmail,
} from '@/utils';
import { getUser, login, moodleLogin, activate } from '@/features/authentication';
import { User } from '@/interfaces';
import { updateUser } from '@/features/user';
import { setBillingData, setUserData } from '../purchase';

import CryptoJS from 'crypto-js';

export const getActiveUser = (mail: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingUser());
    try {
      const response = await getUser(mail);
      if (response.data.error) throw Error('Invalid mail');
      dispatch(setUser(response.data));
    } catch (error) {
      dispatch(setUser(null));
      console.log(error);
    }
  };
};

export const activateUser = (token: string) => {
  clearToken();
  clearMoodleToken();
  clearUserEmail();
  clearActivateToken();
  return async (dispatch: Dispatch) => {
    dispatch(setLoadingActions(true));
    dispatch(setUser(null));
    dispatch(setActive(true));
    try {
      const response = await activate(token);
      if (response.data.error) throw Error('Invalid token');
      const userInfo = await getUser(response.data.mail);
      storeUserEmail(response.data.mail);
      storeToken('activated');
      dispatch(setUser(userInfo.data));
      dispatch(setLoadingActions(false));
      return response.data;
    } catch (error) {
      dispatch(setError('Algo salió mal! Intente de nuevo más tarde'));
      dispatch(setUser(null));
      throw new Error('Ocurrió un error');
    }
  };
};

export const logout = () => {
  clearToken();
  clearMoodleToken();
  clearUserEmail();
  clearPurchase('userPurchase');
  clearPurchase('billPurchase');
  return async (dispatch: ThunkDispatch<any, void, Action>) => {
    dispatch(setUser(null));
    dispatch(setActive(false));
    dispatch(setUserData(null));
    dispatch(setBillingData(null));
  };
};

export const signin = (user: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await login(user, password);
      if (response.data.error) throw Error('Invalid user');
      else {
        const preLoginResponse = await moodleLogin(user, password, response.data.token);
        const userInfo = await getUser(user);
        if (preLoginResponse.data.error) throw Error('Invalid user');
        storeMoodleToken(preLoginResponse.data.userkey);
        storeToken(response.data.token);
        storeUserEmail(response.data.user.mail);
        dispatch(setUser(userInfo.data));
        return userInfo.data;
      }
    } catch (error) {
      dispatch(setUser(null));
      return { error: error };
    }
  };
};

export const updateProfileUser = (user: User, attributes: string[]) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await updateUser({ user, attributes });
      if (response.data.error) throw Error('Ocurrió un error en la actualización de datos');
      const userInfo = await getUser(response.data.mail);
      if (!userInfo.data.error) dispatch(setUser(userInfo.data));
      return userInfo.data;
    } catch (error) {
      return { error: error };
    }
  };
};

export const userActions = (user: User, activation: boolean, attributes: string[]) => {
  const token = getActivateToken();
  const loginKey = getLoginMoodleKey();
  const _loginKey =
    loginKey && CryptoJS.AES.decrypt(loginKey, 'secret key').toString(CryptoJS.enc.Utf8);
  return async (dispatch: ThunkDispatch<any, void, Action>) => {
    dispatch(setLoadingActions(true));
    try {
      if (token && !activation) {
        const active = await dispatch(activateUser(token));
        if (active.error) {
          throw Error('Ocurrió un error en la activación');
        } else {
          const login = await dispatch(signin(user.mail, _loginKey));
          if (login.error) throw Error('Invalid user');
        }
      }

      const updateProfile = await dispatch(updateProfileUser(user, attributes));

      dispatch(setLoadingActions(false));

      return updateProfile;
    } catch (error) {
      return { error: error };
    }
  };
};
