import * as React from 'react';
import { Card } from '@/features/user';
import { toTop } from '@/utils';

export const Settings: React.FC = () => {
  React.useEffect(() => toTop(), []);

  return (
    <div>
      <main className="myProfile settings">
        <header className="lighter-grey_bg shadow-2 mb-5">
          <div className="container">
            <div className="row py-4">
              <h1 className="m-0 py-2">
                <em>
                  <img src={'/img/Icon_configuracion.svg'} alt={''} />
                </em>{' '}
                Configuración
              </h1>
            </div>
          </div>
        </header>
        <Card />
      </main>
    </div>
  );
};
