import * as React from 'react';

import { toTop } from '@/utils';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Certificate, CertificateResponse, MyCertificate } from '@/features/user';
import { getMyCertificates } from '@/store/slices/userCertificates';

export const MyCertificates: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const { certificates, isLoading } = useAppSelector(state => state.myCertificates);

  const getCertificates = () => {
    if (user.id) {
      dispatch(getMyCertificates(user?.nrodoc, user?.tipodoc, user?.mail));
      toTop();
    }
  };

  React.useEffect(() => {
    getCertificates();
  }, [user]);

  if (isLoading || !certificates.certificados) {
    return (
      <main className="myCertificates">
        <header className="lighter-grey_bg shadow-2">
          <div className="container">
            <div className="row py-4">
              <h1 className="m-0 py-2 px-0">
                <em>
                  <img src={'/img/iconMisCertificados.svg'} alt={''} />
                </em>{' '}
                CARGANDO...
              </h1>
            </div>
          </div>
        </header>
        <section className="myCertificates_list">
          <div className="container py-4">
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <div className="row d-flex align-items-sm-stretch">
                  <MyCertificate certificate={null} />
                  <MyCertificate certificate={null} />
                  <MyCertificate certificate={null} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }

  return (
    <main className="myCertificates">
      <header className="lighter-grey_bg shadow-2">
        <div className="container">
          <div className="row py-4">
            <h1 className="m-0 py-2 px-0">
              <em>
                <img src={'/img/iconMisCertificados.svg'} alt={''} />
              </em>{' '}
              Mis Certificados
            </h1>
          </div>
        </div>
      </header>

      <section className="myCertificates_list">
        <div className="container py-4">
          <div className="row d-flex justify-content-center">
            <div className="col-10">
              <div className="row d-flex align-items-sm-stretch">
                {certificates?.certificados.length === 0 && (
                  <div
                    style={{
                      width: '100%',
                      height: '400px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <h1>No se encontraron certificados</h1>
                  </div>
                )}
                {certificates?.certificados?.map((certificate: Certificate) => (
                  <MyCertificate key={certificate.codigo_curso} certificate={certificate} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
