export const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <div className="callout warning d-flex">
      <div className="content">
        <figure>
          <i className="fas fa-exclamation"> </i>
        </figure>
      </div>
      <div className="content">
        <p> {message} </p>
      </div>
    </div>
  );
};
