import { Layout } from '@/features/purchase';
// eslint-disable-next-line no-restricted-imports
import '@/features/purchase/Purchase.css';
import { Outlet } from 'react-router-dom';

export const Purchase = () => {
  return (
    <div>
      <main className="purchase">
        <div className="container">
          <Layout>
            <Outlet />
          </Layout>
        </div>
      </main>
    </div>
  );
};
