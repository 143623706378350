import React from 'react';

export const LearnWithUs = () => {
  return (
    <section className="aprender-con-nosotros container-fluid py-100">
      <div className="container">
        <div className="row mb-5">
          <h2>Aprender con nosotros</h2>
        </div>
        <div className="row">
          <article className="col-lg-4 pe-5">
            <h4 className="mb-4 bold">
              <img
                src="/img/home-new/icono1.png"
                alt="Simple y versátil"
                width="25"
                className="me-3"
              />
              Es simple y versátil
            </h4>
            <p className={'m-0'}>
              Viví una experiencia de aprendizaje flexible basado en lo práctico y vivencial.
            </p>
            <p className={'m-0'}>
              Manteniendo una dinámica de trabajo en equipo con tu entrenador y compañeros, para
              desarrollar nuevas ideas y avanzar con tus objetivos.
            </p>
          </article>
          <article className="col-lg-4 pe-5">
            <h4 className="mb-4 bold">
              <img
                src="/img/home-new/icono2.png"
                alt="Pensado para vos"
                width="25"
                className="me-3"
              />
              Pensado para vos
            </h4>
            <p className={'m-0'}>
              La flexibilidad que ofrecen nuestras modalidades de cursado, te permiten adaptar tu
              aprendizaje a tu ritmo de vida. La comodidad de aprender desde donde quieras,
              manteniendo la cercanía con entrenadores y compañeros.
            </p>
          </article>
          <article className="col-lg-4 pe-5">
            <h4 className="mb-4 bold">
              <img src="/img/home-new/icono3.png" alt="Bonus track" width="25" className="me-3" />
              Bonus track
            </h4>
            <p className={'m-0'}>
              Comunidad de aprendizaje internacional: te vas a relacionar con colegas de distintos
              países de iberoamérica.
            </p>
            <p className={'m-0'}>
              Acceso al material de por vida en nuestra plataforma para que puedas reforzar el
              aprendizaje cuando quieras.
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};
