import { Field, Formik, Form as FormFormik } from 'formik';
import * as Yup from 'yup';
import { InquiryDataProps, Option, Props } from '../interfaces';
import { useState, useEffect } from 'react';
import { sendMail } from '../services/sendMail';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@/components/errorMessage';

type FormValues = {
  inquiryOptions: Array<any>;
  message: string;
};

const inquirySchema = Yup.object().shape({
  message: Yup.string().required('El mensaje es querido'),
  inquiryOptions: Yup.string().required('La opción es requerida'),
});

export const Form = ({ inquiry: { inquiry } }: Props) => {
  const navigate = useNavigate();
  const [inquiryToSend, setInquiryToSend] = useState<InquiryDataProps>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => setInquiryToSend(inquiry), [inquiry]);

  const handleSubmit = async (values: FormValues) => {
    const find = inquiry?.inquiryType?.options?.find(
      (option: { description: never[] }) => option.description === values.inquiryOptions
    );

    const inquiryData = {
      asunto: find.description,
      nombre: inquiryToSend?.user?.name,
      apellido: inquiryToSend?.user?.surname,
      email: inquiryToSend?.user?.mail,
      area: inquiryToSend?.inquiryType?.description,
      prioridad: 'Normal - 72hs',
      descripcion: values.message,
    };

    try {
      const response = await sendMail(inquiryData);
      if (response.ticket_no) {
        navigate('/consultas/gracias');
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Formik
      initialValues={{
        inquiryOptions: [],
        message: '',
      }}
      validationSchema={inquirySchema}
      onSubmit={handleSubmit}>
      {({ values, handleChange, isSubmitting }) => (
        <FormFormik className="w-100">
          {error && <ErrorMessage message="Algo salió mal! Intente de nuevo más tarde" />}
          <div className="row">
            <div className="col-sm-10 option-list-checkbox">
              <div
                className="btn-group-vertical w-100"
                role="group"
                aria-label="Basic radio toggle button group">
                {inquiry.inquiryType.options.map((option: Option) => (
                  <div key={'option_' + option.id} className="d-flex mb-3 w-100">
                    <Field
                      name={'inquiryOptions'}
                      type="radio"
                      value={option.description}
                      className="btn-check"
                      id={'btnradio' + option.id}
                      autoComplete="off"
                    />
                    <label className="btn" htmlFor={'btnradio' + option.id}>
                      <i className="fas fa-check checkboxIcon"> </i>
                      {option.description}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-element">
              <p className="mt-4">En qué podemos ayudarte, dejanos tu consulta</p>
              <div className="form-element ">
                <Field
                  as="textarea"
                  name="message"
                  className="form-control"
                  id="mensaje_consulta "
                  placeholder="Escribí tu consulta"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-end mt-3">
            <div className="col-12 d-flex justify-content-end">
              <button
                //   onClick={e => {
                //     verify(location.state, e).then(r => {});
                //   }}
                disabled={
                  values.message.length === 0 || values.inquiryOptions.length === 0 || isSubmitting
                    ? true
                    : false
                }
                type="submit"
                className="btn primary-btn btn-rounded red_bg white">
                Enviar consulta
              </button>
            </div>
          </div>
        </FormFormik>
      )}
    </Formik>
  );
};
