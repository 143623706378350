import { axios } from '@/lib/axios';

export const moodleLogin = (user: string, password: string, token: string) => {
  return axios.post('/moodle/prelogin', {
    wstoken: token,
    wsfunction: 'wsreceptor_get_user_prelogin',
    username: user,
    password: password,
  });
};
