import * as React from 'react';
import moment from 'dayjs';

import { CourseResponse } from '@/features/courses';
import { getMoodleToken, env } from '@/utils';

const mode = {
  online: 'MODALIDAD ONLINE EN VIVO',
  ondemand: 'MODALIDAD ONDEMAND',
};

const status = {
  A: { label: 'INICIAR CURSADO', variant: 'red' },
  B: { label: 'INICIAR CURSADO', variant: 'red' },
  C: { label: 'CONTINUAR CURSADO', variant: 'blue' },
  D: { label: 'VOLVER A CURSAR', variant: 'green' },
  Z: { label: 'CARGANDO', variant: '' },
};

export const MyProgram: React.FC<{ program: CourseResponse | null }> = ({ program }) => {
  const timestamp = program?.start;
  const formatted = moment(timestamp).format('DD/MM/YYYY');
  const value = Math.round(program?.progreso);

  const usertoken = getMoodleToken();

  const pluginUrl = `/local/receptor/enter.php?usertoken=${usertoken}&courseid=${program?.id}`;

  const baseUrl =
    env === 'production'
      ? `https://edu.mundose.com:8443/${pluginUrl}`
      : `http://desa.mundose.com:8087/${pluginUrl}`;

  const handleLink = () => (window.location.href = baseUrl);

  const disabledButton = !program?.estado || program?.estado === 'A' || program?.estado === 'Z';

  return program ? (
    <article className="col-sm-4 course_thumb">
      <div className="shadow-sm rounded  d-flex flex-column flex-grow-1">
        <figure className="mb-4 d-flex flex-column flex-grow-1">
          <img
            src={program?.image && 'data:image/png;base64,' + program?.image?.data}
            alt={program?.fullname}
            className="img-fluid w-100 "
          />
          <figcaption className="pt-2 px-3 d-flex flex-column justify-content-between flex-grow-1">
            <div>
              <h4 className="mb-2 overline">
                {program.mode !== 'ondemand' ? mode.online : mode.ondemand}
              </h4>
              <h3 className="mb-3 grey">{program?.fullname}</h3>
            </div>
            <button
              className="btn primary-btn btn-rounded red_bg white align-self-center mt-auto"
              disabled={disabledButton}
              onClick={() => handleLink()}>
              {program.estado ? status[program.estado as string].label : status.A.label}
            </button>
          </figcaption>
        </figure>
        <footer className="lighter-grey_bg p-3 w-100 m-auto mb-0">
          <div className="d-flex flex-end align-items-center">
            {/* {program?.mode === 'online' ? (
              <p className="text-start light-grey mb-0">Inicia {formatted}</p>
            ) : null} */}
            {/* <p className="text-start light-grey mb-2">Inicia</p> */}
            {/* <p className="text-end light-grey mb-2">0 de 32 Encuentros</p> */}
          </div>
          <div className={`progress ${status[program.estado as string].variant}`}>
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={value}
              style={{ width: `${value === 0 ? 10 : value}%` }}>
              {`${value}%`}
            </div>
          </div>
        </footer>
      </div>
    </article>
  ) : (
    <article className="col-sm-4 course_thumb">
      <div className="shadow-sm rounded  d-flex flex-column flex-grow-1">
        <figure className="mb-4 d-flex flex-column flex-grow-1">
          <img src={'/img/shimmerLoad.gif'} alt={'cargando...'} className="img-fluid w-100 " />
          <figcaption className="pt-2 px-3 d-flex flex-column justify-content-between flex-grow-1">
            <div>
              <h4 className="mb-2 overline"> </h4>
              <h3 className="mb-3 grey"> </h3>
            </div>
            <button
              className="btn primary-btn btn-rounded red_bg white align-self-center mt-auto"
              disabled={true}>
              {status.Z.label}
            </button>
          </figcaption>
        </figure>
      </div>
    </article>
  );
};
