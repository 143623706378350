import * as React from 'react';
import {
  EducationLevelType,
  KnowledgeAreaType,
  OcupationType,
  PersonnelInChargeType,
} from '@/features/user';
import { SelectComponent, TextInput } from '@/components/form';
import { InputContainer } from '@/features/purchase';

type FormProps = {
  handleKeys: (keys: any) => void;
  formik: any;
};

export const EditEducationData = ({ handleKeys, formik }: FormProps) => {
  function handleChange(att: string, e) {
    handleKeys([att]);
    formik.handleChange(e);
  }

  return (
    <div className="card datos-formacion mb-5">
      <div className="card-body p-5">
        <div className="row mb-5">
          <div className="col">
            <h3 className="card-title mb-4">Formación y Experiencia</h3>
            <p className="card-subtitle mb-4">
              Tu formación nos permite conocerte para poder ofrecerte programas acordes a tu
              necesidad.
            </p>
            <p className="card-subtitle">Campos obligatorios</p>
          </div>
        </div>
        <div className="row mb-4">
          <label htmlFor="educationLevel" className="form-label">
            Nivel de formación *
          </label>
          <SelectComponent
            formik={formik}
            handleKeys={handleKeys}
            options={EducationLevelType.map(({ label, value }) => ({ value: value, label: label }))}
            placeholder={'Nivel de formación'}
            att={'educationLevel'}
          />
        </div>
        <div className="row mb-4">
          <label htmlFor="knowledgeArea" className="form-label">
            Área de conocimiento *
          </label>
          <SelectComponent
            formik={formik}
            handleKeys={handleKeys}
            options={KnowledgeAreaType.map(({ label, value }) => ({ value: value, label: label }))}
            placeholder={'Área de conocimiento'}
            att={'knowledgeArea'}
          />
        </div>
        <div className="row mb-4">
          <label htmlFor="occupation" className="form-label">
            Ocupación o Cargo *
          </label>
          <SelectComponent
            formik={formik}
            handleKeys={handleKeys}
            options={OcupationType.map(({ label, value }) => ({ value: value, label: label }))}
            placeholder={'Ocupación o Cargo'}
            att={'occupation'}
          />
        </div>
        <div className="row mb-4">
          <label htmlFor="personnelInCharge" className="form-label">
            Personal a Cargo *
          </label>
          <SelectComponent
            formik={formik}
            handleKeys={handleKeys}
            options={PersonnelInChargeType.map(({ label, value }) => ({
              value: value,
              label: label,
            }))}
            placeholder={'Personal a Cargo'}
            att={'personnelInCharge'}
          />
        </div>
        <div className="row mb-4">
          <InputContainer
            profileSize
            errors={formik.errors.empresa}
            touched={formik.touched.empresa}>
            <TextInput
              label="Empresa donde trabaja "
              name="empresa"
              onChange={e => handleChange('user.empresa', e)}
              placeholder="Nombre donde trabaja"
            />
          </InputContainer>
        </div>

        <p className="text-end mt-2">
          <button type="submit" className="btn primary-btn btn-rounded red_bg white btn-small">
            Guardar cambios
          </button>
        </p>
      </div>
    </div>
  );
};
