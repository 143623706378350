import { User } from '@/interfaces';
import { Cookies } from 'react-cookie';

export function storeToken(token: string) {
  const cookies = new Cookies();
  cookies.set('token', token, { path: '/' });
}

export function getToken() {
  const cookies = new Cookies();
  return cookies.get('token');
}

export function clearToken() {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
}

export function storeUserEmail(mail: string) {
  const cookies = new Cookies();
  cookies.set('mail', mail, { path: '/' });
}

export function getUserEmail() {
  const cookies = new Cookies();
  return cookies.get('mail');
}

export function clearUserEmail() {
  const cookies = new Cookies();
  cookies.remove('mail', { path: '/' });
}

export function storeMoodleToken(wsreceptor: string) {
  const cookies = new Cookies();
  cookies.set('wsreceptor', wsreceptor, { path: '/' });
}

export function getMoodleToken() {
  const cookies = new Cookies();
  return cookies.get('wsreceptor');
}

export function clearMoodleToken() {
  const cookies = new Cookies();
  cookies.set('wsreceptor', '', { path: '/' });
}

export function purchase(key: string, value: User) {
  const cookies = new Cookies();
  cookies.set(key, value, { path: '/' });
}

export function getPurchase(key: string) {
  const cookies = new Cookies();
  return cookies.get(key) || {};
}

export function clearPurchase(key: string) {
  const cookies = new Cookies();
  cookies.remove(key, { path: '/' });
}

export function activateToken(token: string) {
  const cookies = new Cookies();
  cookies.set('activateToken', token, { path: '/' });
}

export function getActivateToken() {
  const cookies = new Cookies();
  return cookies.get('activateToken');
}

export function clearActivateToken() {
  const cookies = new Cookies();
  cookies.remove('activateToken', { path: '/' });
}

export function loginMoodleKey(secret: string) {
  const cookies = new Cookies();
  cookies.set('key', secret, { path: '/' });
}

export function getLoginMoodleKey() {
  const cookies = new Cookies();
  return cookies.get('key');
}

export function clearLoginMoodleKey() {
  const cookies = new Cookies();
  cookies.remove('key', { path: '/' });
}
