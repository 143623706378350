import React, { useState, useEffect } from 'react';
import { useAppSelector } from '@/store/hooks';

export const useUserLogged = () => {
  const { user } = useAppSelector(state => state.user);
  const [logged, setLogged] = useState<boolean>(false);

  useEffect(() => {
    setLogged(user.mail != null && user.mail != '');
  }, [user]);

  return {
    logged,
  };
};
