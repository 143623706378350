import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '@/features/user';
import { storeUserEmail } from '@/utils';

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await resetPassword(email);
    if (!response.data.error) {
      storeUserEmail(email);
      navigate('/confirmacion-contrasena', { replace: true, state: location.state });
    } else {
      setError('El correo electrónico que ingresó no esta asociado a una cuenta.');
    }
  };

  return (
    <div className="resetPassword">
      <div className="form-wrapper d-flex align-items-center flex-column">
        <h4 className="mb-2 text-center">¿Te olvidaste la contraseña?</h4>
        <p className="mb-2 text-center">
          Ingresá la dirección de correo electrónico asociado a tu cuenta para enviar un enlace y
          recuperar la contraseña.
        </p>
        <form onSubmit={e => handleSubmit(e)} className="d-flex flex-column align-items-center">
          <div className={`mb-4 position-relative w-100 ${error === '' ? '' : 'error'}`}>
            <label className="form-label" htmlFor={'value.mail'}>
              Correo electrónico*
            </label>
            <input
              name="value.mail"
              id="value.mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              className="form-control"
            />
          </div>
          {error !== '' && (
            <div className="callout warning d-flex">
              <div className="content">
                <figure>
                  <i className="fas fa-exclamation"> </i>
                </figure>
              </div>
              <div className="content d-flex align-items-center">
                <p> {error} </p>
              </div>
            </div>
          )}
          <button type="submit" className="btn primary-btn btn-rounded red_bg white">
            Recuperar contraseña
          </button>
        </form>
      </div>
    </div>
  );
};
