import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import { Spinner } from 'react-bootstrap';

import { FormValues, inquiryCourseSchema, sendCourseInquiry, InputContainer } from '../index';
import { FlagsSelectInput, PhoneInputField, TextAreaInput, TextInput } from '@/components/form';
import { getCodeCountryByCodeName, getCodeCountryByCodeNumber } from '@/utils/codeCountryNumber';
import { useAppSelector } from '@/store/hooks';

export const CouseInquiryForm = () => {
  const { course } = useAppSelector(state => state.course);
  const { user } = useAppSelector(state => state.user);

  const navigate = useNavigate();

  const initialValues: FormValues = {
    user: {
      name: user?.name || '',
      surname: user?.surname || '',
      mail: user?.mail || '',
      country: user?.country || '',
      phone: {
        number: user?.phone?.number || '',
        countryCode: getCodeCountryByCodeNumber(user?.phone?.countryCode).name || '',
        area: user?.phone?.area || '',
      },
    },
    message: '',
    course: course?.shortname,
  };

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const valuesToSend = {
      ...values,
      user: {
        ...values.user,
        phone: {
          number: `${getCodeCountryByCodeName(values.user.phone.countryCode)?.codeNumber} ${
            values.user.phone.area
          } ${values.user.phone.number}`,
        },
      },
    };

    const response = await sendCourseInquiry(valuesToSend);
    if (!response.error) {
      navigate(`/programas/${course.id}/consulta/gracias`);
    }
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={inquiryCourseSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}>
      {({ errors, touched, isSubmitting, isValidating }) => (
        <Form>
          <div className="row">
            <InputContainer errors={errors.user?.name} touched={touched.user?.name}>
              <TextInput label="Ingresá tu Nombre *" name="user.name" placeholder="Nombre" />
            </InputContainer>
            <InputContainer errors={errors.user?.surname} touched={touched.user?.surname}>
              <TextInput label="Ingresá tu Apellido *" name="user.surname" placeholder="Apellido" />
            </InputContainer>
          </div>
          <div className="row">
            <InputContainer errors={errors.user?.country} touched={touched.user?.country}>
              <FlagsSelectInput
                label={'País de Residencia *'}
                placeholder={'Seleccionar país'}
                name="user.country"
              />
            </InputContainer>
            <InputContainer errors={errors.user?.mail} touched={touched.user?.mail}>
              <TextInput
                label="Correo electrónico *"
                name="user.mail"
                placeholder="Ingresá tu correo electrónico"
                type="email"
              />
            </InputContainer>
          </div>
          <div className="my-3 d-flex justify-content-between w-100">
            <h4 className="phone-data">Datos de contacto telefónico</h4>
            <div className="phone-data-border"></div>
          </div>
          <div className="row mb-2">
            <InputContainer
              touched={touched.user?.phone?.countryCode}
              errors={errors.user?.phone?.countryCode}>
              <PhoneInputField
                name="user.phone.countryCode"
                label="Teléfono *"
                codeCountry={user?.country?.toLocaleLowerCase()}
              />
            </InputContainer>
            <div className="col-md-6 ">
              <div className="row">
                <InputContainer
                  errors={errors.user?.phone?.area}
                  touched={touched.user?.phone?.area}>
                  <TextInput
                    label="Código Area *"
                    name="user.phone.area"
                    placeholder="Ej.: 11"
                    type="number"
                  />
                </InputContainer>
                <InputContainer
                  errors={errors.user?.phone?.number}
                  touched={touched.user?.phone?.number}>
                  <TextInput
                    label="Telefono *"
                    name="user.phone.number"
                    placeholder="Ej.:123456"
                    type="number"
                  />
                </InputContainer>
              </div>
            </div>
          </div>
          <div className="row">
            <InputContainer errors={errors.message} touched={touched.message} colSize={'col-sm-12'}>
              <TextAreaInput
                label="¿En qué podemos ayudarte? *"
                name="message"
                placeholder="Escribí tu consulta…"
              />
            </InputContainer>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button className="btn btn-rounded mt-4 cancelbutton" onClick={() => navigate(-1)}>
              CANCELAR
            </button>
            <button
              type="submit"
              className="btn primary-btn btn-rounded red_bg white mt-4 d-flex align-items-center justify-content-center formbutton"
              disabled={isSubmitting && !isValidating}>
              {isSubmitting && !isValidating ? (
                <Spinner animation="border" />
              ) : (
                <span>Enviar Consulta</span>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
