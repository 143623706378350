import { useState } from 'react';
import { Modal as BootModal, Spinner } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useNavigate } from 'react-router-dom';
import { getPayment } from '@/store/slices';
import { setUserEnroll } from '../services';
import { getObjectKeys } from '@/utils';
import { userActions } from '@/store/slices/user';
interface OrderResponse {
  id: string;
  status: 'COMPLETED' | 'SAVED' | 'APPROVED' | 'VOIDED' | 'PAYER_ACTION_REQUIRED';
  processing_instruction?: string | undefined;
}

type PayPalProps = {
  dirty: boolean;
  errors: any;
  price: string;
};

export const Paypal = ({ dirty, errors, price, ...props }: PayPalProps) => {
  const { course } = useAppSelector(state => state.course);
  const { activation, loadingActions, user } = useAppSelector(state => state.user);
  const { user: userPurchase } = useAppSelector(state => state.purchase);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const orderToPay = {
    name: course.shortname,
    unit_amount: {
      value: price.split(',').join('.'),
      currency_code: 'USD',
    },
    quantity: '1',
    sku: userPurchase.mail,
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const userActionsPurchase = async () => {
    const formatUser = userPurchase;
    const { mail, password, nrodoc: nroDoc, ...rest } = formatUser;
    const renamedProps = { nroDoc, ...rest };
    const attributes = getObjectKeys({ user: renamedProps });

    const response = await dispatch(userActions(userPurchase, activation, attributes));
    if (response.error) {
      navigate(`/programas/${course.id}/compra/error`, { replace: true });
    } else {
      setShow(true);
    }
  };

  const onOrderCompleted = async (details: OrderResponse) => {
    if (details.status !== 'COMPLETED') {
      return;
    }
    dispatch(
      getPayment({
        payment: details.id,
      })
    );
    handleClose();
    navigate(`/programas/${course.id}/compra/gracias`);
  };

  const handleShow = e => {
    e.preventDefault();
    userActionsPurchase();
  };

  return (
    <>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
        <button
          className="btn btn-rounded back me-md-2"
          disabled={loadingActions}
          onClick={() => navigate(-1)}>
          VOLVER
        </button>
        <button
          className="btn primary-btn btn-rounded red_bg white d-flex align-items-center justify-content-center payment-button"
          disabled={!dirty || errors.terms || errors.validateData}
          onClick={handleShow}>
          {loadingActions ? (
            <Spinner animation="border" />
          ) : (
            <span className="payment-text">IR A PAGAR</span>
          )}
        </button>
      </div>
      <BootModal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <BootModal.Header>
          <BootModal.Title>{'Pagar con PayPal'}</BootModal.Title>
          <button
            onClick={() => handleClose()}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </BootModal.Header>
        <BootModal.Body>
          <>
            {
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: orderToPay.unit_amount.value,
                          currency_code: 'USD',
                          breakdown: {
                            item_total: {
                              currency_code: 'USD',
                              value: orderToPay.unit_amount.value,
                            },
                          },
                        },
                        items: [orderToPay],
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order?.capture().then(details => {
                    onOrderCompleted(details);
                  });
                }}
              />
            }
          </>
        </BootModal.Body>
      </BootModal>
    </>
  );
};
