import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { activateUser } from '@/store/slices/user';
import { Modal } from '@/components/modal';
import { Home } from '@/pages';
import { getToken } from '@/utils';

export const Activated = () => {
  const { token } = useParams();
  const { user, activation, loadingActions, error } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  const activated = getToken();

  React.useEffect(() => {
    if (!user.active && token && !activation && !activated) {
      dispatch(activateUser(token));
    }
  }, [user]);

  return (
    <>
      <Modal title={'Activación de cuenta'}>
        <div className="form-wrapper">
          <div className="modal-body px-5 d-flex flex-column align-items-center">
            {loadingActions && <Spinner animation={'border'} />}
            {!loadingActions && user.active && (
              <>
                <figure>
                  <img
                    src={'/img/bienvenida.svg'}
                    width="150"
                    alt="Bienvenida a nuestra comunidad"
                  />
                </figure>
                <h2 className="my-3 text-center">Bienvenida a nuestra comunidad</h2>
                <p className="mb-3 light-grey text-center">
                  Completa tu perfil y accede a nuestros descuentos.
                </p>
                <Link
                  to={'/usuario/perfil'}
                  className="btn primary-btn btn-rounded red_bg white mt-2">
                  Continuar
                </Link>
              </>
            )}

            {!loadingActions && error && <p className="my-3 text-center">{error}</p>}
          </div>
        </div>
      </Modal>
      <Home />
    </>
  );
};
