import { User } from '@/interfaces';
import { axios } from '@/lib/axios';

type UpdateUserDTO = {
  user: User;
  attributes: string[];
};

export const updateUser = (data: UpdateUserDTO) => {
  return axios.post('/user/update', data);
};
