import { CourseResponse } from '@/features/courses';
import { axios } from '@/lib/axios';
import { Dispatch } from '@reduxjs/toolkit';

import { setCourses, startLoadingCourses } from './courses';

export const getCourses = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingCourses());
    // TODO: fetch courses from API
    try {
      const response = await axios.post<CourseResponse[]>('/course/find_by_mode_category', {});

      dispatch(setCourses(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
