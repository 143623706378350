import React from 'react';
import { User } from '@/interfaces';
import { toTop } from '@/utils';

type StateProps = {
  user: User;
  setEdit: React.Dispatch<React.SetStateAction<string>>;
};

export const ViewEducationData: React.FC<StateProps> = ({ user, setEdit }) => {
  return (
    <div className="card datos-formacion mb-5">
      <div className="card-body p-5">
        <div className="row mb-5">
          <div className="col">
            <h3 className="card-title mb-4">Formación y Experiencia</h3>
            <p className="card-subtitle">
              Tu formación nos permite conocerte para poder ofrecerte programas acordes a tu
              necesidad.
            </p>
          </div>
        </div>
        <ul className="list-unstyled mb-4 data">
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Nivel de formación</h4>
            <p>{user.educationLevel}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Área de conocimiento</h4>
            <p>{user.knowledgeArea}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Ocupación o Cargo</h4>
            <p>{user.occupation}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Personal a Cargo</h4>
            <p>{user.personnelInCharge}</p>
          </li>
          <li className="py-3 d-flex justify-content-center flex-column">
            <h4 className="mb-2">Nombre de la empresa</h4>
            {user.empresa ? <p>{user.empresa}</p> : <p>{''}</p>}
          </li>
        </ul>
        <p className="text-end mt-2">
          <button
            onClick={() => {
              setEdit('education');
              toTop();
            }}
            className="btn primary-btn btn-rounded red_bg white btn-small">
            Editar datos
          </button>
        </p>
      </div>
    </div>
  );
};
