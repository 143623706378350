// eslint-disable-next-line no-restricted-imports
import '@/features/course/Course.css';
import { ClassContentResponse } from '@/features/courses';
import { useAppSelector } from '@/store/hooks';
// import { useDownload } from '../hooks';

type IconType = 'page' | 'resource' | 'assign' | 'forum' | 'quiz';

export const Structure = () => {
  const { course, content } = useAppSelector(state => state.course);
  // const { downloadFile, download } = useDownload();

  const getIcon = (urlIcon: string) => {
    const icon = urlIcon.split('/')[6];

    switch (icon) {
      case 'page':
        return 'video';
      case 'resource':
        return 'descarga';
      case 'quiz':
        return 'trabajo_practico';
      default:
        return 'video';
    }
  };

  return (
    <section className="que_incluye_el_programa shadow-border py-5">
      <div className="py-md-5 py-0 container">
        <div className="row">
          <h2 className="mb-3">¿Qué incluye este programa?</h2>
          <p className="mb-5">
            {course?.structure}
            {/*Esta certificación fue desarrollada bajo el formato On Demand para que puedas recorrerla*/}
            {/*y disfrutarla a tu ritmo y organizando tus tiempos. Los módulos comienzan con contenidos*/}
            {/*teóricos que brindan contexto y sustento; y continúan con propuestas de ejercicios*/}
            {/*prácticos para fijar e internalizar cada uno de los aprendizajes.*/}
          </p>
          <div className="accordion" id="que-incluye-acordeon">
            {content?.length &&
              content.map(
                (item: ClassContentResponse, index) =>
                  item.modules.length > 0 && (
                    <div key={`data_${index}`} className="accordion-item mb-3">
                      <div className="accordion-header shadow-1" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}>
                          <div className="d-flex justify-content-between align-items-center flex-grow-1 pe-3">
                            <h4 className="m-0">{item.name}</h4>
                            {/* <p className="m-0 text-end">{item.modules.length} clases</p> */}
                          </div>
                        </button>
                      </div>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#que-incluye-acordeon">
                        <div className="accordion-body shadow-1 p-3">
                          <ul className="list-unstyled">
                            {item.modules.map((module, index) => (
                              <li key={`module_${index}`} className="pb-3">
                                <article className="que-incluye-list d-flex flex-grow-1 justify-content-between align-items-center pe-5">
                                  <a href="#" className="link">
                                    <img
                                      src={`/img/programa_iconos/${getIcon(module.modicon)}.svg`}
                                      alt={`${getIcon(module.modicon)}`}
                                    />{' '}
                                    {module.name}
                                  </a>
                                  {/* <p className="text-end m-0">06:16</p> */}
                                </article>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </section>
  );
};
