import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setBillingData, setPurchaseData } from '@/store/slices';
import { BillDataForm, commonSchema } from '../forms';
import { useUserLogged } from '../hooks';
import { FormValues } from '../interfaces';
import { Header } from './Header';
import { getCodeCountryByCodeName, getCodeCountryByCodeNumber } from '@/utils/codeCountryNumber';

export const BillContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logged } = useUserLogged();
  const { course } = useAppSelector(state => state.course);
  const { userDataChecked, billingData } = useAppSelector(state => state.purchase);

  const validationSchema = commonSchema;

  const codeNumberCountryUserPurchase =
    billingData?.phone?.countryCode &&
    getCodeCountryByCodeNumber(billingData?.phone?.countryCode).name;

  const initialValues: FormValues = {
    name: billingData?.name || '',
    surname: billingData?.surname || '',
    mail: billingData?.mail || '',
    tipodoc: billingData?.tipodoc || '',
    nrodoc: billingData?.nrodoc || '',
    phone: {
      countryCode: codeNumberCountryUserPurchase || getCodeCountryByCodeNumber('').name,
      area: billingData?.phone?.area || '',
      number: billingData?.phone?.number || '',
    },
    address: {
      country: billingData?.address?.country || '',
      state: billingData?.address?.state || '',
      city: billingData?.address?.city || '',
      street: billingData?.address?.street || '',
      departmentNumber: billingData?.address?.departmentNumber || '',
      streetNumber: billingData?.address?.streetNumber || '',
    },
    password: '',
  };

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const dataToSave = {
      ...values,
      phone: {
        ...values.phone,
        countryCode: getCodeCountryByCodeName(values.phone.countryCode).codeNumber,
      },
    };
    dispatch(setPurchaseData(dataToSave, setBillingData, 'billPurchase'));
    navigate(`/programas/${course.id}/compra/revisar`, { state: { newChecked: true } });

    actions.setSubmitting(false);
  };

  return (
    <>
      <Header
        title="Datos de facturación"
        action={
          'Es importante que los datos ingresados sean correctos ya que serán los que figurarán en la facturación'
        }
      />
      <BillDataForm
        initialValues={initialValues}
        schema={validationSchema}
        handleSubmit={handleSubmit}
        logged={logged}
      />
    </>
  );
};
