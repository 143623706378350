import { Schedule } from '@/features/courses';
import { useAppSelector } from '@/store/hooks';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { mode } from '../utils/mode';
require('dayjs/locale/es');

export const Schedules = () => {
  const { course } = useAppSelector(state => state.course);

  return (
    <section className="cuando-puedo-comenzar container light-grey_bg py-5">
      <header className="row">
        <h2 className="mb-5">¿Cuándo puedo comenzar?</h2>
      </header>
      <div className="row d-flex justify-content-end position-relative align-items-center">
        <div className={'col-sm-8 imagen'}>
          <figure>
            <img
              src={'/img/back_cuandoEmpezar_Online.jpg'}
              className="img-fluid"
              alt="¿Cuándo puedo comenzar?"
            />
          </figure>
        </div>

        <div className={'col-sm-6'}>
          <div className={'row py-4'}>
            {course.schedules &&
              course.schedules.map((schedule: Schedule, i) => {
                return (
                  <div key={'schedule_' + i} className="col-md-6">
                    <div className="shadow-1 p-4 pt-0 rounded-12">
                      <article className="dia-cursado d-flex">
                        <aside className="dia-icon">
                          <h6>
                            {schedule.start ? dayjs.unix(Number(schedule.start)).format('DD') : ''}
                          </h6>
                        </aside>
                        <div className="dia-info pt-4">
                          <h5 className="mb-4">
                            {schedule.start
                              ? dayjs.unix(Number(schedule.start)).locale('es').format('MMM YYYY')
                              : ''}
                          </h5>
                          <ul className="list-unstyled">
                            {schedule.times &&
                              schedule.times.map((value: string, j) => {
                                return <li key={'time_' + j}>{value}</li>;
                              })}
                          </ul>
                        </div>
                      </article>
                      <article className="semanal d-flex">
                        <aside className="semanal-info">
                          <img src="/img/icon_fecha.svg" alt="Días de cursado" />
                        </aside>
                        <div className="semana-info">
                          <h5>Días de cursado</h5>
                          {schedule.days &&
                            schedule.days.map((value: string, k) => {
                              return <p key={'day_' + k}>{value}</p>;
                            })}
                          {schedule.days.length < 1 ? <p>Por definir</p> : null}
                        </div>
                      </article>
                      <article className="cantidad d-flex">
                        <aside className="cantidad-info">
                          <img src="/img/icon_people.svg" alt="Cantidad de encuentros" />
                        </aside>
                        <div className="cantidad-info">
                          <h5>Cantidad</h5>
                          <p>{course?.encounters} encuentros</p>
                        </div>
                      </article>
                      {mode[course.mode!].showLocation && (
                        <article className="ubicacion d-flex">
                          <aside className="ubicacion-icon">
                            <h6>
                              <i className="fas fa-map-marker-alt"></i>
                            </h6>
                          </aside>
                          <div className="ubicacion-info">
                            <h5>Ubicación</h5>
                            <p>{schedule.location}</p>
                          </div>
                        </article>
                      )}
                      <p className="m-0">
                        <Link
                          to={`/programas/${course.id}/consulta`}
                          className={
                            'btn primary-btn btn-rounded red_bg white d-none d-sm-block text-uppercase ' +
                            (schedule.soldout ? ' btn disabled' : '')
                          }>
                          {' '}
                          {schedule.soldout ? 'AGOTADO' : 'consultar'}
                        </Link>
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
