import { useWindowDimensions } from '@/hooks';
import { Link } from 'react-router-dom';

/* eslint-disable react/no-unescaped-entities */
export const Privacy = () => {
  const wide = useWindowDimensions(700);
  const position = wide ? 'fixed' : 'static';
  const paddingTop = wide ? null : '60px';

  return (
    <section className="legales_hero gradient-50 d-flex align-items-start">
      <div className="container">
        <div
          className="row position-relative"
          style={{
            paddingTop,
          }}>
          <div
            className="nav flex-column nav-pills me-3 col-sm-6 selector-legales py-5 pe-sm-5"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
            style={{
              position,
            }}>
            <header className="pb-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/legales'}>
                      <i className="fas fa-chevron-left"></i> Privacidad y Términos
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Políticas de Privacidad
                  </li>
                </ol>
              </nav>
              <h3>Políticas de Privacidad</h3>
              <p className="lead">Selecciona para acceder a la información</p>
            </header>
            <button
              className="nav-link active"
              id="v-pills-Introduccion-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Introduccion"
              type="button"
              role="tab"
              aria-controls="v-pills-Introduccion"
              aria-selected="true">
              Introducción
            </button>
            <button
              className="nav-link"
              id="v-pills-Consentimiento-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Consentimiento"
              type="button"
              role="tab"
              aria-controls="v-pills-Consentimiento"
              aria-selected="false">
              Consentimiento
            </button>
            <button
              className="nav-link"
              id="v-pills-Definiciones-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Definiciones"
              type="button"
              role="tab"
              aria-controls="v-pills-Definiciones"
              aria-selected="false">
              Definiciones
            </button>
            <button
              className="nav-link"
              id="v-pills-Condiciones-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Condiciones"
              type="button"
              role="tab"
              aria-controls="v-pills-Condiciones"
              aria-selected="false">
              Condiciones de Privacidad
            </button>
            <button
              className="nav-link"
              id="v-pills-Datos-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Datos"
              type="button"
              role="tab"
              aria-controls="v-pills-Datos"
              aria-selected="false">
              Datos personales
            </button>
          </div>
          <div className="tab-content col-sm-6 legales-content py-5" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-Introduccion"
              role="tabpanel"
              aria-labelledby="v-pills-Introduccion-tab"
              tabIndex={0}>
              {' '}
              <h3>Introducción</h3>
              <p>
                El presente documento describe las Políticas de Privacidad aplicables a la oferta y
                adquisición de los Servicios ofrecidos en la plataforma de TRANSFORMARME S.A.S. (en
                adelante, “Mundos E”) con relación a la información que Mundos E puede recopilar
                sobre y/o de los Usuarios y el uso que puede darle a dicha información, en el Sitio
                o en sitios vinculados. Mundos E respeta la privacidad de toda persona que acceda,
                navegue, utilice, y/o se registre en el Sitio mundose.com (en adelante el “Sitio”)
                con la intención de adquirir u ofrecer un Servicio bajo la figura de Usuario. En las
                presentes Políticas de Privacidad también explica las medidas de seguridad tomadas
                por Mundos E para proteger su información, la posibilidad de solicitar el acceso,
                rectificación, modificación o supresión de su información personal, y a quién podrá
                contactar en Mundos E y a través del Sitio para que sus preguntas en relación con
                estas Políticas de Privacidad sean contestadas y sean resueltos los asuntos que
                puedan surgir
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-Consentimiento"
              role="tabpanel"
              aria-labelledby="v-pills-Consentimiento-tab"
              tabIndex={0}>
              <h3>Consentimiento</h3>

              <p>
                Al acceder, navegar, utilizar y/o registrarse en el Sitio, los Usuarios deben leer,
                entender y aceptar todas las condiciones establecidas en las Políticas de Privacidad
                así como en los demás documentos incorporados a los mismos por referencia,
                previamente a su registración como Usuario de Mundos E, pudiendo optar de forma
                expresa por recibir los mismos y toda otra información por medios digitales; de modo
                que la utilización del Sitio implica la aceptación por parte de los Usuarios de las
                Políticas de Privacidad.
              </p>
              <p>
                El Sitio se basa en la confianza y en la honestidad de los Usuarios, para que se
                puedan adquirir las capacitaciones en un ambiente amistoso y confiable. En
                consecuencia, el acceso y/o el uso del Sitio y/o de los Servicios promocionados
                implican que los Usuarios han leído y comprendido las Políticas de Privacidad de
                Mundos E. Ante la falta de acuerdo con la presente, los Usuarios deberán abstenerse
                de utilizar el Sitio.
              </p>
              <p>
                Todas las visitas y todos los contratos y transacciones que se realicen en este
                Sitio, así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidos
                a la legislación aplicable en la República Argentina, de modo que las Políticas de
                Privacidad contenidas en este instrumento se aplicarán y se entenderán como parte
                integral de todos los actos y contratos que se ejecuten o celebren mediante los
                sistemas de oferta y comercialización comprendidos en este Sitio.{' '}
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-Definiciones"
              role="tabpanel"
              aria-labelledby="v-pills-Definiciones-tab"
              tabIndex={0}>
              <h3>Definiciones</h3>

              <p>
                Para la interpretación de los términos que serán utilizados en las Políticas de
                Privacidad, como así también en la normativa se entenderán, según el Art. 2 de La
                Ley 25.326
              </p>
              <p>
                <strong>Usuarios:</strong>Todo sujeto que se registra en el Sitio, pudiendo tener
                diferentes roles acorde a la contratación realizada con Mundos E, sea adquiriendo un
                paquete de capacitaciones u ofreciéndolo a través de dicha plataforma.
              </p>
              <p>
                <strong>Datos personales:</strong> Información de cualquier tipo referida a personas
                físicas o de existencia ideal determinadas o determinables.
              </p>
              <p>
                <strong>Datos sensibles:</strong> Datos personales que revelan origen racial y
                étnico, opiniones políticas, convicciones religiosas, filosóficas o morales,
                afiliación sindical e información referente a la salud o a la vida sexual
              </p>
              <p>
                <strong>Archivo, registro, base o banco de datos:</strong> Indistintamente, designan
                al conjunto organizado de datos personales que sean objeto de tratamiento o
                procesamiento, electrónico o no, cualquiera que fuere la modalidad de su formación,
                almacenamiento, organización o acceso.
              </p>
              <p>
                <strong>Tratamiento de datos:</strong> Operaciones y procedimientos sistemáticos,
                electrónicos o no, que permitan la recolección, conservación, ordenación,
                almacenamiento, modificación, relacionamiento, evaluación, bloqueo, destrucción, y
                en general el procesamiento de datos personales, así como también su cesión a
                terceros a través de comunicaciones, consultas, interconexiones o transferencias.
              </p>
              <p>
                <strong>Responsable de archivo, registro, base o banco de datos:</strong> Persona
                física o de existencia ideal pública o privada, que es titular de un archivo,
                registro, base o banco de datos.
              </p>
              <p>
                <strong>Datos informatizados:</strong> Los datos personales sometidos al tratamiento
                o procesamiento electrónico o automatizado.
              </p>
              <p>
                <strong>Titular de los datos:</strong> Toda persona física o persona de existencia
                ideal con domicilio legal o delegaciones o sucursales en el país, cuyos datos sean
                objeto del tratamiento al que se refiere la presente ley.{' '}
              </p>
              <p>
                <strong>Usuario de datos:</strong> Toda persona, pública o privada que realice a su
                arbitrio el tratamiento de datos, ya sea en archivos, registros o bancos de datos
                propios o a través de conexión con los mismos
              </p>
              <p>
                <strong>Disociación de datos:</strong> Todo tratamiento de datos personales de
                manera que la información obtenida no pueda asociarse a persona determinada o
                determinable.{' '}
              </p>
              <p>
                <strong>Registro de datos con fines publicitarios:</strong> En el caso de
                recopilación de domicilios, reparto de documentos, publicidad o venta directa y
                otras actividades análogas, por medio de la cual se traten datos que sean aptos para
                establecer perfiles determinados con fines promocionales, comerciales o
                publicitarios; o permitan establecer hábitos de consumo, y siempre que se trate de
                documentos accesibles al público o hayan sido facilitados por los propios titulares
                u obtenidos con su consentimiento
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-Condiciones"
              role="tabpanel"
              aria-labelledby="v-pills-Condiciones-tab"
              tabIndex={0}>
              <h3>Condiciones de Privacidad</h3>

              <h6>Condiciones de Privacidad de la Información del Sitio</h6>
              <p>
                Para utilizar los Servicios ofrecidos por Mundos E los Usuarios deberán facilitar
                determinados datos de carácter personal:
              </p>
              <p>
                En el caso de los Usuarios alumnos, deberán aportar al Sitio la siguiente
                información personal: (i) nombre y apellido; (ii) género; (iii) correo electrónico;
                (iv) teléfono de contacto; (v) documento de identidad; (vi) país de residencia;
                (vii) fecha de nacimiento; (viii) edad; (ix) vinculación de perfil a redes sociales
                tal como Linkedin, Facebook, Gmail, entre otras.
              </p>
              <p>
                En el caso de los Usuarios capacitadores, deberán aportar al Sitio la siguiente
                información personal: (i) nombre y apellido; (ii) género; (iii) correo electrónico;
                (iv) teléfono de contacto; (v) documento de identidad; (vi) país de residencia;
                (vii) fecha de nacimiento; (viii) edad; (ix) vinculación de perfil a redes sociales
                tal como Linkedin, Facebook, Gmail, entre otras; (x) situación impositiva frente al
                AFIP; (xi) CUIT; (xii) datos de la cuenta bancaria donde se realizarán depósitos.
              </p>
              <p>
                Toda la información es procesada y almacenada en servidores o medios magnéticos que
                mantienen altos estándares de seguridad y protección física y tecnológica. Mediante
                el uso del Sitio, los Usuarios reconocen y convienen en que Mundos E puede a su sola
                discreción conservar por tiempo indeterminado el contenido de los Usuarios, así como
                también sus Datos Personales, ya sea direcciones de email, direcciones IP, datos de
                tiempo y hora, y demás información los Usuarios, sobre sus actividades o acceso y
                uso del Sitio. Podrá divulgar la información de los Usuarios únicamente cuando
                considere que es necesario por ley o creyendo de buena fe que dicha conservación o
                divulgación es razonablemente necesaria para lo siguiente:{' '}
              </p>
              <ol className="lower-roman">
                <li>cumplir con un proceso legal; </li>
                <li>
                  exigir el cumplimiento de estas Políticas de Privacidad y de los Términos y
                  Condiciones;{' '}
                </li>
                <li>
                  responder a reclamos sobre la violación de los derechos de terceros en virtud del
                  contenido;{' '}
                </li>
                <li>
                  proteger los derechos, la propiedad, o la seguridad de Mundos E, de los Usuarios o
                  del público en general.
                </li>
              </ol>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-Datos"
              role="tabpanel"
              aria-labelledby="v-pills-Datos-tab"
              tabIndex={0}>
              {' '}
              <h3>Procedimientos sobre Información y Datos Personales </h3>
              <h6> Información recopilada automáticamente </h6>
              <ol>
                <li>
                  <strong>Información de navegación.</strong>Como es usual en muchas páginas, el
                  servidor de Sitio recaba automáticamente cierta información concerniente a los
                  Usuarios del Sitio, tales como: dirección del Protocolo Internet (IP) de su
                  computadora, dirección del IP de servicio de Internet, fecha y hora en que ingresó
                  al Sitio, dirección Internet de la página desde la que se conectó directamente al
                  Sitio, sistema operativo que está usando, secciones del Sitio que los Usuarios
                  visitaron, páginas leídas e imágenes visualizadas, y cualquier otro contenido que
                  haya enviado o bajado desde el Sitio. Utilizamos esta información con propósitos
                  administrativos, informativos, estadísticos y para mejorar el servicio que se
                  brinda en el Sitio.
                </li>
                <li>
                  <strong>Cookies.</strong> El Sitio utiliza 'cookies', una tecnología que instala
                  información en la computadora de un usuario de una página Web a los efectos de
                  permitir que ésta reconozca futuras visitas por parte de esa computadora. Las
                  cookies aumentan la conveniencia y mejoran el uso del Sitio. El uso de cookies
                  permite que Mundos E pueda personalizar ciertos servicios y comunicaciones. Por
                  esta razón, en ocasiones el Sitio puede enviar cookies para que los Usuarios
                  puedan personalizar algunas opciones. Mundos E utiliza la información
                  proporcionada mediante las cookies para: reconocerlo como uno los Usuarios;
                  ofrecerle un contenido personalizado del Sitio e información para su uso personal;
                  rastrear su actividad del Sitio; completar formularios en línea con sus datos
                  personales para responder a sus necesidades e inquietudes de forma más eficiente;
                  y facilitar y mejorar su experiencia en el Sitio. Los Usuarios podrán optar por
                  rechazar las cookies si su navegador así lo permite, pero eso podría afectar su
                  utilización del Sitio y su capacidad para acceder a ciertas secciones. La
                  publicidad desplegada en el Sitio también puede contener cookies u otras
                  tecnologías. Dicha publicidad puede provenir de terceras compañías de publicidad.
                  Se deja constancia que Mundos E no tiene ningún control, responsabilidad u
                  obligación con respecto a esas cookies u otras tecnologías similares utilizadas en
                  dicha publicidad o respecto al uso y/o divulgación de información recolectada a
                  través de las cookies de publicidad.
                </li>
                <li>
                  <strong>Web Beacons.</strong> Es una imagen electrónica que es colocada en un
                  sitio web y tiene finalidades similares a las cookies. Además, también puede ser
                  utilizada para medir patrones de tráfico de un sitio a otro con el objeto de
                  maximizar el flujo del tráfico a través de la web. Los Usuarios del Sitio conocen
                  y aceptan que eventualmente Mundos E podrá implementar web beacons en el Sitio.
                </li>
              </ol>
              <h6>Información Personal Proporcionada </h6>
              <p>
                Al momento de registrarse en el Sitio, es posible que se le solicite que proporcione
                ciertos datos personales para propósitos tales como facilitar la comunicación con
                los Usuarios y posibilitar el acceso a ciertas secciones dentro del Sitio. El
                Usuario siempre tendrá la opción de no proporcionar dicha información, pero se le
                advierte que en caso de no hacerlo es posible que no pueda acceder a ciertos
                beneficios o servicios.
              </p>
              <p>
                Para acceder al Sitio se le va a solicitar que se registre creando para ello una
                cuenta de usuario. Al registrarse, los Usuarios aceptan y se obligan a (a)
                suministrar datos exactos, actuales y completos sobre su persona, como se solicita
                en la forma de registro; y (b) mantener actualizados sus datos en el Sitio, de
                manera que la información permanezca exacta, veraz, actualizada y completa. Los
                Usuarios responderán, en cualquier caso, de la veracidad de los datos facilitados,
                reservándose Mundos E el derecho de excluir a todo los Usuarios que hayan facilitado
                datos falsos, sin perjuicio de iniciar las acciones legales que correspondieren.{' '}
              </p>
              <p>
                Si los Usuarios ofrecen y adquieren servicios en nuestro Sitio, Mundos E podrá
                recabar información adicional sobre aquellos, ya sea personal o de otro tipo, tales
                como su dirección, número de teléfono, información sobre el medio de pago o forma de
                envío, de corresponder. De ser necesario, Mundos E proporcionará dicha información a
                terceras partes para completar la transacción y cumplir con el pedido requerido.{' '}
              </p>
              <h6>Uso de Datos Personales </h6>
              <p>
                En caso de que los Usuarios brinden información personal, la misma será objeto de
                tratamiento por Mundos E e incorporada a su base de datos. La recolección y
                tratamiento de información y/o datos de carácter personal tiene por finalidad la
                prestación, gestión, administración, actualización y mejora de los servicios y
                contenidos puestos a disposición de los Usuarios por parte de Mundos E a través del
                Sitio. Asimismo, los datos personales podrán ser utilizados para la participación de
                sorteos, promociones, ofertas, para el envío de comunicaciones a los Usuarios en lo
                referente a los servicios y contenidos brindados por Mundos E, así como también para
                cualquier otro uso permitido por la ley.
              </p>
              <h6>Divulgación de Datos Personales</h6>
              <p>
                Mundos E, no compartirá, cederá, transferirá ni transmitirá sus datos personales a
                terceros, salvo en los casos previstos en las presentes Políticas de Privacidad. Los
                Usuarios consienten en forma expresa que Mundos E, podrá transferir, total o
                parcialmente su información personal a empresas controladas, controlantes,
                vinculadas o asociadas, a asesores profesionales, entidades gubernamentales y/o
                prestadores de servicios, en la medida necesaria para el cumplimiento de las
                finalidades descritas en la presente Política de Privacidad y/u obligaciones
                legales, aceptando y consintiendo que, en algunos casos, dichos destinatarios
                podrían encontrarse o tener operaciones relevantes en jurisdicciones cuyo nivel de
                protección podría no ser equivalente al otorgado por la legislación argentina.
                Adicionalmente, los servicios que Mundos E brinda o pueda brindar a través de su
                Sitio, requieren que Mundos E comparta ciertos datos e información con terceros a
                fin de cumplir con el servicio ofrecido por el.
              </p>
              <h6>
                En caso de tratamiento de datos personales por cuenta de terceros, Mundos E firmará
                con tales, un contrato con relación al tratamiento el cual establezca que dicho
                tercero no podrá aplicar o utilizar con un fin distinto al que figure en el contrato
                de servicios, ni cederlos a otras personas. Es dicho contrato se deberá prever que
                una vez cumplida la prestación contractual los datos personales tratados deberán ser
                destruidos, salvo que medie autorización expresa cuando se presuma la posibilidad de
                ulteriores tratativas, en cuyo caso se podrá almacenar con debidas condiciones de
                seguridad por un periodo de hasta dos años.
              </h6>
              <p>
                Mundos E podrá revelar información personal a terceros sin su consentimiento en el
                caso de que sea requerido por ley o por resolución judicial que así lo autorice u
                ordene, a fin de cooperar con autoridades gubernamentales en investigaciones
                judiciales y/o administrativas y para hacer cumplir o proteger la propiedad
                intelectual o derechos contractuales de Mundos E o sus Usuarios.
              </p>
              <h6>Seguridad de los Datos Personales </h6>
              <p>
                Para prevenir el acceso no autorizado, mantener la seguridad y confidencialidad de
                los datos personales y asegurar el uso correcto de la información, Mundos E ha
                puesto en uso ciertos medios físicos, electrónicos, administrativos y procedimientos
                de seguridad que protegen y aseguran la información que se recopila en el Sitio.
              </p>
              <h6>Seguridad de los Datos Personales</h6>
              <p>
                Para prevenir el acceso no autorizado, mantener la seguridad y confidencialidad de
                los datos personales y asegurar el uso correcto de la información, Mundos E ha
                puesto en uso ciertos medios físicos, electrónicos, administrativos y procedimientos
                de seguridad que protegen y aseguran la información que se recopila en el Sitio.{' '}
              </p>
              <p>
                Mundos E resguarda la información de acuerdo con estándares y procedimientos de
                seguridad establecidos por la normativa aplicable, contemplando las medidas técnicas
                y organizativas internas necesarias para garantizar la seguridad y confidencialidad
                de los datos, tratando por todos los medios de evitar el acceso no autorizado a los
                mismos; encontrándose continuamente evaluando nuevas tecnologías y opciones para
                proteger la información. Sin embargo, los Usuarios reconocen que los medios técnicos
                existentes que brindan seguridad no son invulnerables, y que aun cuando se adopten
                todos los recaudos razonables de seguridad es posible sufrir manipulaciones,
                destrucción y/o pérdida de información. Por otro lado, mientras Mundos E intenta
                proteger su información personal, los Usuarios también deberán tomar medidas para
                proteger su información personal mientras se encuentre utilizando el Sitio.
              </p>
              <p>
                No está permitida ninguna acción o uso de dispositivo, software, u otro medio
                tendiente a interferir tanto en la operatoria del Sitio como en las cuentas o bases
                de datos de Mundos E. Los Usuarios se comprometen a no tomar ninguna acción para
                poner en peligro, limitar o interferir con el Sitio.
              </p>
              <p>
                Los Usuarios no pueden descompilar, realizar ingeniería inversa o intentar descubrir
                el código fuente de cualquier elemento del Sitio; ni tampoco aplicar o utilizar
                herramientas de automatización para conectarse, modificar o controlar el contenido
                del Sitio.{' '}
              </p>
              <p>
                Mundos E puede bloquear, restringir, desconectar o interrumpir el acceso a los
                Usuarios a todo o parte del Sitio de Mundos E en cualquier momento, a su propio
                criterio y sin previo aviso u obligación. Cualquier conducta que, a criterio
                exclusivo de Mundos E, limite o impida el uso o aprovechamiento del Sitio por parte
                de cualquier otra persona o entidad está estrictamente prohibida y puede ocasionar
                la cancelación del acceso al Sitio sin previo aviso.
              </p>
              <p>
                Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes
                sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este
                contrato harán pasible a su/s responsable/s de las acciones legales pertinentes, y a
                las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar
                los daños ocasionados.
              </p>
              <h6>Derechos sobre Datos Personales </h6>
              <p>
                Mundos E garantiza, respecto de la información personal proporcionada, el debido
                ejercicio de los derechos de los Usuarios que conceden las disposiciones que regulan
                la Protección de Datos Personales vigentes en la República Argentina. Los titulares
                de los datos cuentan con los siguientes derechos:{' '}
              </p>
              <ul>
                <li>
                  <strong>Derecho de Información:</strong> Toda persona podrá solicitar al organismo
                  de control referente a la existencia de bases de datos personales, finalidades y
                  responsables. La información debe ser clara, amplia y completa.{' '}
                </li>
                <li>
                  <strong>Derecho de Acceso:</strong> El titular de los datos podrá obtener
                  información de sus datos personales incluidos en los bancos de datos públicos o
                  privados destinados a proveer informes. La información solicitada se debe
                  proporcionar dentro de los 10 días hábiles desde la solicitud. Si no se satisface
                  el pedido, quedará expedita la acción de hábeas data. El derecho de acceso que se
                  refiere en este artículo sólo puede ser ejercido en forma gratuita a intervalos no
                  inferiores a seis meses, salvo que se acredite un interés legítimo al efecto.
                </li>
                <li>
                  <strong>Derecho de Rectificación, Actualización o Supresión de datos:</strong> El
                  responsable o los Usuarios del banco de datos, debe proceder a la rectificación,
                  supresión o actualización de los datos personales en el plazo máximo de 10 días
                  hábiles de recibido el reclamo. El incumplimiento de esta obligación dentro del
                  término acordado habilitará a promover la acción de hábeas data. De este modo, los
                  Usuarios podrán ejercer los derechos de acceso, rectificación, actualización o
                  supresión de datos y oposición.{' '}
                </li>
              </ul>
              <p>
                El ejercicio de dichos derechos podrá ser efectivizado por cada Usuario mediante el
                envío de un correo electrónico dirigido a{' '}
                <a href="mailto:administracion@mundose.com" target="_blank" rel="noreferrer">
                  administracion@mundose.com
                </a>{' '}
                Ante el requerimiento formal realizado por parte de los Usuarios, Mundos E procederá
                a brindar el acceso a su información personal, así como a rectificar, actualizar o
                suprimir los datos personales del afectado según corresponda, en los términos y
                plazos legales y cuando ello fuere procedente. La supresión de algunos datos no
                procederá cuando pudiese causar perjuicios a derechos o intereses legítimos de
                terceros, o cuando existiera una obligación legal de conservar los datos. Por
                último, debajo incluimos el texto requerido por la Disposición N° 10/2008, emitida
                por la Dirección Nacional de Protección de Datos Personales en relación a los
                derechos de los titulares de los datos personales.
              </p>
              <p>
                'EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO DE
                ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS MESES, SALVO
                QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO
                14, INCISO 3 DE LA LEY Nº 25.326'.{' '}
              </p>
              <h6>Acceso a Otros Sitios</h6>
              <p>
                El Sitio puede contener vínculos hacia otros sitios o recursos de Internet,
                incluyendo sitios operados por los socios comerciales de Mundos E o aquellas
                empresas que proveen contenido y/o servicios para el funcionamiento de la
                plataforma. Cuando los Usuarios acceden a estos sitios a través de los enlaces o
                vínculos correspondientes, reconocen estar conectándose con otro sitio web distinto
                al Sitio de Mundos E. En este sentido, Mundos E no tiene ninguna responsabilidad,
                obligación y/o control con respecto a los contenidos disponibles en esos otros
                sitios o a la recolección, uso, tratamiento y/o divulgación que éstos hagan de su
                información personal. Se sugiere a los Usuarios la revisión de las políticas de
                privacidad de tales terceras partes para comprender de qué forma recopilan y usan la
                información.{' '}
              </p>
              <h6>Términos y Condiciones</h6>
              <p>
                Al acceder, navegar, utilizar y/o registrarse en el Sitio, los Usuarios expresamente
                reconoce y acepta las disposiciones contenidas en los Términos y Condiciones. Cada
                una de dichas cláusulas es aplicable a cualquier disputa que pudiera surgir en
                relación con estas Políticas de Privacidad o con la recopilación, uso, tratamiento
                y/o divulgación que Mundos E hiciera de su información personal.
              </p>
              <h6>Conservación de Datos Personales </h6>
              <p>
                Mundos E conservará la información personal de los Usuarios por el término necesario
                para el cumplimiento de las finalidades descritas en las presentes Políticas de
                Privacidad y/u obligaciones legales. La información personal que se recabe de los
                Usuarios serán destruida cuando haya dejado de ser necesaria o pertinente a los
                fines para los cuales hubiese sido recolectada, salvo que exista una obligación
                legal de conservarla por un término mayor
              </p>
              <h6>Cambios a esta Política de Privacidad</h6>
              <p>
                Mundos E se reserva el derecho de modificar estas Políticas de Privacidad
                periódicamente, sin previa notificación y/o consentimiento de los Usuarios. La
                utilización de nuestro Sitio después de operado el/los cambio/s instrumentado/s a
                estas Políticas de Privacidad importa la aceptación de dicha/s modificación/es. Le
                sugerimos la visita periódica de estas Políticas de Privacidad para estar informado
                de la versión más actual.{' '}
              </p>
              <h6>Jurisdicción y Ley Aplicable</h6>
              <p>
                Todos los datos procesados o a los que Mundos E tenga acceso en virtud o en el marco
                del pago del Servicio seleccionado en la Aplicación por los Usuarios, serán tratados
                por Mundos E de acuerdo a lo detallado en sus Términos y Condiciones, en las normas
                vigentes aplicables en la República Argentina y, en particular, en la Ley N° 25.326
                y en cumplimiento de las medidas técnicas y organizativas recomendadas por la
                Resolución 47/2018 de la Agencia de Acceso a la Información Pública, las que
                garantizan la seguridad y confidencialidad de los datos, a fin de evitar su
                adulteración, pérdida, consulta o tratamiento no autorizado y detectar desvíos
                intencionales o no de información, respecto de lo cual Mundos E realiza sus mejores
                esfuerzos en prevenir y evitar cualquier incidente de seguridad.{' '}
              </p>
              <p>
                Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la
                República Argentina. Cualquier controversia derivada del presente acuerdo, su
                existencia, validez, interpretación, alcance o cumplimiento, será sometida ante la
                Justicia Nacional Ordinaria de la Ciudad Autónoma de Buenos Aires.
              </p>
              <h6>Comentarios</h6>
              <p>
                Si los Usuarios tienen algún comentario o pregunta acerca de esta Política de
                Privacidad o sobre su información personal, podrán contactarse con Mundos E
                escribiendo a{' '}
                <a href="mailto:administracion@mundose.com" target="_blank" rel="noreferrer">
                  administracion@mundose.com
                </a>
                .
              </p>
              <h6>Domicilio</h6>
              <p>
                Se fija como domicilio de <strong> TRANSFORMARME S.A.S. </strong>la Avenida Córdoba
                785, piso 6, oficina 12, de la Ciudad Autónoma de Buenos Aires, código postal 1054,
                República Argentina.{' '}
              </p>
              <p>
                <strong>
                  CUALQUIER PERSONA QUE NO ACEPTE LOS TÉRMINOS DE ESTAS POLÍTICAS DE PRIVACIDAD, LOS
                  CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR
                  EL SITIO Y/O LOS SERVICIOS.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
