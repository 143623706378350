import { Route, Routes } from 'react-router-dom';

import { Programs, MyCertificates, Profile, Settings } from '@/pages';

export const UserRoutes = () => {
  return (
    <Routes>
      <Route index element={<Profile />} />
      <Route path="programas" element={<Programs />} />
      <Route path="perfil" element={<Profile />} />
      <Route path="certificados" element={<MyCertificates />} />
      <Route path="configuracion" element={<Settings />} />
      {/* <Route path=":id">
        <Route index element={<Course />} />
        <Route path="consulta" element={<CourseInquiry />} />
        <Route path="consulta/gracias" element={<CourseInquiryThanks />} />
        {env === 'development' && (
          <Route path="compra" element={<Purchase />}>
            <Route index element={<UserContainer />} />
            <Route path="revisar" element={<ReviewForm />} />
            <Route path="factura" element={<BillContainer />} />
            <Route path="gracias/*" element={<Thanks />} />
          </Route>
        )}
      </Route> */}
    </Routes>
  );
};
