import React from 'react';

import { CourseResponse } from '@/features/courses';
import { getProgramsByUser, MyProgram } from '@/features/user';
import { getUserEmail, toTop } from '@/utils';

export const Programs = () => {
  const [programs, setPrograms] = React.useState<CourseResponse[] | null>(null);

  const getPrograms = async () => {
    const response = await getProgramsByUser(getUserEmail());

    if (response.data.error) {
      setPrograms([]);
    } else {
      setPrograms(response.data);
    }
  };

  React.useEffect(() => {
    getPrograms().then(() => {
      toTop();
    });
  }, []);

  return (
    <main className="myCourses">
      <header className="lighter-grey_bg shadow-2">
        <div className="container">
          <div className="row py-4">
            <h1 className="m-0">
              <em>
                <img src={'/img/iconMisProgramas.svg'} alt="icono de programas" />
              </em>{' '}
              Mis Programas
            </h1>
          </div>
        </div>
      </header>
      <section className="courses_list container">
        <div className="row pb-5 pt-5 px-5 gap-5">
          {!programs ? (
            <>
              <MyProgram program={null} />
              <MyProgram program={null} />
              <MyProgram program={null} />
            </>
          ) : programs.length < 1 ? (
            <div
              style={{
                width: '100%',
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <h1>No se encontraron programas</h1>
            </div>
          ) : (
            programs.map(program => {
              return <MyProgram key={program.id} program={program} />;
            })
          )}
        </div>
      </section>
    </main>
  );
};
